<script>
import MessagingNode from "./BaseMessagingNode";
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";
import { MESSAGE_FORMAT_PLAIN_TEXT } from "@/constants/messagingNodeFormat";

export default {
  mixins: [MessagingNode],
  created() {
    this.$set(this.nodeToBind, "node_type", NODE_TYPES.SEND_SMS.NODE_TYPE);

    this.$set(this.nodeToBind.messaging_node.data, "sms_node_type", "send");

    this.$set(
      this.nodeToBind.messaging_node.data,
      "available_message_formats",
      [MESSAGE_FORMAT_PLAIN_TEXT]
    );

    // Plain text is the only available format for this node type, so if not set, just use plain text.
    // If other formats become available, then make changes here similar to the other message nodes.
    if (_.isEmpty(this.nodeToBind.messaging_node.data.message_format)) {
      this.$set(
        this.nodeToBind.messaging_node.data,
        "message_format",
        MESSAGE_FORMAT_PLAIN_TEXT
      );
    }
  }
};
</script>
