<template>
  <div
    v-focus
    @keydown.shift.ctrl.exact="handleCtrlShiftKeyPress"
    @keydown.ctrl.exact="handleCtrlKeyPress"
    @keydown.shift.exact="handleShiftKeyPress"
    @keydown.esc.exact="handleEscapeKeyDownEvent"
    @keydown.exact="handleHotKeyPress"
    tabindex="0"
    v-if="task !== null"
    :class="getModalContainerClass"
    id="callfloweditor"
  >
    <nodes-palette
      style="border-right: #f5f5f8 1px solid;"
      :is-template="task && !!task.is_template"
      v-if="task && !isTaskReadOnly"
      @node-type-search="makeNodePaletteItemsDraggable"
      @searching-nodes="markNodeTypeSearchInputFieldFocused"
    ></nodes-palette>
    <div
      v-loading="loadingCallflowEditor"
      :element-loading-text="getLoadingText"
      id="canvas"
      class="canvas"
    >
      <div class="callFlowTitleBar">
        <div class="taskNameTitle">
          <el-button
            icon="el-icon-arrow-left"
            text
            class="backBtn"
            @click="handleBackBtnClick"
            v-if="isTaskReadOnly"
            >{{ __("Back") }}
          </el-button>
          <div
            v-if="isTaskReadOnly"
            style="display: flex; padding-right: 25px; padding-left: 10px"
          >
            <img
              class="colorIcon"
              onload="SVGInject(this)"
              :src="getIconPreview"
            />
            <div style="color: var(--theme-color);padding-left: 5px">
              {{ __("PREVIEW MODE") }}
            </div>
          </div>
          <div
            style="display: flex;"
            v-if="
              task &&
                !task.is_template &&
                ['voice', 'messaging', 'whatsapp'].includes(task.task_type)
            "
            class="assignNumbersAction"
            @click="openManageNumbersModal = true"
          >
            <img
              class="colorIcon"
              onload="SVGInject(this)"
              :src="getPhoneSvg"
            />
            <span class="assignedNumbers">
              {{ getPhoneNumbers ? getPhoneNumbers : __("[ Assign Numbers ]") }}
            </span>
          </div>
        </div>
        <div class="canvasNameTitle">
          <div
            id="taskNameTitle"
            v-if="task"
            @click="openTaskSettings = true"
            style="
              cursor: pointer;
              max-width: 250px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            "
          >
            {{ `${task.task_name}` }}
          </div>
          /
          <el-popover
            placement="bottom"
            :trigger="triggerForCanvasPopover"
            :key="popperIdForCanvasPopover"
            :id="popperIdForCanvasPopover"
            popper-class="canvas-list"
            width="250"
            v-model="canvasDropDownVisible"
          >
            <div slot="reference" style="display: flex;align-items: center">
              <div
                style="cursor: pointer; max-width: 200px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;"
              >
                {{ selectedCanvas }}
              </div>
              <i class="el-icon-caret-bottom"></i>
            </div>

            <div class="canvas-dropdown-container">
              <div class="canvas-dropdown-row-top">
                <div
                  style="display: flex; align-items: center"
                  class="canvas-list-div"
                >
                  <el-button
                    icon="el-icon-plus"
                    plain
                    style="border: none"
                    @click="createCanvas({ ac_id: selectedAccountId, task_id })"
                    v-if="task && !isTaskReadOnly"
                    :loading="!isCanvasCreated"
                    >{{ __("New Canvas") }}
                  </el-button>
                </div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="tooltipContentForCanvasPin"
                  placement="right"
                >
                  <div @click="handlePinCanvasClick">
                    <img
                      :src="require('@/assets/icons/icon-pin.svg')"
                      onload="SVGInject(this)"
                      class="pin-canvas-icon"
                      :class="{ pinned: canvasIsPinned }"
                      alt="pin-canvas-icon"
                    />
                  </div>
                </el-tooltip>
              </div>
              <el-input
                :placeholder="__('Search Canvas')"
                suffix-icon="el-icon-search"
                v-model="canvasSearch"
                style="margin-top: 5px"
                @focus="markSearchCanvasFieldFocused(true)"
                @blur="markSearchCanvasFieldFocused(false)"
              ></el-input>
              <el-scrollbar :native="false" style="width: 100%">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    max-height: 300px;
                    margin-top: 10px;
                  "
                >
                  <div
                    v-for="(canvases, out_index) in getCanvases"
                    :key="out_index"
                  >
                    <div
                      v-if="out_index === 0 && canvases.length"
                      class="subHeading"
                    >
                      {{ __("System Canvases") }}
                    </div>
                    <div
                      v-if="out_index === 1 && canvases.length"
                      class="subHeading"
                    >
                      {{ __("Canvases") }}
                    </div>
                    <div
                      v-if="out_index === 2 && canvases.length"
                      class="subHeading"
                    >
                      {{ __("Event Handler Canvases") }}
                    </div>
                    <canvas-list-item
                      :canvas="canvas"
                      v-for="canvas in canvases"
                      :key="canvas.canvas_id"
                      @select-canvas="handleCanvasSelect"
                      @change="handleCanvasNameChange"
                      @move-to-event-handler="handleMoveCanvasToEventHandler"
                      @focus="handleCanvasFocus"
                      @change-canvas-name-input="markCanvasNameFieldFocused"
                      :class="getActiveCanvasClass(canvas)"
                      :show-delete-canvas="!isTaskReadOnly"
                    />
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </el-popover>
        </div>

        <div class="cfe-controls">
          <div class="canvasSearchInput">
            <el-tooltip
              placement="bottom"
              :hide-after="1000"
              :visible-arrow="false"
            >
              <div slot="content" style="font-size: 1em">
                {{ __("Search") }}
                <span
                  style="
                    padding: 0 7px;
                    background-color: #6e7681;
                    margin-left: 5px;
                    border-radius: 2px;
                  "
                  >/</span
                >
              </div>
              <node-search
                class="node-search"
                popper-class="my-autocomplete"
                ref="searchbar"
                filterable
                clearable
                :filter-method="setSearchItem"
                v-model="searchItemComputed"
                @input="debouncedHandleNodeSelect"
                @hover="debouncedHandleNodeSelect"
                @focus="handleSearchBarFocus"
                @visible-change="handleNodeSearchClose"
                :placeholder="__('Search Nodes')"
              >
                <div class="node-search-icon align-self-center" slot="prefix" />
                <template v-for="group in targetNodeGroups">
                  <el-option
                    v-for="(item, position) in group"
                    :key="item.node_id + '_' + position"
                    :label="item.node_name"
                    :value="item.node_id"
                    :title="item.node_name"
                  >
                    <div
                      style="
                      display: flex;
                      justify-content: flex-start;
                      padding-inline: 7px;
                    "
                    >
                      <span
                        v-if="position !== 0"
                        style="padding: 0 0 0 24px"
                      ></span>
                      <div
                        style="
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                      "
                      >
                        <img
                          :src="
                            getIconForNodeType(
                              item.node_type.data.node_type,
                              item
                            )
                          "
                          v-if="
                            ![
                              'start_node',
                              'beep_detection',
                              'machine_detection',
                              'disconnect'
                            ].includes(item.node_type.data.node_type)
                          "
                          alt
                          height="18px"
                          width="18px"
                        />
                        <img
                          :src="
                            getIconForNodeType(
                              task.task_type +
                                '_' +
                                item.node_type.data.node_type
                            )
                          "
                          v-else-if="
                            item.node_type.data.node_type === 'start_node'
                          "
                          alt
                          height="18px"
                          width="18px"
                          onload="SVGInject(this)"
                          class="startNodeSearchIcon"
                        />
                        <img
                          :src="
                            getIconForNodeType(item.node_type.data.node_type)
                          "
                          v-else
                          alt
                          height="18px"
                          width="18px"
                          onload="SVGInject(this)"
                          class="startNodeSearchIcon"
                        />
                      </div>
                      <div class="value">{{ item.node_name }}</div>
                    </div>
                  </el-option>
                </template>
              </node-search>
            </el-tooltip>
          </div>

          <div class="cfe-minor-controls-wrapper">
            <!-- Call task button -->
            <div class="cfe-minor-controls">
              <el-tooltip
                :open-delay="1000"
                :visible-arrow="false"
                popper-class="cfe-control-tooltip"
                placement="bottom"
              >
                <template #content>
                  <div
                    style="
                    display: flex;
                    align-self: stretch;
                    justify-content: center;
                  "
                  >
                    {{ __("Preview Task Caller") }}
                  </div>
                </template>
                <el-popover
                  placement="bottom"
                  width="170"
                  trigger="manual"
                  v-if="this.task.task_type === 'voice'"
                  v-model="showWebRTCPopup"
                >
                  <el-button
                    slot="reference"
                    @click="showWebRTCDialpad(true)"
                    type="text"
                  >
                    <img
                      :src="getWebRTCSVG"
                      class="versioningIcon"
                      onload="SVGInject(this)"
                    />
                  </el-button>
                  <dialpad
                    :start-call="showWebRTCPopup"
                    :task="task"
                    @show="showWebRTCDialpad"
                  />
                </el-popover>
              </el-tooltip>
            </div>

            <!-- Version history button -->
            <div v-if="!isTaskReadOnly" class="cfe-minor-controls">
              <el-tooltip
                :open-delay="1000"
                :visible-arrow="false"
                popper-class="cfe-control-tooltip"
                placement="bottom"
              >
                <template #content>
                  <div
                    style="
                    display: flex;
                    align-self: stretch;
                    justify-content: center;
                  "
                  >
                    {{ __("Version history") }}
                  </div>
                </template>
                <el-popover placement="bottom" width="310" trigger="hover">
                  <version-manager
                    v-if="currentTask"
                    :task="currentTask"
                    @version-changed="handleVersionChange"
                    @version-created="setTaskModifiedToFalse"
                    @version-creating="handleVersionCreation"
                    @restoring="handleVersionSwitchProcess"
                    @loadingText="changeLoadingText"
                  />
                  <el-container slot="reference">
                    <img
                      :src="getVersioningSVG"
                      :alt="__('Version')"
                      class="versioningIcon"
                      onload="SVGInject(this)"
                    />
                  </el-container>
                </el-popover>
              </el-tooltip>
            </div>
            <div
              class="cfe-minor-controls"
              v-if="
                currentTask &&
                  (currentTask.task_type === 'chatbot' ||
                    currentTask.task_type === 'workflow')
              "
            >
              <el-tooltip
                :open-delay="1000"
                :visible-arrow="false"
                popper-class="cfe-control-tooltip"
                placement="bottom"
              >
                <template #content>
                  <div
                    style="
                    display: flex;
                    align-self: stretch;
                    justify-content: center;
                  "
                  >
                    {{ __("Key") }}
                  </div>
                </template>
                <el-popover placement="bottom" width="310" trigger="hover">
                  <div
                    class="api-key-item"
                    v-if="currentTask.task_type === 'chatbot'"
                  >
                    <div class="titles">{{ __("Chatbot Task Key") }}</div>
                    <span class="api-key"
                      >{{ currentTask.chatbot_task.data.api_key }}
                    </span>
                    <span>
                      <copier
                        :value="currentTask.chatbot_task.data.api_key"
                        :success-message-override="__('Copied')"
                      ></copier>
                    </span>
                  </div>
                  <div
                    class="api-key-item"
                    v-if="currentTask.task_type === 'workflow'"
                  >
                    <div class="titles">{{ __("Workflow Key") }}</div>
                    <span class="api-key"
                      >{{ currentTask.workflow_task.data.api_key }}
                    </span>
                    <span>
                      <copier
                        :value="currentTask.workflow_task.data.api_key"
                        :success-message-override="__('Copied')"
                      ></copier>
                    </span>
                  </div>
                  <el-container slot="reference">
                    <img
                      :src="getCopyKeySVG"
                      :alt="__('Key')"
                      class="versioningIcon"
                      onload="SVGInject(this)"
                    />
                  </el-container>
                </el-popover>
              </el-tooltip>
            </div>

            <div class="cfe-minor-controls">
              <el-tooltip
                :open-delay="1000"
                :visible-arrow="false"
                popper-class="cfe-control-tooltip"
                placement="bottom"
              >
                <template #content>
                  <div
                    style="
                    display: flex;
                    align-self: stretch;
                    justify-content: center;
                  "
                  >
                    {{ __("Show Task Validator") }}
                  </div>
                </template>
                <el-popover placement="bottom" width="100" trigger="manual">
                  <el-button
                    slot="reference"
                    @click="handleValidatorClick"
                    type="text"
                  >
                    <img
                      :src="getTaskValidatorIconSVG"
                      class="versioningIcon"
                      onload="SVGInject(this)"
                  /></el-button>
                </el-popover>
              </el-tooltip>
            </div>

            <!-- DISPLAY RECORDINGS -->
            <div v-if="!isTaskReadOnly" class="cfe-minor-controls">
              <el-tooltip
                :open-delay="1000"
                :visible-arrow="false"
                popper-class="cfe-control-tooltip"
                placement="bottom"
              >
                <template #content>
                  <div
                    style="
                    display: flex;
                    align-self: stretch;
                    justify-content: center;
                  "
                  >
                    {{ __("Show Recordings") }}
                  </div>
                </template>
                <el-popover placement="bottom" width="100" trigger="manual">
                  <el-button
                    slot="reference"
                    @click="showRecordings"
                    type="text"
                  >
                    <img
                      :src="getMicrophoneSVG"
                      class="versioningIcon"
                      onload="SVGInject(this)"
                  /></el-button>
                </el-popover>
              </el-tooltip>
            </div>

            <div class="cfe-minor-controls">
              <el-tooltip
                :open-delay="1000"
                :visible-arrow="false"
                popper-class="cfe-control-tooltip"
                placement="bottom"
              >
                <template #content>
                  <div
                    style="
                    display: flex;
                    align-self: stretch;
                    justify-content: center;
                  "
                  >
                    {{ __("Show Task Edit History") }}
                  </div>
                </template>
                <el-popover placement="bottom" width="100" trigger="manual">
                  <el-button
                    slot="reference"
                    @click="handleTaskEditHistoryClick"
                    type="text"
                  >
                    <img
                      :src="getTaskEditHistoryIconSVG"
                      class="versioningIcon"
                      onload="SVGInject(this)"
                  /></el-button>
                </el-popover>
              </el-tooltip>
            </div>

            <div class="cfe-minor-controls">
              <el-tooltip
                :open-delay="1000"
                :visible-arrow="false"
                popper-class="cfe-control-tooltip"
                placement="bottom"
              >
                <template #content>
                  <div
                    style="
                    display: flex;
                    align-self: stretch;
                    justify-content: center;
                  "
                  >
                    {{ __("Show Task Logs") }}
                  </div>
                </template>
                <el-popover placement="bottom" width="100" trigger="manual">
                  <el-button slot="reference" @click="showLogs" type="text">
                    <img
                      ref="sysLogIcon"
                      :alt="__('System Log Icon')"
                      :src="getCallLogIconSVG"
                      class="versioningIcon"
                      onload="SVGInject(this)"
                  /></el-button>
                </el-popover>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <user-presence
        ref="UserPresenceTab"
        :canvas-name="activeCanvasName"
        :ac-id="selectedAccountId"
        :task-id="task_id"
        :phone-number="task.phone_number"
        :editing-node="
          !!selectedNodeType && linkedOrphanNode === -1 && clickedNode && isEdit
            ? clickedNode
            : null
        "
        @active-node-id="debouncedHandleNodeSelect"
      ></user-presence>
      <div @click="closeSearchBar" id="orgchart"></div>
    </div>

    <component
      :is="showAs"
      :visible.sync="nodeSelected"
      v-if="nodeSelected"
      :direction="direction"
      :modal="false"
      :close-on-click-modal="false"
      :show-close="false"
      @closed="handleClose"
      fullscreen
      :custom-class="nodeModal"
      :close-on-press-escape="false"
      v-loading="nodeContentLoading"
    >
      <el-row
        v-if="!isStartNodeSelected"
        slot="title"
        class="node-configure-title"
        type="flex"
      >
        <el-col :lg="12" style="display: flex" :md="17" :xs="22" :sm="22">
          <img
            :src="getNodeIcon"
            alt=""
            height="33px"
            width="33px"
            style="margin-right: 10px"
          />
          <!--   todo   Implement a way for user to select the node type, for now going with a drop down -->
          <div class="node-type-options">
            <el-form ref="nodeForm" label-position="top" label-width="100px">
              <el-row :gutter="20" type="flex">
                <!-- Show option to insert a orphan node only when -->
                <!-- trying to insert a new node -->
                <el-col v-if="isInsertPossible">
                  <el-select v-model="linkedOrphanNode" default-first-option>
                    <el-option
                      v-for="orphanNode in orphanNodes"
                      :key="orphanNode.value"
                      :label="__(orphanNode.label)"
                      :value="orphanNode.value"
                    ></el-option>
                  </el-select>
                </el-col>

                <!-- Show option to change node type -->
                <!-- only when new node is selected -->
                <el-col v-if="linkedOrphanNode === -1">
                  <el-form-item>
                    <el-select
                      v-model="selectedNodeType"
                      style="width: 100%"
                      default-first-option
                      :placeholder="__('Select Node Type')"
                      filterable
                    >
                      <el-option
                        v-for="item in filteredNodeTypeOptions"
                        :key="item.LABEL"
                        :label="item.LABEL"
                        :value="item.NODE_TYPE"
                      >
                        <div
                          style="
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                          "
                        >
                          <img
                            :src="getIconForNodeType(item.NODE_TYPE)"
                            alt
                            height="18px"
                            width="18px"
                            style="margin-right: 10px"
                          />
                          <span>{{ item.LABEL }}</span>
                        </div>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-col>
      </el-row>

      <el-row type="flex" class="dialog-contents">
        <!-- Show option to configure node -->
        <!-- only when new node is selected -->
        <el-col :lg="12" :md="17" :xs="22" :sm="22">
          <node-configure
            v-if="!!selectedNodeType && linkedOrphanNode === -1"
            :task_id="+task_id"
            :selectedNodeType="selectedNodeType"
            @handleCancel="handleCancelBtnClick"
            @nodeLoading="nodeLoading"
          />
        </el-col>
      </el-row>

      <el-row
        slot="footer"
        type="flex"
        v-if="isCustomNode"
        style="display: flex; justify-content: center"
      >
        <el-col
          :lg="12"
          :md="17"
          :xs="22"
          :sm="22"
          style="display: flex; align-items: flex-start"
        >
          <el-button
            :loading="isNodeSubmit"
            :disabled="
              isTaskReadOnly ||
                validationsInProgress ||
                (linkedOrphanNode === -1 && isEdit && !nodeUpdated)
            "
            @click="handleSaveNodeClick"
            :class="submitNode"
          >
            {{
              linkedOrphanNode !== -1
                ? __("Insert")
                : isEdit
                ? __("Save Changes")
                : __("Create")
            }}
          </el-button>
          <el-button @click="handleCancelBtnClick" class="cancelBtn"
            >{{ __("Cancel") }}
          </el-button>
        </el-col>
      </el-row>
    </component>

    <div id="ghostNode">
      <div class="ghostNode">
        <img src="/icons/integration.svg" alt="node_type" />
        <div class="node-name">{{ __("Integration Node") }}</div>
      </div>
    </div>
    <manage-numbers
      v-if="task"
      :task="this.task"
      :open-modal="openManageNumbersModal"
      @handleCancel="handleCancelManageNumbers"
      @numbers-updated="handleNumbersUpdated"
    ></manage-numbers>

    <el-dialog
      :visible.sync="openTaskSettings"
      v-if="openTaskSettings"
      custom-class="editContentItemModal"
      destroy-on-close
      fullscreen
      :show-close="false"
    >
      <CreateOrEditForm
        :task="clonedTask"
        :id="task.task_id"
        :task-info="clonedTask"
        :languages="languages"
        create-type=""
        :template="null"
        @cancel="handleCancelFromTaskSettings"
      ></CreateOrEditForm>
    </el-dialog>

    <!-- Chatbot preview button -->
    <div
      v-if="currentTask.task_type === 'chatbot' && chatbotPreviewBottomRight"
      id="chatbot-preview-btn"
      :class="{ 'chatbot-preview': chatbotPreviewBottomRight }"
    >
      <ChatbotTaskStartPreview
        class="cfe-control-tooltip"
        :task-key="currentTask.chatbot_task.data.api_key"
        :task-id="task.task_id"
        :pretend-as-widget="chatbotPreviewBottomRight"
      />
    </div>

    <el-tooltip placement="top" style="width: 100px;">
      <template #content>
        <div
          style="display: flex; align-self: stretch; justify-content: center"
        >
          {{ __("Show All Contents") }}
        </div>
      </template>
      <helper-icons />
    </el-tooltip>
    <global-contents @global-contents-closed="fetchNecessaryContentsForTask" />

    <el-dialog
      v-if="showModal"
      :visible.sync="showModal"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
      destroy-on-close
      lock-scroll
      top="5vh"
      width="80%"
      @close="handleLogClose"
      :class="{ logDialog: true, fadeToPeek: this.peekActive }"
      :modal="false"
    >
      <div v-loading="loadingCollectionData">
        <el-row>
          <el-col>
            <header :style="headerStyle">
              {{ __("SYSTEM_LOG") }}
            </header>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-dialog
              :title="__('Filter Data')"
              v-if="dialogVisible"
              :visible.sync="dialogVisible"
              width="50%"
              destroy-on-close
              append-to-body
              @close="searchStartedAt = {}"
            >
              <div class="contentListContainer">
                <!-- propagate input, for your component to work with v-model -->
                <query-condition-builder
                  :fields="queryCollectionStructure"
                  :value="eb"
                  :fieldTypes="fieldTypes"
                  :operators="operators"
                  v-loading="requestingDataStoreData"
                  default-col="_uuid"
                  @previewDataStore="previewData"
                >
                </query-condition-builder>
              </div>
            </el-dialog>
            <preview-table
              hide-seq
              allow-export
              :tableData="dsData"
              :tableColumns="selectedDsCollectionStructure"
              :loading-table="loadingCollectionData"
              @update-sort-event="updateSortEvent"
              @refresh-data-event="refreshDsData(true)"
              @show-query-condition="dialogVisible = true"
              @navigate-to-node="navigateToNode"
              @handle-node-link-hover="handleNodeLinkHoverSysLog"
              @handle-ctrl-key-press-on-hover="
                handleCtrlKeyPressOnHoverFromSysLog
              "
              @handle-ctrl-key-release="handleCtrlKeyRelease"
              @peek-node="peekNodeSysLog"
              @end-peek-node="handleNodeLinkMouseLeave"
              @close-log-modal="showModal = false"
              @filter="handleFilter"
              :show-pagination="true"
              is-system-log
              :show-all-interaction-logs="showAllInteractionLogs"
            >
              <el-form class="form-container" slot="paginationToolbar">
                <PaginationToolbar
                  :content-api="getDataStoresCollection"
                  :show-search="false"
                  :show-slot="true"
                  :key="`view-data-system-log-${requestData.data_store_id}`"
                  list-changed-event-name="documents-changed"
                  @success="handleFetchSuccess"
                >
                </PaginationToolbar>
              </el-form>
            </preview-table>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <!-- RECORDINGS DIALOG -->
    <el-dialog
      v-if="showRecordingsModal"
      :visible.sync="showRecordingsModal"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
      destroy-on-close
      lock-scroll
      top="5vh"
      width="80%"
      :class="{ logDialog: true, fadeToPeek: this.peekActive }"
      :modal="false"
    >
      <div v-loading="loadingCollectionData">
        <el-row>
          <el-col>
            <header :style="headerStyle">
              {{ __("Recordings") }}
            </header>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <div style="padding: 0 36px">
              <el-form class="form-container">
                <PaginationToolbar
                  :content-api="recordingsContentAPI"
                  :show-slot="true"
                  @success="$emit('replaceContent', $event)"
                >
                </PaginationToolbar>
              </el-form>
              <el-scrollbar :native="true">
                <div class="contentListContainer">
                  <el-table
                    ref="recordingsTable"
                    v-loading="isLoading"
                    :data="recordings"
                    highlight-current-row
                    class="list-table"
                  >
                    <el-table-column :label="__('UUID')">
                      <template slot-scope="scope">
                        <span>{{ scope.row.uuid }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="__('File Name')">
                      <template slot-scope="scope">
                        <span>{{ scope.row.file_name }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="__('Timestamp')">
                      <template slot-scope="scope">
                        <span>{{
                          formattedDateTime(scope.row.timestamp)
                        }}</span>
                      </template>
                    </el-table-column>

                    <el-table-column :label="__('Play Audio')">
                      <template slot-scope="scope">
                        <span>
                          <audio-player
                            :simplePlayer="true"
                            :file="scope.row.file_url"
                            showClose="false"
                            :showDelete="true"
                            :record="scope.row"
                            @deleteRecord="handleDeleteRecord"
                          ></audio-player
                        ></span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-scrollbar>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="showDeleteConfirmationDialog"
      width="50%"
      class="check-in-use-dialog"
      :class="{ logDialog: true, fadeToPeek: this.peekActive }"
      append-to-body
      :modal="false"
    >
      <div v-if="avoidDeletingCanvas">
        <span>
          <!-- eslint-disable-next-line -->
          {{ __("Canvas cannot be Deleted. It contains node(s) referenced by other node(s)") }}
        </span>
        <div class="dialog-box-body">
          <div
            class="dialog-box-body items"
            :id="`node-link-${usage.node_id}`"
            :ref="`node-link-${usage.node_id}`"
            @click="navigateToNode(usage.node_id)"
            @mouseover.exact="handleNodeLinkHover(usage.node_id)"
            @keyup="handleCtrlKeyRelease"
            @keydown.ctrl="handleCtrlKeyPressOnHover($event, usage.node_id)"
            @mouseover.ctrl="peekNode(usage.node_id)"
            @mouseout="endPeekNode"
            @mouseleave="handleNodeLinkMouseLeave"
            v-for="(usage, index) in canvasUsages"
            :key="index"
            tabindex="0"
          >
            <el-tooltip
              :content="
                __('Click to navigate to the node or Ctrl+hover to peek')
              "
              placement="right-start"
            >
              <span class="canvas-node-link">{{ usage.label }} </span>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div v-else-if="avoidDeletingNode">
        <div v-if="deleteType">
          <span>
            <!-- eslint-disable-next-line -->
            {{ __("Node cannot be Deleted. Currently referenced by other node(s)") }}
          </span>
        </div>
        <div v-else>
          <span>
            <!-- eslint-disable-next-line -->
            {{ __("Branch cannot be Deleted. It contains node(s) referenced by other node(s)") }}
          </span>
        </div>

        <div class="dialog-box-body">
          <div
            class="dialog-box-body items"
            :id="`node-link-${usage.node_id}`"
            :ref="`node-link-${usage.node_id}`"
            @click="navigateToNode(usage.node_id)"
            @mouseover.exact="handleNodeLinkHover(usage.node_id)"
            @keyup="handleCtrlKeyRelease"
            @keydown.ctrl="handleCtrlKeyPressOnHover($event, usage.node_id)"
            @mouseover.ctrl="peekNode(usage.node_id)"
            @mouseout="endPeekNode"
            @mouseleave="handleNodeLinkMouseLeave"
            v-for="(usage, index) in goToNodeUsages"
            :key="index"
            tabindex="0"
          >
            <el-tooltip
              :content="
                __('Click to navigate to the node or Ctrl+hover to peek')
              "
              placement="right-start"
            >
              <span class="canvas-node-link">{{ usage.label }} </span>
            </el-tooltip>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="showPastedReminderDialog"
      width="50%"
      class="pasted-reminder-dialog"
      :class="{ logDialog: true, fadeToPeek: this.peekActive }"
      :modal="false"
      :close-on-click-modal="false"
      :fullscreen="false"
    >
      <div v-if="requireUpdatingPastedNode" style="margin-left: 5px;">
        <span>
          <!-- eslint-disable-next-line -->
          {{ __("The following node(s) reference external Tasks and need to be fixed") }}
        </span>

        <div
          style="margin-top: 10px; margin-left: 20px; width: 40%; line-height: 30px;"
        >
          <div
            style="outline: none; margin: 0 50px 0 0"
            :id="`node-link-${usage.node_id}`"
            :ref="`node-link-${usage.node_id}`"
            @click="navigateToNode(usage.node_id)"
            @mouseover.exact="handleNodeLinkHover(usage.node_id)"
            @keyup="handleCtrlKeyRelease"
            @keydown.ctrl="handleCtrlKeyPressOnHover($event, usage.node_id)"
            @mouseover.ctrl="peekNode(usage.node_id)"
            @mouseout="endPeekNode"
            @mouseleave="handleNodeLinkMouseLeave"
            v-for="(usage, index) in pastedNodesWithReference"
            :key="index"
            tabindex="0"
          >
            <el-tooltip
              :content="
                __('Click to navigate to the node or Ctrl+hover to peek')
              "
              placement="right-start"
            >
              <span class="canvas-node-link">{{ usage.node_name }} </span>
            </el-tooltip>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import node_template from "@/utils/node_templates/node_template";
import condition_label_template from "@/utils/node_templates/condition_label_template";
import start_node_template from "@/utils/node_templates/start_node_template";
import NodesPalette from "./nodesPalette";
import OrgChartConfiguration, {
  editForm
} from "@/views/build/callflow/OrgChartConfiguration";
import NodeConfigure from "../components/NodeConfigure";
import CanvasTab from "../components/CanvasTab";
import _ from "lodash";
import { getNodeConfig, NODE_CATEGORIES, NODE_TYPES } from "@/constants/nodes";
import { dropCriteria } from "@/utils/canvas";
import ManageNumbers from "@/views/build/tasks/pages/ManageNumbers";

import store from "@/store";

import { includesAny } from "@/utils/collection";
import { copyBranch } from "@/utils/nodeActions/CopyBranch";
import { pasteNode } from "@/utils/nodeActions/PasteNode";
import { cutNode } from "@/utils/nodeActions/CutNode";
import { cutBranch } from "@/utils/nodeActions/CutBranch";
import { copyNode } from "@/utils/nodeActions/CopyNode";
import { deleteNode } from "@/utils/nodeActions/DeleteNode";
import { deleteBranch } from "@/utils/nodeActions/DeleteBranch";
import { getRenderedSubGraph } from "@/store/modules/canvas";
import HelperIcons from "@/layout/components/HelperIcons";
import GlobalContents from "@/layout/components/globalContents/index";

import VersionManager from "./../components/versionManager/VersionManager";
import Dialpad from "@/views/build/callflow/components/webRTC/Dialpad";

import BackButtonHandler from "@/components/BackButtonHandler";
import CanvasListItem from "@/views/build/callflow/components/CanvasListItem";
import CreateOrEditForm from "@/views/build/tasks/create-task/components/createOrEditForm";
import { NODE_LOG_PROFILE } from "@/constants/nodeLoggingProfiles";
import { EventBus } from "@/EventBus";
import PreviewTable from "@/components/queryConditionBuilder/PreviewTable";
import { Conditions, Core } from "vue-renderless-expression-builder";
import QueryConditionBuilder from "@/components/queryConditionBuilder/QueryConditionBuilder";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import DataStore from "@/mixins/DataStore";
import NodeSearch from "@/views/build/callflow/components/node-type-forms/NodeSearch";
import { getTaskForNode } from "@/api/nodes";
import usersUtils from "@/utils/users";
import UserPresence from "@/views/build/callflow/components/UserPresence";
import Copier from "@/components/Copier";
import AudioPlayer from "@/components/AudioPlayer";
import ChatbotTaskStartPreview from "@/views/build/callflow/components/chatbotPreview/StartPreview";
import { MessageBox } from "element-ui";

const { defaultFieldTypes } = Conditions.Defaults;
const DEFAULT_COLLECTION_STRUCTURE = [
  {
    name: "_uuid",
    label: "UUID",
    type: defaultFieldTypes.TEXT,
    choices: null
  },
  {
    name: "_timestamp",
    label: __("TIMESTAMP"),
    type: "timestamp",
    choices: null
  },
  {
    name: "event",
    label: __("EVENT"),
    type: defaultFieldTypes.TEXT,
    choices: null
  },
  {
    name: "param1",
    label: __("PARAM1"),
    type: defaultFieldTypes.TEXT,
    choices: null
  },
  {
    name: "param2",
    label: __("PARAM2"),
    type: defaultFieldTypes.TEXT,
    choices: null
  },
  {
    name: "param3",
    label: __("PARAM3"),
    type: defaultFieldTypes.TEXT,
    choices: null
  },
  {
    name: "details.node_id",
    label: __("Node ID"),
    type: defaultFieldTypes.NUMBER,
    choices: null
  },
  {
    name: "details.node_name",
    label: __("Node Name"),
    type: defaultFieldTypes.TEXT,
    choices: null
  },
  {
    name: "details.no_match_attempts",
    label: __("No Match Attempts"),
    type: defaultFieldTypes.NUMBER,
    choices: null
  },
  {
    name: "details.no_input_attempts",
    label: __("No Input Attempts"),
    type: defaultFieldTypes.NUMBER,
    choices: null
  }
];
export default {
  name: "CallFlowEditor",
  mixins: [BackButtonHandler, BaseContent, DataStore],
  provide() {
    return {
      $onDemandCampaigns: () => this.onDemandCampaigns,
      $phoneNumberToTaskMap: () => this.phoneNumberToTaskMap
    };
  },
  components: {
    NodeSearch,
    Dialpad,
    VersionManager,
    NodesPalette,
    NodeConfigure,
    CanvasTab,
    ManageNumbers,
    CanvasListItem,
    CreateOrEditForm,
    HelperIcons,
    GlobalContents,
    PreviewTable,
    QueryConditionBuilder,
    PaginationToolbar,
    UserPresence,
    Copier,
    AudioPlayer,
    ChatbotTaskStartPreview
  },
  props: ["title", "task_id", "nav_to_node_id", "open_node_id"],
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "label"
      },
      showDeleteConfirmationDialog: false,
      showPastedReminderDialog: false,
      pageSize: 15,
      showModal: false,
      showRecordingsModal: false,
      showTaskValidator: false,
      dialogVisible: false,
      sortByColumns: ["_id"],
      isHovering: false,
      selectedDsCollectionStructure: DEFAULT_COLLECTION_STRUCTURE,
      queryCollectionStructure: DEFAULT_COLLECTION_STRUCTURE,
      previewDataStoreDataResponse: "",
      requestingDataStoreData: false,
      testString: "",
      task: null,
      direction: "rtl", // only works if showAs el-drawer
      showAs: "el-dialog",
      selectedNodeType: null,
      nodeOptions: [],
      linkedOrphanNode: -1,
      isSubmitting: false,
      searchItem: "",
      searchFieldFocused: false,
      searchCanvasFieldFocused: false,
      canvasNameFieldFocused: false,
      canvasFocused: false,
      nodeTypeSearchFieldFocused: false,
      searchStartedAt: {},
      openManageNumbersModal: false,
      onDemandCampaigns: [],
      showWebRTCPopup: false,
      phoneNumberToTaskMap: [],
      canvasSearch: "",
      canvasDropDownVisible: false,
      openTaskSettings: false,
      clonedTask: null,
      debouncedNodeSelectFn: null,
      debouncedValidNodeSearchFn: null,
      validSearchNodes: [],
      targetNodeGroups: {},
      peekActive: false,
      canvasIsPinned: false,
      activeCanvasName: __("Main Dialog"),
      nodeContentLoading: false,
      loadingText: "",
      creatingVersion: false,
      showAllInteractionLogs: false
    };
  },

  computed: {
    ...mapState("canvas", {
      chart: state => state.chart,
      nodes: state => state.nodes,
      clickedNode: state => state.clickedNode,
      clickedRenderedNodeId: state => state.clickedRenderedNodeId,
      prevClickedRenderedNodeId: state => state.prevClickedRenderedNodeId,
      isEdit: state => state.isEdit,
      addNewNodeBelow: state => state.addNewNodeBelow,
      // nodeTypeOptions: state => state.nodeTypeOptions,
      newNodeOption: state => state.newNodeOption,
      isNodeSubmit: state => state.isNodeSubmit,
      validationsInProgress: state => state.validationsInProgress,
      loading: state => state.loading,
      paletteSelection: state => state.paletteSelection,
      nodeUpdated: state => state.nodeUpdated,
      canvases: state => state.canvases,
      newCanvas: state => state.newCanvas,
      selectedCanvas: state => state.selectedCanvas,
      copyType: state => state.copyType,
      copyTaskType: state => state.copyTaskType,
      nodeIdToCopy: state => state.nodeIdToCopy,
      branchSelected: state => state.branchSelected,
      nodeClickFlag: state => state.nodeClickFlag,
      hideBackBtn: state => state.hideBackBtn,
      showExpressionBuilder: state => state.showExpressionBuilder,
      showNotification: state => state.showNotification,
      notificationContent: state => state.notificationContent,
      showDeleteConfirmBox: state => state.showDeleteConfirmBox,
      deleteConfiguration: state => state.deleteConfiguration,
      paletteMounted: state => state.paletteMounted,
      isNodeCancelCompleted: state => state.isNodeCancelCompleted,
      canvasUsages: state => state.canvasUsages,
      goToNodeUsages: state => state.goToNodeUsages,
      pastedNodesWithReference: state => state.pastedNodesWithReference,
      nodeDeleteType: state => state.nodeDeleteType,
      isCanvasCreated: state => state.isCanvasCreated,
      nodeTypesHiddenUnderLD: state => state.nodeTypesHiddenUnderLD
    }),

    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId,
      selectedAccountDomain: state => state.selectedAccountDomain,
      darkTheme: state => state.darkTheme,
      globalContentModalIsOpen: state => state.globalContentModalIsOpen,
      userRole: state => state.userRole
    }),

    ...mapGetters("app", {
      userHasSPScopeToAc: "userHasSPScopeToAc"
    }),

    ...mapState("tasks", {
      tasks: state => state.tasks,
      languages: state => state.languages
    }),

    ...mapState("recordings", {
      recordings: state => state.recordings,
      isLoading: state => state.isLoading
    }),

    ...mapState("variables", {
      showVariableNotification: state => state.showNotification,
      notificationVariableContent: state => state.notificationContent
    }),

    // ...mapState("folders", {
    //   checkInUseLoading: state => state.checkInUseLoading
    // }),

    ...mapState("dataStores", {
      systemLogDataStore: state => state.systemLogDataStore
    }),

    ...mapGetters("canvas", {
      isTaskReadOnly: "isTaskReadOnly",
      hasChild: "hasChild",
      getOrphans: "getOrphans",
      getNoInputNoMatchHandlerCanvasList: "getNoInputNoMatchHandlerCanvasList",
      getValidNodes: "getValidNodes",
      getRootNode: "getRootNode",
      getSubGraph: "getSubGraph",
      nodesToRender: "nodesToRender",
      canvasOfNode: "canvasOfNode",
      currentCanvas: "currentCanvas",
      indexOfCurrentCanvas: "indexOfCurrentCanvas",
      mainCanvas: "mainCanvas",
      renderedNode: "renderedNode",
      parentsIncludeNonRemovableNodes: "parentsIncludeNonRemovableNodes",
      // getOriginalNodeFromRenderedNodeId: "getOriginalNodeFromRenderedNodeId"
      getOriginalNode: "getOriginalNode",
      nodeTypeOptions: "nodeTypeOptions",
      currentTask: "currentTask",
      goToNodeGroups: "goToNodeGroups"
    }),

    ...mapGetters("tasks", {
      getTask: "getTask"
    }),

    ...mapGetters("variables", {
      loadingVariables: "loading"
    }),

    ...mapGetters("app", {
      formattedDateTime: "formattedDateTime"
    }),

    getLoadingText() {
      return (
        __(this.loadingText) +
        (this.loadingText ? "..." : __("loading") + "...")
      );
    },

    getModalContainerClass() {
      return {
        modalContainer: true,
        dark: this.darkTheme,
        protected: this.isTaskReadOnly
      };
    },

    headerStyle() {
      return {
        fontSize: "2.3rem !important",
        fontWeight: "200 !important",
        padding: "0 0 20px 10px"
      };
    },

    /**
     * computed property that returns a trigger type based on canvasIsPinned property.
     */
    triggerForCanvasPopover() {
      return this.canvasIsPinned ? "manual" : "hover";
    },

    /**
     * computed property that returns tooltip message based on canvasIsPinned property.
     */
    popperIdForCanvasPopover() {
      return this.canvasIsPinned ? "pinned-canvas-list" : "canvas-list";
    },

    /**
     * computed property that returns tooltip message based on canvasIsPinned property.
     */
    tooltipContentForCanvasPin() {
      let status = this.canvasIsPinned ? "Un-Pin" : "Pin";
      return __(":status Canvas List", { status: status });
    },

    searchItemComputed: {
      get() {
        return this.searchItem;
      },
      set(val) {
        // we only need to reset it once its empty
        if (!val) {
          this.searchItem = val;
        }
      }
    },

    trimmedValidNodes() {
      return _.take(this.getValidNodes, 500);
    },

    trimmedNodeGroups() {
      return _.groupBy(this.trimmedValidNodes, node => node.node_id);
    },

    avoidDeletingCanvas() {
      return this.canvasUsages.length > 0;
    },

    avoidDeletingNode() {
      return this.goToNodeUsages.length > 0;
    },

    requireUpdatingPastedNode() {
      return this.pastedNodesWithReference.length > 0;
    },

    deleteType() {
      return this.nodeDeleteType === "delete_node";
    },

    dialogTitle() {
      let self = this;
      if (self.avoidDeletingCanvas) {
        return __("Canvas cannot be deleted");
      }
      if (self.avoidDeletingNode) {
        if (self.nodeDeleteType === "delete_node") {
          return __("Node cannot be deleted");
        }
        return __("Branch cannot be deleted");
      }
      if (self.requireUpdatingPastedNode) {
        return __("Node(s) need to be re-configured");
      }
      return __("Are you sure about deleting this Canvas?");
    },

    getPhoneNumbers() {
      if (this.task.phone_number) {
        let totalPhoneNumbers = _.size(this.task.phone_number);
        let phoneNumbers = _.slice(this.task.phone_number, 0, 2);
        if (_.size(phoneNumbers) > 0) {
          let numberString = "[";
          _.map(phoneNumbers, (phoneNumber, index) => {
            if (index === _.size(phoneNumbers) - 1) {
              if (_.size(phoneNumbers) > 1 && index !== totalPhoneNumbers - 1) {
                numberString += phoneNumber.phone_number + " ...]";
              } else {
                numberString += phoneNumber.phone_number + "]";
              }
            } else {
              numberString += phoneNumber.phone_number + ", ";
            }
          });
          return numberString;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },

    // isTaskReadOnly() {
    //   return !!this.task.protected;
    // },

    getCanvases() {
      let canvasList = _.partition(
        _.sortBy(
          _.filter(
            this.canvases,
            canvas =>
              canvas.canvas_name
                .toLowerCase()
                .includes(this.canvasSearch.toLowerCase()) &&
              !canvas.is_ni_nm_handler
          ),
          function(canvas) {
            // keep Main Dialog canvas always at the top of the list
            return canvas.canvas_name === __("Main Dialog")
              ? -1
              : canvas.canvas_name;
          }
        ),
        "is_event_handler"
      );
      let ni_nm_handler = _.sortBy(
        _.filter(this.canvases, canvas => {
          return (
            canvas.canvas_name
              .toLowerCase()
              .includes(this.canvasSearch.toLowerCase()) &&
            canvas.is_ni_nm_handler
          );
        }),
        function(canvas) {
          return canvas.canvas_name;
        }
      );
      canvasList.push(ni_nm_handler);
      return canvasList;
    },

    isStartNodeSelected() {
      return this.selectedNodeType === NODE_TYPES.START.NODE_TYPE;
    },

    getSearchIconSVG() {
      return require("@/assets/icons/icon-search.svg");
    },

    getCopyKeySVG() {
      return require("@/assets/icons/icon-api.svg");
    },

    getVersioningSVG() {
      return require("@/assets/icons/icon-versioning.svg");
    },

    getWebRTCSVG() {
      return require("@/assets/icons/icon-webrtc.svg");
    },

    getMicrophoneSVG() {
      return require("@/assets/icons/icon-microphone.svg");
    },

    getCallLogIconSVG() {
      return require("@/assets/icons/icon-call-logs.svg");
    },

    getTaskValidatorIconSVG() {
      return require("@/assets/icons/icon-task-validator.svg");
    },

    getTaskEditHistoryIconSVG() {
      return require("@/assets/icons/icon-task-edit-history.svg");
    },

    getPhoneSvg() {
      return require("@/assets/icons/icon-phone.svg");
    },

    getIconPreview() {
      return require("@/assets/icons/icon-preview.svg");
    },

    darkModeClass() {
      return this.darkTheme ? "dark" : "";
    },

    isInsertPossible() {
      return !this.isEdit && !_.isEmpty(this.clickedNode);
    },

    nodeSelected: {
      get: function() {
        return !_.isEmpty(this.clickedNode);
      },
      set: function() {
        this.setClickedNode(null);
      }
    },

    nodeTypeOptionsAvailableForTask() {
      return (
        !_.isEmpty(this.task) &&
        this.nodeTypeOptions(this.selectedAccountId, this.task.task_type, true)
      );
    },

    filteredNodeTypeOptions() {
      return _.isEmpty(this.task)
        ? []
        : !_.isEmpty(this.clickedNode) &&
          !this.hasChild(this.clickedNode.node_id)
        ? _.filter(
            this.nodeTypeOptionsAvailableForTask,
            nodeTypeOption => !nodeTypeOption.CANNOT_CREATE_NEW
          )
        : _.filter(this.nodeTypeOptionsAvailableForTask, nodeTypeOption => {
            return (
              nodeTypeOption.CATEGORY !== NODE_CATEGORIES.NO_CHILD &&
              !nodeTypeOption.CANNOT_CREATE_NEW
            );
          });
    },
    isCustomNode() {
      return this.selectedNodeType !== "web_service";
    },
    orphanNodes() {
      const options = _.map(this.getOrphans, orphan => ({
        label: orphan.node_name,
        value: orphan.node_id
      }));
      return this.nodeOptions.concat(
        _.filter(
          options,
          option =>
            option.value !==
              this.getRootNode(this.clickedNode.node_id).node_id &&
            option.value !== undefined
        )
      );
    },
    getNodeIcon() {
      let nodeType = this.selectedNodeType;
      return "/icons/" + this.customiseNodeType(nodeType);
    },
    nodeModal() {
      return "node-modal-" + this.selectedNodeType + " node-modal";
    },
    submitNode() {
      return "submitBtn node-submit-" + this.selectedNodeType;
    },
    /**
     * show loading spinner for the callflow editor page
     * @returns {*}
     */
    loadingCallflowEditor() {
      return this.loading || this.loadingVariables || this.creatingVersion;
    },

    /**
     * Check if the chatbot preview trigger should be displayed on the bottom right
     */
    chatbotPreviewBottomRight() {
      return true;
    }
  },
  methods: {
    ...mapActions("canvas", {
      setReadOnlyAccess: "setReadOnlyAccess",
      setHideBackBtn: "setHideBackBtn",
      removeNodeTypeHiddenUnderLd: "removeNodeTypeHiddenUnderLd",
      resetHiddenNodesUnderLd: "resetHiddenNodesUnderLd",
      setTask: "setTask",
      setTaskModifiedFlag: "setTaskModifiedFlag",
      setTaskType: "setTaskType",
      setChart: "setChart",
      setZoom: "setZoom",
      getNodes: "getNodes",
      setClickedNode: "setClickedNode",
      changeCanvasNodeMode: "changeCanvasNodeMode",
      removeNodes: "removeNodes",
      removeCanvases: "removeCanvases",
      editNode: "editNode",
      toggleNodeSubmit: "toggleNodeSubmit",
      toggleNodeCancelCompletedState: "toggleNodeCancelCompletedState",
      resetPaletteSelection: "resetPaletteSelection",
      changeSelectedCanvas: "changeSelectedCanvas",
      createCanvas: "createCanvas",
      renameCanvas: "renameCanvas",
      updateCanvas: "updateCanvas",
      centerCanvas: "centerCanvas",
      setNodesInChart: "setNodesInChart",
      resetCopyState: "resetCopyState",
      setClickedRenderedNodeId: "setClickedRenderedNodeId",
      changeBranchSelectedFlag: "changeBranchSelectedFlag",
      removeSelections: "removeSelections",
      setExpressionBuilderModalShow: "setExpressionBuilderModalShow",
      setShowNotificationStatus: "setShowNotificationStatus",
      setShowDeleteConfirmationBox: "setShowDeleteConfirmationBox",
      deleteNodeAction: "deleteNode",
      setPaletteMounted: "setPaletteMounted",
      respondToClickOrDragOrDrop: "respondToClickOrDragOrDrop",
      forceFetchCanvases: "getCanvases",
      deleteCanvas: "deleteCanvas",
      setCanvasUsages: "setCanvasUsages",
      setGoToNodeUsages: "setGoToNodeUsages",
      setPastedNodesWithReference: "setPastedNodesWithReference",
      reloadTaskAndOverwrite: "reloadTaskAndOverwrite",
      changeLoadingState: "changeLoadingState",
      removeHighlightOnAllSelectedNodesAction:
        "removeHighlightOnAllSelectedNodesAction",
      addHighlightOnSelectedNodeAction: "addHighlightOnSelectedNodeAction",
      setSearchResults: "setSearchResults",
      updateNodeNameCopy: "updateNodeNameCopy"
    }),

    ...mapActions("nodeTypes", {
      accountNodeTypes: "accountNodeTypes"
    }),

    ...mapActions("variables", {
      fetchVariables: "forceFetchVariables",
      setShowVariableNotificationStatus: "setShowNotificationStatus"
    }),

    ...mapActions("recordings", {
      recordingsContentAPI: "getRecordings",
      deleteRecording: "deleteRecording"
    }),

    ...mapActions("tasks", {
      setTaskFilter: "setTaskFilter",
      getVersions: "getVersions",
      taskModified: "taskModified",
      forceFetchTask: "forceFetchTask",
      getLanguages: "getLanguages"
    }),

    ...mapActions("nodeLoggingProfiles", {
      getNodeLoggingProfiles: "getNodeLoggingProfiles"
    }),

    ...mapActions("prompts", {
      getPrompts: "getPrompts"
    }),

    ...mapActions("campaigns", {
      getOnDemandCampaigns: "getOnDemandCampaigns"
    }),

    ...mapActions("phonenumbers", {
      getPhoneNumberToTaskMap: "getCampaignNumbers"
    }),

    ...mapActions("datastorecollection", {
      getDataStoresCollection: "getDataStoresCollection"
    }),

    ...mapActions("dataStores", {
      getSystemLogDataStore: "getSysLogDataStore"
    }),

    ...mapActions("folders", {
      updateSelectedTaskId: "updateSelectedTaskId",
      checkInUse: "checkInUse"
    }),

    ...mapActions("app", {
      toggleCanvasMode: "toggleCanvasMode"
    }),

    ...mapActions("expressionbuilder", {
      fetchJavascriptFunctions: "updateUserJavascript"
    }),

    checkHideBackBtn() {
      if (!this.hideBackBtn) {
        const urlParams = new URLSearchParams(window.location.search);
        const hideBackBtn = urlParams.get("hideBackBtn") === "true";
        this.setHideBackBtn(hideBackBtn);
      }
    },

    async initQueryCollectionStructure() {
      await this.initShowInteractionLogs();
      if (this.showAllInteractionLogs) {
        this.queryCollectionStructure = [
          ...DEFAULT_COLLECTION_STRUCTURE,
          {
            name: "_task_id",
            label: __("Task ID"),
            type: defaultFieldTypes.NUMBER,
            choices: null
          }
        ];
      }
    },

    async initialPreviewTableColumns() {
      await this.initShowInteractionLogs();
      if (this.showAllInteractionLogs) {
        const newElement = [
          {
            name: "_task_id",
            label: __("TASK ID"),
            type: defaultFieldTypes.NUMBER,
            choices: null
          },
          {
            name: "_task_name",
            label: __("TASK NAME"),
            type: defaultFieldTypes.TEXT,
            choices: null
          }
        ];

        const timestampIndex = this.selectedDsCollectionStructure.findIndex(
          item => item.name === "_timestamp"
        );

        this.selectedDsCollectionStructure = [
          ...this.selectedDsCollectionStructure.slice(0, timestampIndex + 1),
          ...newElement,
          ...this.selectedDsCollectionStructure.slice(timestampIndex + 1)
        ];
      }
    },

    handleVersionSwitchProcess(val) {
      if (val) {
        this.changeLoadingState(val);
      }
    },

    handleVersionCreation(val) {
      this.creatingVersion = val;
    },

    changeLoadingText(val) {
      this.loadingText = val;
    },

    openNode(chart, nodeId) {
      let nodeConfigForm = new editForm();
      nodeConfigForm.init(chart);
      nodeConfigForm.show(+nodeId);
    },

    /**
     * register mousemove, up and down events for pinned canvas
     */
    registerPinnedCanvasListeners() {
      this.$nextTick(() => {
        let pinnedCanvas = document.querySelector("#pinned-canvas-list div");
        let nodeChartBounds = document
          .getElementById("orgchart")
          .getBoundingClientRect();

        pinnedCanvas.onmousedown = function(ev) {
          let shiftX = ev.clientX - pinnedCanvas.getBoundingClientRect().left;
          let shiftY = ev.clientY - pinnedCanvas.getBoundingClientRect().top;

          pinnedCanvas.style.position = "absolute";
          // initial move when mouse is clicked and dragged
          moveAt(ev.pageX, ev.pageY);

          // moves the pinned canvas at (pageX, pageY) coordinates
          // considering initial shifts, node-chart bounds
          function moveAt(pageX, pageY) {
            let canvasX = pageX - shiftX;
            let canvasY = pageY - shiftY;
            // margin around chart boundaries for canvas popover
            let boundaryOffset = 10;
            if (
              canvasX >
              nodeChartBounds.right - pinnedCanvas.clientWidth - boundaryOffset
            )
              canvasX =
                nodeChartBounds.right -
                pinnedCanvas.clientWidth -
                boundaryOffset;
            if (canvasX < nodeChartBounds.left + boundaryOffset)
              canvasX = nodeChartBounds.left + boundaryOffset;
            if (canvasY < nodeChartBounds.top) canvasY = nodeChartBounds.top;
            if (
              canvasY >
              nodeChartBounds.bottom -
                pinnedCanvas.clientHeight -
                boundaryOffset
            )
              canvasY =
                nodeChartBounds.bottom -
                pinnedCanvas.clientHeight -
                boundaryOffset;

            pinnedCanvas.style.left = canvasX + "px";
            pinnedCanvas.style.top = canvasY + "px";
          }

          function onMouseMove(ev) {
            moveAt(ev.pageX, ev.pageY);
          }

          // remove unneeded handlers and set canvas popover position as fixed
          function removePinnedCanvasListeners() {
            document.removeEventListener("mousemove", onMouseMove);
            pinnedCanvas.style.position = "fixed";
          }

          // move the pinned canvas on mousemove
          document.addEventListener("mousemove", onMouseMove);

          // drop the pinned canvas
          pinnedCanvas.onmouseleave = function() {
            removePinnedCanvasListeners();
          };

          // when mouse pointer leaves the pinned canvas area
          pinnedCanvas.onmouseup = function() {
            removePinnedCanvasListeners();
          };
        };
      });
    },

    /**
     *  handling function when pin canvas element is clicked
     */
    handlePinCanvasClick() {
      this.canvasIsPinned = !this.canvasIsPinned;
      this.canvasDropDownVisible = this.canvasIsPinned;
      if (this.canvasIsPinned) {
        this.registerPinnedCanvasListeners();
      }
    },

    focusOnNodeLink(nodeId) {
      let s = "node-link-" + nodeId;
      document.getElementById(s).focus();
    },
    focusOnNodeLinkSysLog(nodeId, componentId) {
      let s = "node-link-sys-log-" + nodeId + "-" + componentId;
      const elem = document.getElementById(s);
      elem.focus({
        preventScroll: true
      });
    },
    removeNodeLinkFocus(nodeId) {
      let s = "node-link-" + nodeId;
      document.getElementById(s).blur();
    },

    handleNodeLinkHover(nodeId) {
      this.isHovering = true;
      this.focusOnNodeLink(nodeId);
    },

    handleNodeLinkHoverSysLog(nodeId, componentId) {
      this.isHovering = true;
      this.focusOnNodeLinkSysLog(nodeId, componentId);
    },

    handleNodeLinkMouseLeave() {
      this.isHovering = false;
    },

    handleFilter({ colName, value }) {
      let uuid_condition_filter = this.createColumnEqualsValueFilterCondition(
        colName,
        "text",
        value
      );
      this.setNewQueryCondition(uuid_condition_filter);
      this.$nextTick(() => {
        EventBus.$emit("documents-changed", this.dsData, { page: 1 });
      });
    },

    async previewData() {
      await this.buildRequestData();
      this.$nextTick(() => {
        EventBus.$emit("documents-changed", this.dsData, { page: 1 });
      });
    },

    refreshDsData(removeCondition = false) {
      let additionalInfo = {};
      if (removeCondition) {
        this.resetConditionValue();
        additionalInfo.page = 1;
      }
      this.$nextTick(() => {
        EventBus.$emit("documents-changed", this.dsData, additionalInfo);
      });
    },

    updateSortEvent(orderBy) {
      this.orderBy = orderBy;
      this.previewData();
    },

    navigateToNode(nodeId) {
      if (nodeId === "new") {
        return;
      }
      if (this.getOriginalNode(nodeId)) {
        this.showModal = false;
        this.showDeleteConfirmationDialog = false;
        this.peekActive = false;
        this.searchStartedAt = {};
        this.handleNodeHover({ node_id: nodeId });
      } else {
        if (this.showAllInteractionLogs) {
          window.open(`/build/callflow/node/${nodeId}`, "_blank");
        } else {
          this.$message.error({
            message: __("This node does not exist in the Call-flow")
          });
        }
      }
    },
    async peekNodeSysLog(nodeId, componentId) {
      if (nodeId === "new") {
        return;
      }
      if (this.getOriginalNode(nodeId)) {
        await this.handleNodeHover({ node_id: nodeId });
        this.focusOnNodeLinkSysLog(nodeId, componentId);
        this.peekActive = true;
      } else {
        this.$message.error({
          message: __("This node does not exist in the Call-flow")
        });
      }
    },
    async peekNode(nodeId) {
      if (nodeId === "new") {
        return;
      }
      if (this.getOriginalNode(nodeId)) {
        await this.handleNodeHover({ node_id: nodeId });
        this.focusOnNodeLink(nodeId);
        this.peekActive = true;
      } else {
        this.$message.error({
          message: __("This node does not exist in the Call-flow")
        });
      }
    },

    endPeekNode() {
      this.peekActive = false;
      if (this.searchStartedAt.nodeId) {
        this.handleNodeHover({ node_id: this.searchStartedAt.nodeId });
      }
    },

    async initShowInteractionLogs() {
      this.showAllInteractionLogs = await this.showFeature(
        this.$getConst("SHOW_ALL_INTERACTION_LOGS")
      );
    },

    buildRequestData() {
      let requestData = {};
      requestData["data_store_id"] = this.systemLogDataStore[0].data_store_id;
      requestData["where"] = this.eb.root.toJSON();
      requestData["row_limit"] = this.rowLimit;
      requestData["order_by"] = this.orderBy;
      requestData["task_id"] = this.task_id;
      //todo:uncomment this line if the old confidential data is decided to be masked.
      // requestData["log_should_be_masked"] = this.task.is_system_log_hidden;
      this.setRequestData(requestData);
    },

    handleLogClose() {
      this.eb = new Core.ExpressionBuilder(
        {
          maxDepth: 0,
          connectionType: "and",
          children: []
        },
        err => {
          console.log(err);
        }
      );
    },

    showLogs() {
      if (this.systemLogDataStore.length) {
        this.searchStartedAt.canvas = this.currentCanvas.canvas_name;
        this.searchStartedAt.nodeId = this.clickedRenderedNodeId;
        this.updateSelectedTaskId(this.task_id);
        this.buildRequestData();
        this.showModal = true;
      }
    },

    showRecordings() {
      this.updateSelectedTaskId(this.task_id);
      this.showRecordingsModal = true;
    },

    handleValidatorClick() {
      EventBus.$emit("show-task-validator");
    },

    handleTaskEditHistoryClick() {
      EventBus.$emit("show-task-edit-history");
    },

    handleCancelFromTaskSettings(task) {
      if (task) {
        this.task = { ...this.task, ...task };
        this.setTask(this.task);
        this.clonedTask = _.cloneDeep(this.task);
        if (this.task.task_type === "voice") {
          this.handleTaskSettingsChange({
            beep_detection: this.task.voice_task.data.beep_detection,
            machine_detection: this.task.voice_task.data.machine_detection
          });
        }
      }
      this.openTaskSettings = false;
    },

    customiseNodeType(nodeType) {
      // Icon change logic for special node actions(using png as having png icons at the moment for special actions)
      if (nodeType === "web_service") {
        let node = this.clickedNode.web_service_node;
        if (!_.isEmpty(node)) {
          nodeType = node.data.action_name;
          return nodeType + ".png";
        }
      }
      return nodeType + ".svg";
    },

    handleSaveNodeClick() {
      if (this.selectedNodeType === "send_email") {
        EventBus.$emit("save-rich-text-editor");
      }
      this.$router.push({
        name: "callflow",
        params: { task_id: this.clickedNode.task_id }
      });
      this.retainFocus();
      this.updateNodeNameCopy(null);

      if (!this.isTaskReadOnly) {
        this.linkedOrphanNode !== -1
          ? this.linkOrphanNode()
          : this.toggleNodeSubmit(true);
      } else {
        this.$message.error(__("Not Permitted!"));
      }
    },

    handleNumbersUpdated() {
      EventBus.$emit("log-user-activity", "updated number(s)", null);
      this.forceFetchTask(this.task.task_id)
        .then(task => {
          this.task = task;
          this.setTask(this.task);
        })
        .catch(() => {
          this.$message.error(__("could not get updated task details"));
        });
    },

    handleCancelManageNumbers() {
      this.openManageNumbersModal = false;
    },

    handleBackBtnClick() {
      let target = !this.task.is_template ? "tasks" : "task-library";
      if (this.globalContentModalIsOpen) {
        EventBus.$emit("close-contents-modal");
      }

      this.$router
        .push({ name: target })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.task = null;
          this.updateSelectedTaskId(-1);
          this.setTask(this.task);
          this.updateNodeNameCopy(null);
        });
    },

    backButtonHandler(to, from, isBackButton) {
      if (isBackButton) {
        if (this.globalContentModalIsOpen) {
          EventBus.$emit("close-contents-modal");
          return false;
        } else if (this.dialogVisible) {
          this.dialogVisible = false;
          return false;
        } else if (this.showDeleteConfirmationDialog) {
          this.showDeleteConfirmationDialog = false;
          return false;
        } else if (this.showModal) {
          this.showModal = false;
          this.handleLogClose();
          return false;
        } else if (this.nodeSelected) {
          this.handleCancelBtnClick();
          return false;
        } else if (this.openManageNumbersModal) {
          this.handleCancelManageNumbers();
          return false;
        } else if (this.openTaskSettings) {
          this.handleCancelFromTaskSettings();
          return false;
        } else if (this.canvasIsPinned) {
          this.handlePinCanvasClick();
          return false;
        } else if (to.name === "edit_node") {
          this.$router.push({ name: "tasks" });
          return false;
        }
      }

      if (!["callflow", "edit_node"].includes(to.name)) {
        this.updateSelectedTaskId(-1);
        store.dispatch("app/toggleCanvasMode", false);
      }
      return true;
    },

    handleVersionChange() {
      this.setTaskModifiedToFalse();
      this.initializeCallFlowEditor();
    },

    /**
     * A method that is called when the fetching of task dependencies fails.
     * @param err Error object
     */
    handleFetchTaskDependenciesFailure(err) {
      if (err.response.status === 403) {
        let requestUrl = _.get(err, "response.config.url", "");
        let requestContent =
          _.last(requestUrl.split("?")[0].split("/")) || "dependency";
        this.$message.error(
          // eslint-disable-next-line
          __("Unable to get task details. Please contact your system administrator to assign :requestContent permissions to view the task details.", {
              requestContent: requestContent.toUpperCase()
            }
          )
        );
      }
    },

    handleTaskSettingsChange({ beep_detection, machine_detection }) {
      if (!beep_detection && this.selectedCanvas === "Beep Detection") {
        this.changeSelectedCanvas(this.mainCanvas.canvas_name);
      }
      if (!machine_detection && this.selectedCanvas === "Machine Detection") {
        this.changeSelectedCanvas(this.mainCanvas.canvas_name);
      }
      this.getNodes({
        ac_id: this.task.ac_id,
        task_id: this.task.task_id
      });
    },

    setTaskModifiedToFalse() {
      this.taskModified({ task_id: this.task.task_id, modified: false });
      this.setTaskModifiedFlag(false);
    },

    fetchNecessaryContentsForTask() {
      Promise.all([
        this.fetchVariables(),
        this.getPrompts({ fetch_all: 1 }),
        this.fetchJavascriptFunctions()
      ]).catch(err => {
        this.handleFetchTaskDependenciesFailure(err);
      });
      this.buildRequestData();
    },

    async initializeCallFlowEditor() {
      this.toggleCanvasMode(true);
      this.setClickedNode(null);
      this.searchItem = "";
      this.nodeOptions = [this.newNodeOption];

      this.forceFetchTask(this.task_id)
        .then(async task => {
          this.clonedTask = _.cloneDeep(task);
          if (
            !task.is_template &&
            (!this.can(task.task_type) || this.selectedAccountId !== task.ac_id)
          ) {
            this.$message.error(__("permission denied"));
            this.$router.push("/").catch(() => {});
          } else {
            this.task = _.cloneDeep(task);
            this.setTask(this.task);
            this.setTaskFilter(this.task.task_type);
            await this.setTaskType(this.task.task_type);

            // only allow copy and paste node/branch from one task to the other of the same type
            if (
              this.copyTaskType !== this.task.task_type ||
              ["cut_node", "cut_branch"].includes(this.copyType)
            ) {
              this.resetCopyState();
            }

            if (this.can("build.tasks.write")) {
              this.setReadOnlyAccess(
                this.task &&
                  this.task.is_template &&
                  ((this.task.template.is_system &&
                    !this.$auth.check("system")) ||
                    !this.userHasSPScopeToAc ||
                    this.selectedAccountId !== this.task.ac_id ||
                    !this.can("discover.task-library.write"))
              );
            } else {
              this.setReadOnlyAccess(true);
            }
            this.accountNodeTypes({ task_type: this.task.task_type }).then(
              async () => {
                this.filterNodeTypesHiddenUnderLd();

                node_template.genericNode();
                condition_label_template.conditionLabelNode();
                start_node_template.startNode();

                if (
                  _.has(
                    this.nodeTypeOptionsAvailableForTask,
                    "TRIGGER_CAMPAIGN"
                  )
                ) {
                  Promise.all([
                    this.getOnDemandCampaigns(),
                    this.getPhoneNumberToTaskMap({
                      fetch_all: 1,
                      for_campaign_cli: 1,
                      ac_id: this.selectedAccountId
                    })
                  ])
                    .then(results => {
                      this.onDemandCampaigns = results[0];
                      this.phoneNumberToTaskMap = results[1].data;
                    })
                    .catch(err => {
                      console.log(err);
                    });
                }

                Promise.all([
                  this.fetchVariables(),
                  this.getPrompts({ fetch_all: 1 }),
                  this.fetchJavascriptFunctions(),
                  this.getNodes({
                    ac_id: this.selectedAccountId,
                    task_id: this.task_id
                  })
                ])
                  .then(() => {
                    this.$nextTick(async () => {
                      await this.setChart(
                        // eslint-disable-next-line no-undef
                        new OrgChart(
                          document.getElementById("orgchart"),
                          OrgChartConfiguration
                        )
                      );
                      await this.changeSelectedCanvas(
                        this.mainCanvas.canvas_name
                      );
                      this.returnValidNodes();
                      if (this.nav_to_node_id) {
                        this.navigateToNode(this.nav_to_node_id);
                      } else if (
                        this.open_node_id &&
                        this.open_node_id !== "new"
                      ) {
                        this.navigateToNode(this.open_node_id);
                        this.openNode(this.chart, this.open_node_id);
                      }
                    });
                  })
                  .catch(err => {
                    console.log(err);
                    this.handleFetchTaskDependenciesFailure(err);
                  });

                Promise.all([
                  this.getLanguages(),
                  _.map(NODE_LOG_PROFILE, profile_type => {
                    this.getNodeLoggingProfiles({
                      fetch_all: 1,
                      profile_type,
                      fetch_minimum_details: 1
                    });
                  })
                ])
                  .then(() => {})
                  .catch(err => console.log(err));
              }
            );
          }
        })
        .catch(err => {
          if (err.response.status === 403) {
            this.$message.error(__("permission denied"));
          } else {
            this.$message.error(__("unable to fetch the task"));
          }
          this.$router.push("/").catch(() => {});
        });
      this.getSystemLogDataStore();
    },

    handleHotKeyPress(ev) {
      if (_.isEmpty(this.clickedNode)) {
        const { code } = ev;
        let preventAndStopPropagation = false;

        let zoom;

        switch (code) {
          case "Slash":
            if (!this.searchFieldFocused) {
              this.$refs.searchbar.focus();
              preventAndStopPropagation = true;
            }
            break;
          case "ArrowUp":
          case "ArrowDown":
          case "ArrowRight":
          case "ArrowLeft":
            if (
              !this.searchFieldFocused &&
              !this.nodeTypeSearchFieldFocused &&
              !this.canvasFocused
            ) {
              this.moveSelection(code);
              this.retainFocus();
              preventAndStopPropagation = true;
            }
            break;
          case "Enter":
          case "NumpadEnter":
            // we have opened task settings then prevent navigating to selected node
            if (!this.openTaskSettings) {
              this.openNode(this.chart, this.clickedRenderedNodeId);
            }
            preventAndStopPropagation = true;
            break;
          case "NumpadAdd":
            zoom = true;
            break;
          case "NumpadSubtract":
            zoom = false;
            break;
          default:
            break;
        }

        if (preventAndStopPropagation) {
          ev.preventDefault();
          ev.stopPropagation();
        }

        if (zoom !== undefined) {
          this.handleZoom(zoom);
        }
      }

      return true;
    },

    //todo can be improved, for now its ok
    async moveSelection(code) {
      let nodeId;
      let node;
      if (!this.clickedRenderedNodeId) {
        nodeId =
          this.prevClickedRenderedNodeId &&
          // can only center to prevClickedRenderedNodeId if it exists in the current canvas that is rendered
          (this.prevClickedRenderedNodeId ===
            this.currentCanvas.first_node_id ||
            getRenderedSubGraph(
              this.nodesToRender,
              this.currentCanvas.first_node_id
            ).includes(String(this.prevClickedRenderedNodeId)))
            ? this.prevClickedRenderedNodeId
            : this.currentCanvas.first_node_id;
      } else {
        let BGNode = this.chart.getBGNode(this.clickedRenderedNodeId);
        switch (code) {
          case "ArrowUp":
            if (!_.isEmpty(BGNode.parent)) {
              BGNode = this.chart.getBGNode(BGNode.pid);
              while (
                !_.isEmpty(BGNode.parent) &&
                BGNode.tags.includes(NODE_CATEGORIES.DUMMY)
              ) {
                BGNode = this.chart.getBGNode(BGNode.pid);
              }
              nodeId = BGNode.id;
            }
            break;
          case "ArrowDown":
            if (!_.isEmpty(BGNode.children)) {
              BGNode = this.chart.getBGNode(BGNode.children[0].id);
              while (
                !_.isEmpty(BGNode.children) &&
                BGNode.tags.includes(NODE_CATEGORIES.DUMMY)
              ) {
                BGNode = this.chart.getBGNode(BGNode.children[0].id);
              }
              nodeId = BGNode.id;
            }
            break;
          case "ArrowLeft":
            if (!_.isEmpty(BGNode.leftNeighbor)) {
              BGNode = this.chart.getBGNode(BGNode.leftNeighbor.id);
              while (
                !_.isEmpty(BGNode.children) &&
                BGNode.tags.includes(NODE_CATEGORIES.DUMMY)
              ) {
                BGNode = this.chart.getBGNode(BGNode.children[0].id);
              }
              nodeId = BGNode.id;
            }
            break;
          case "ArrowRight":
            if (!_.isEmpty(BGNode.rightNeighbor)) {
              BGNode = this.chart.getBGNode(BGNode.rightNeighbor.id);
              while (
                !_.isEmpty(BGNode.children) &&
                BGNode.tags.includes(NODE_CATEGORIES.DUMMY)
              ) {
                BGNode = this.chart.getBGNode(BGNode.children[0].id);
              }
              nodeId = BGNode.id;
            }
            break;
          default:
            break;
        }
      }
      if (nodeId) {
        node = this.renderedNode(nodeId);
        this.respondToClickOrDragOrDrop({ node, action: "click" });
        await this.setNodesInChart(this.nodesToRender);
        this.centerCanvas(nodeId);
      }
    },

    handleSearchBarFocus() {
      // no more going back to search start node.
      // if (resetSearchInitiatedCanvas && _.isEmpty(this.searchStartedAt)) {
      //   this.searchStartedAt.canvas = this.currentCanvas.canvas_name;
      //   this.searchStartedAt.nodeId = this.clickedRenderedNodeId;
      // }
      this.markSearchInputFieldFocused(true);
    },
    async clearSearchInputs() {
      this.searchStartedAt = {};
      this.markSearchInputFieldFocused(false);
    },

    async closeSearchBar() {
      await this.clearSearchInputs();
      this.retainFocus();
    },

    markSearchInputFieldFocused(val) {
      this.searchFieldFocused = val;
    },

    /**
     * Mark search canvas field focus
     * @param { Boolean } val New focus state
     */
    markSearchCanvasFieldFocused(val) {
      this.searchCanvasFieldFocused = val;
    },

    /**
     * Mark search canvas field focused
     * @param { Boolean } val New focus state
     */

    markCanvasNameFieldFocused(val) {
      this.canvasNameFieldFocused = val;
    },

    markNodeTypeSearchInputFieldFocused(val) {
      this.nodeTypeSearchFieldFocused = val;
    },

    handleNodeHover(node) {
      if (
        !_.isEmpty(node) &&
        (!this.clickedRenderedNodeId ||
          (this.clickedRenderedNodeId &&
            node.node_id.toString() !== this.clickedRenderedNodeId.toString()))
      ) {
        const canvasOfNode = this.canvasOfNode(node.node_id);
        if (!!canvasOfNode && canvasOfNode !== this.currentCanvas.canvas_name) {
          this.changeSelectedCanvas(canvasOfNode);
        }
        this.setClickedRenderedNodeId(node.node_id);
        this.setNodesInChart(this.nodesToRender);
        this.centerCanvas(node.node_id);
      }
    },

    debouncedHandleNodeSelect(value, selected = true) {
      if (this.debouncedNodeSelectFn) {
        this.debouncedNodeSelectFn.cancel();
      }
      if (selected === false) {
        this.debouncedNodeSelectFn = _.debounce(
          () => this.handleNodeSelect(value, selected),
          300
        );
        this.debouncedNodeSelectFn();
      } else {
        this.handleNodeSelect(value, selected);
      }
    },

    handleNodeSelect(value, selected = true) {
      if (value) {
        this.handleNodeHover({ node_id: value });
        if (selected) {
          this.searchStartedAt = {};
          this.$nextTick(() => {
            this.closeSearchBar();
          });
        }
      }
    },

    handleNodeSearchClose(val) {
      this.$nextTick(() => {
        if (!val) {
          this.closeSearchBar();
        }
      });
    },

    setSearchItem(value) {
      this.searchItem = value;
      if (!value) {
        this.$nextTick(() => {
          this.handleSearchBarFocus();
          this.retainFocus();
        });
      }
    },

    // valid nodes include nodes that matches search string and target of goto nodes that matches
    returnValidNodes() {
      if (this.searchItem.trim()) {
        // filter by search string
        this.targetNodeGroups = _.groupBy(
          _.take(
            this.getValidNodes.filter(
              this.createFilter(this.searchItem.trim())
            ),
            500
          ),
          node => node.node_id
        );

        // add gotoNodes
        _.map(this.targetNodeGroups, (nodeArr, key) => {
          if (this.goToNodeGroups[key]) {
            this.targetNodeGroups[key] = nodeArr.concat(
              this.goToNodeGroups[key]
            );
          }
        });

        this.validSearchNodes = _.uniqBy(
          _.flatMapDeep(this.targetNodeGroups, node => node),
          node => node.node_id
        );
      } else {
        this.validSearchNodes = this.trimmedValidNodes;
        this.targetNodeGroups = this.trimmedNodeGroups;
      }
    },

    createFilter(queryString) {
      return node => {
        if (_.isEmpty(node)) {
          return false;
        }
        let nodeConfigObj = getNodeConfig(node.node_type.data.node_type);
        if (_.isEmpty(nodeConfigObj)) {
          return false;
        }
        return (
          this.containsSearchQuery(node.node_name, queryString) ||
          this.startsWithSearchQuery(nodeConfigObj.NODE_TYPE, queryString) ||
          this.startsWithSearchQuery(nodeConfigObj.LABEL, queryString) ||
          this.startsWithSearchQuery(nodeConfigObj.CATEGORY, queryString) ||
          this.startsWithSearchQuery(nodeConfigObj.TOOLTIP, queryString)
        );
      };
    },

    containsSearchQuery(searchOn, queryString) {
      if (_.isEmpty(searchOn) || _.isEmpty(queryString)) {
        return false;
      }
      return searchOn.toLowerCase().indexOf(queryString.toLowerCase()) > -1;
    },

    startsWithSearchQuery(searchOn, queryString) {
      if (_.isEmpty(searchOn) || _.isEmpty(queryString)) {
        return false;
      }
      return searchOn.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
    },

    async handleEscapeKeyDownEvent(ev) {
      if (this.searchFieldFocused) {
        ev.preventDefault();
        ev.stopPropagation();
        this.closeSearchBar();
      } else if (this.nodeTypeSearchFieldFocused) {
        ev.preventDefault();
        ev.stopPropagation();
        this.markNodeTypeSearchInputFieldFocused(false);
      } else if (_.isEmpty(this.clickedNode)) {
        ev.preventDefault();
        ev.stopPropagation();

        // let nodeId = this.clickedRenderedNodeId;
        this.setClickedRenderedNodeId(null);
        this.resetCopyState();
        this.changeBranchSelectedFlag(false);
      } else {
        if (!this.showExpressionBuilder) {
          // this.handleClose();
        } else {
          this.setExpressionBuilderModalShow(false);
        }
      }
      this.retainFocus();
    },

    async handleZoom(zoom) {
      this.setZoom(zoom);
      if (this.clickedRenderedNodeId) {
        // this will force the chart to redraw
        // and in the redraw event handler, we will zoom the chart
        // according to the value set in the zoom property in canvas state
        await this.setNodesInChart(this.nodesToRender);
        this.centerCanvas(this.clickedRenderedNodeId);
      } else {
        this.chart.zoom(zoom, null, true);
      }
    },

    handleDeleteOperation(node) {
      if (
        includesAny(node.tags, [
          NODE_CATEGORIES.ONE_CHILD,
          NODE_CATEGORIES.NO_CHILD,
          NODE_CATEGORIES.MULTIPLE_CHILD
        ])
      ) {
        this.branchSelected ||
        (!this.branchSelected &&
          (node.tags.includes(NODE_TYPES.SAME_NODE.NODE_TYPE) ||
            node.tags.includes(NODE_CATEGORIES.MULTIPLE_CHILD)))
          ? deleteBranch(this.clickedRenderedNodeId)
          : deleteNode(this.clickedRenderedNodeId);
      }
    },

    handleCopyOperation(node) {
      // this.resetCopyState();

      if (
        includesAny(node.tags, [
          NODE_CATEGORIES.ONE_CHILD,
          NODE_CATEGORIES.NO_CHILD,
          NODE_CATEGORIES.MULTIPLE_CHILD
        ])
      ) {
        if (
          includesAny(node.tags, [
            NODE_CATEGORIES.MULTIPLE_CHILD,
            NODE_TYPES.SAME_NODE.NODE_TYPE
          ]) ||
          this.branchSelected
        ) {
          copyBranch(this.clickedRenderedNodeId);
        } else {
          copyNode(this.clickedRenderedNodeId);
        }
      } else {
        console.log("cannot copy");
      }
    },

    handleCutOperation(node) {
      // this.resetCopyState();
      if (
        includesAny(node.tags, [
          NODE_CATEGORIES.ONE_CHILD,
          NODE_CATEGORIES.NO_CHILD,
          NODE_CATEGORIES.MULTIPLE_CHILD
        ])
      ) {
        if (
          includesAny(node.tags, [
            NODE_CATEGORIES.MULTIPLE_CHILD,
            NODE_TYPES.SAME_NODE.NODE_TYPE
          ]) ||
          this.branchSelected
        ) {
          cutBranch(this.clickedRenderedNodeId);
        } else {
          cutNode(this.clickedRenderedNodeId);
        }
      } else {
        console.log("cannot cut");
      }
    },

    handlePasteOperation(node) {
      if (
        includesAny(node.tags, [
          NODE_CATEGORIES.ONE_CHILD,
          NODE_CATEGORIES.SYSTEM
        ]) &&
        !node.tags.includes(NODE_TYPES.SAME_NODE.NODE_TYPE)
      ) {
        pasteNode(this.clickedRenderedNodeId);
      } else {
        console.log("cannot paste here");
      }
    },

    handleShiftKeyPress(event) {
      const { code } = event;
      if (
        !this.searchFieldFocused &&
        !this.nodeTypeSearchFieldFocused &&
        _.isEmpty(this.clickedNode)
      ) {
        // let focusOn = null;
        // if (this.clickedRenderedNodeId) {
        //   let nodeElement = this.chart.getNodeElement(
        //     this.clickedRenderedNodeId
        //   );
        //   let x =
        //     (nodeElement.getBoundingClientRect().left * 100) /
        //     this.chart.width();
        //   console.log(x);
        //   x = x < 50 ? x - 15 : x + 15;
        //   console.log(x);
        //   focusOn = [
        //     x,
        //     (nodeElement.getBoundingClientRect().top * 100) /
        //       this.chart.height()
        //   ];
        // }

        let zoom;

        switch (code) {
          case "Equal":
            zoom = true;
            break;
          case "Minus":
            zoom = false;
            break;
          default:
            break;
        }

        if (zoom !== undefined) {
          this.handleZoom(zoom);
        }
      }
    },

    handleCtrlKeyRelease(event) {
      const { key } = event;
      switch (key) {
        case "Control":
          this.endPeekNode();
          break;
        default:
          break;
      }
    },
    handleCtrlKeyPressOnHoverFromSysLog(event) {
      const { key } = event;
      const { node_id } = event;
      const { component_id } = event;
      switch (key) {
        case "Control":
          if (this.isHovering) {
            this.peekNodeSysLog(node_id, component_id);
          }
          break;
        default:
          break;
      }
    },
    handleCtrlKeyPressOnHover(event, nodeId) {
      const { key } = event;
      switch (key) {
        case "Control":
          if (this.isHovering) {
            this.peekNode(nodeId);
          }
          break;
        default:
          break;
      }
    },
    handleCtrlKeyPress(event) {
      const { code } = event;
      if (
        !this.searchFieldFocused &&
        !this.nodeTypeSearchFieldFocused &&
        !this.searchCanvasFieldFocused &&
        !this.canvasNameFieldFocused &&
        _.isEmpty(this.clickedNode) &&
        this.clickedRenderedNodeId
      ) {
        event.preventDefault();
        event.stopPropagation();
        const node = this.renderedNode(this.clickedRenderedNodeId);
        if (!this.isTaskReadOnly) {
          if (
            !_.isEmpty(node) &&
            !includesAny(node.tags, [NODE_CATEGORIES.DUMMY])
          ) {
            switch (code) {
              case "KeyA":
                this.changeBranchSelectedFlag(true);
                break;
              case "KeyC":
                this.handleCopyOperation(node);
                break;
              case "KeyX":
                this.handleCutOperation(node);
                break;
              case "KeyD":
                this.handleDeleteOperation(node);
                break;
              case "KeyV":
                this.handlePasteOperation(node);
                break;
              default:
                break;
            }
          } else {
            console.log("empty/dummy node, cannot perform keyboard operations");
          }
        }
      }
    },

    handleCtrlShiftKeyPress(event) {
      const { code } = event;
      if (
        !this.searchFieldFocused &&
        !this.nodeTypeSearchFieldFocused &&
        _.isEmpty(this.clickedNode)
      ) {
        event.preventDefault();
        event.stopPropagation();
        let indexOfCanvasToShow;

        // only perform this check if its a canvas change code
        if (
          ["PageDown", "PageUp"].includes(code) &&
          this.indexOfCurrentCanvas < 0 // if it cannot find the canvas
        ) {
          this.changeSelectedCanvas(this.mainCanvas.canvas_name);
          return;
        }
        switch (code) {
          case "PageDown":
            indexOfCanvasToShow =
              this.indexOfCurrentCanvas + 1 >= this.canvases.length
                ? 0
                : this.indexOfCurrentCanvas + 1;
            break;
          case "PageUp":
            indexOfCanvasToShow =
              this.indexOfCurrentCanvas - 1 < 0
                ? this.canvases.length - 1
                : this.indexOfCurrentCanvas - 1;
            break;
          default:
            break;
        }

        if (indexOfCanvasToShow !== undefined) {
          this.changeSelectedCanvas(
            this.canvases[indexOfCanvasToShow].canvas_name
          );
        }
      }
    },

    handleClose() {
      this.$router
        .push({
          name: "callflow",
          params: { task_id: this.clickedNode.task_id }
        })
        .catch(() => {});

      this.setClickedNode(null);
      this.updateNodeNameCopy(null);
      this.resetPaletteSelection();
      // reset the linkedOrphanNode property of component
      this.linkedOrphanNode = -1;
    },

    handleCancelBtnClick() {
      if (this.selectedNodeType === "send_email") {
        EventBus.$emit("close-rich-text-editor"); // close the modal if its opened
      }

      this.toggleNodeCancelCompletedState(false);
      if (this.linkedOrphanNode !== -1) {
        this.toggleNodeCancelCompletedState(true);
        this.handleCancel();
      }
    },

    handleCancel() {
      this.retainFocus();
      this.handleClose();
    },

    /**
     * handle the dynamic component load for the first time
     */
    nodeLoading() {
      this.nodeContentLoading = true;

      setTimeout(() => {
        this.nodeContentLoading = false;
      }, 300);
    },

    handleCanvasNameChange({ oldName, newName }) {
      this.renameCanvas({ oldName, newName, ac_id: this.task.ac_id });
      EventBus.$emit(
        "log-user-activity",
        __("renamed canvas from :oldName to :newName", {
          oldName: oldName,
          newName: newName
        }),
        null
      );
    },

    handleMoveCanvasToEventHandler(updatedCanvas) {
      this.$set(updatedCanvas, "is_ni_nm_handler", 1);
      this.updateCanvas(updatedCanvas);
    },

    /**
     * handles canvas selection from the canvas popover
     */
    handleCanvasSelect(canvasName) {
      this.activeCanvasName = canvasName;
      this.changeSelectedCanvas(canvasName);
      if (!this.canvasIsPinned) {
        this.canvasDropDownVisible = false;
      }
      this.retainFocus();
    },

    checkCanvasInUse(content_model, content_id) {
      return new Promise((resolve, reject) => {
        this.setCanvasUsages([]);
        this.checkInUse({
          content_model,
          content_id
        })
          .then(data => {
            this.setCanvasUsages([...data]);
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    retainFocus() {
      if (this.searchFieldFocused) {
        this.$refs.searchbar.focus();
      } else if (this.$el !== undefined && this.$el !== null) {
        this.$el.focus();
      }
      return true;
    },

    linkOrphanNode() {
      this.toggleNodeSubmit(true);
      let node = _.cloneDeep(
        _.find(this.getValidNodes, { node_id: this.linkedOrphanNode })
      );

      this.$set(node, "connector_parent", {});
      this.$set(node.connector_parent, "data", [
        { pid: +this.clickedNode.node_id }
      ]);
      this.$set(node, "node_type", node.node_type.data.node_type);

      this.editNode({ node })
        .then(() => {
          let nodeId = this.clickedRenderedNodeId;
          this.setClickedRenderedNodeId(null);
          if (nodeId) {
            this.setNodesInChart(this.nodesToRender)
              .then(() => {
                this.centerCanvas(nodeId);
                this.setClickedRenderedNodeId(nodeId);
              })
              .catch(() => {});
          }
          this.linkedOrphanNode = -1;
        })
        .catch(() => {})
        .finally(() => {
          this.toggleNodeSubmit(false);
        });
    },

    getActiveCanvasClass(canvas) {
      let className =
        this.selectedCanvas === canvas.canvas_name ? "active-canvas" : "";
      className +=
        this.newCanvas.canvas_id === canvas.canvas_id ? "new-canvas" : "";
      return className;
    },

    /**
     * Get the path to the icon for a given node
     * @param { String } nodeType Type of the node
     * @param { Object|null } node Node configuration object
     * @returns { String } Path to icon
     */
    getIconForNodeType(nodeType, node = null) {
      if (nodeType === "data_store" && node !== null) {
        if (
          _.get(node, "data_store_node.data.data_store.data.is_log", 0) === 1
        ) {
          nodeType += "_system";
        }
      }
      return "/icons/" + nodeType + ".svg";
    },

    makeNodePaletteItemsDraggable() {
      if (!this.isTaskReadOnly) {
        let self = this;
        let items = document.querySelectorAll(".node-cell:not(.empty)");

        for (let i = 0; i < items.length; i++) {
          let item = items[i];
          item.draggable = true;

          item.addEventListener(
            "mouseover",
            ev => {
              let target = ev.target;
              let nodeType = target.getAttribute("data-node-type");
              let nodeTypeImg = document.querySelector("#ghostNode img");

              // In case the user mouseover on the SVG element instead of the containing div,
              // we'd want to go up the hierarchy to find the containing div.
              while (nodeType == null) {
                target = target.parentNode;
                nodeType = target.getAttribute("data-node-type");
              }

              nodeTypeImg.setAttribute("src", `/icons/${nodeType}.svg`);
            },
            false
          );

          item.ondragstart = async function(ev) {
            ev.dataTransfer.setData(
              "nodeType",
              ev.target.getAttribute("data-node-type")
            );
            ev.dataTransfer.setData("taskId", self.task_id);
            let nodeName = ev.target.getAttribute("data-node-name");
            let nodeType = ev.target.getAttribute("data-node-type");
            let nodeNameDiv = document.querySelector("#ghostNode .node-name");
            nodeNameDiv.innerHTML = nodeName + " Node";
            let nodeTypeImg = document.querySelector("#ghostNode img");
            nodeTypeImg.setAttribute("src", "/icons/" + nodeType + ".svg");
            let ghostNode = document.querySelector("#ghostNode");
            let nodeWidth = ghostNode.clientWidth;
            let nodeHeight = ghostNode.clientHeight;
            ev.dataTransfer.setDragImage(
              ghostNode,
              nodeWidth / 2,
              nodeHeight / 2
            );
            let selector = dropCriteria(
              ev.target.getAttribute("data-drop-rules")
            );

            let dropBoxes = await document.querySelectorAll(selector);
            _.map(dropBoxes, dropBox => {
              dropBox.style.display = "block";
              dropBox.style["z-index"] = 99999;
            });

            self.removeSelections();
          };

          item.ondragend = async function() {
            let dropBoxes = await document.querySelectorAll(".node .drop-box");
            _.map(dropBoxes, dropBox => {
              dropBox.style.display = "none";
            });
          };
        }
      }
    },

    showWebRTCDialpad(val) {
      this.showWebRTCPopup = val;
    },

    handleCanvasFocus(val) {
      this.canvasFocused = val;
    },

    filterNodeTypesHiddenUnderLd: function() {
      this.resetHiddenNodesUnderLd();

      Promise.all([
        this.showFeature(this.$getConst("PROACTIVE_MESSAGING")),
        this.showFeature(this.$getConst("AI_SUMMARY_NODE")),
        this.showFeature(this.$getConst("FRONTEND_KNOWLEDGE_NODE")),
        this.showFeature(this.$getConst("AI_PROMPT_NODE"))
      ])
        .then(
          ([
            proactiveNodeEnabled,
            summaryNodeEnabled,
            knowledgeDistillerNodeEnabled,
            knowledgePromptNodeEnabled
          ]) => {
            if (proactiveNodeEnabled) {
              this.removeNodeTypeHiddenUnderLd(
                NODE_TYPES.PROACTIVE_MESSAGE.NODE_TYPE
              );
            }
            if (summaryNodeEnabled) {
              this.removeNodeTypeHiddenUnderLd(
                NODE_TYPES.SUMMARIZATION.NODE_TYPE
              );
            }
            if (knowledgeDistillerNodeEnabled) {
              this.removeNodeTypeHiddenUnderLd(
                NODE_TYPES.KNOWLEDGE_DISTILLER.NODE_TYPE
              );
            }
            if (knowledgePromptNodeEnabled) {
              this.removeNodeTypeHiddenUnderLd(
                NODE_TYPES.AI_CUSTOM_PROMPT.NODE_TYPE
              );
            }
          }
        )
        .finally(() => {
          this.setPaletteMounted(true);
        });
    },

    handleDeleteRecord(record) {
      MessageBox.confirm(
        "This will permanently delete the file. Continue?",
        "Warning",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          this.deleteRecording(record)
            .then(() => {
              EventBus.$emit("fetch-content-items-in-folder");
              this.$message({
                type: "success",
                message: "Delete completed"
              });
            })
            .catch(err => {
              console.log("Error deleting item:", err);
              this.$message({
                type: "error",
                message: `Error :-  ${err.message}`
              });
            });
        })
        .catch(() => {
          this.$message({
            message: "Delete canceled"
          });
        });
    }
  },

  async created() {
    await this.initQueryCollectionStructure();
    await this.initialPreviewTableColumns();
    this.checkHideBackBtn();

    EventBus.$on("page-size-changed", pageSize => {
      this.pageSize = pageSize;
    });

    EventBus.$on("canvas-changed", () => {
      this.getNodes({
        ac_id: this.selectedAccountId,
        task_id: this.task_id
      });
    });

    EventBus.$on(
      "open-check-in-use-modal",
      async ({ content_id, content_model }) => {
        await this.checkCanvasInUse(content_model, content_id);
      }
    );

    EventBus.$on("open-delete-confirmation-dialog-box", async () => {
      if (this.canvasUsages.length > 0 || this.goToNodeUsages.length > 0) {
        this.showDeleteConfirmationDialog = true;
        this.searchStartedAt.canvas = this.currentCanvas.canvas_name;
        this.searchStartedAt.nodeId = this.clickedRenderedNodeId;
      }
    });

    EventBus.$on("open-pasted-reminder-dialog-box", async () => {
      if (this.pastedNodesWithReference.length > 0) {
        this.showPastedReminderDialog = true;
      }
    });

    EventBus.$on(
      "reload-task-and-overwrite",
      async (canvasName, cancelForm = false) => {
        this.activeCanvasName = canvasName;
        EventBus.$emit("close-contents-modal");
        this.changeLoadingState(true);

        this.forceFetchTask(this.task.task_id)
          .then(async task => {
            console.log("task - force fetch", task);
            this.clonedTask = _.cloneDeep(task);
            if (
              !task.is_template &&
              (!this.can(task.task_type) ||
                this.selectedAccountId !== task.ac_id)
            ) {
              this.$message.error(__("permission denied"));
              this.$router.push("/").catch(() => {});
            } else if (task.is_enabled == 0) {
              this.$message.error(__("Task is deleted"));
              this.$router.push({ name: "tasks" }).catch(() => {});
            } else {
              this.task = _.cloneDeep(task);
              this.setTask(this.task);
              this.setTaskFilter(this.task.task_type);
              await this.setTaskType(this.task.task_type);
              if (this.can("build.tasks.write")) {
                this.setReadOnlyAccess(
                  this.task &&
                    this.task.is_template &&
                    ((this.task.template.is_system &&
                      !this.$auth.check("system")) ||
                      !this.userHasSPScopeToAc ||
                      this.selectedAccountId !== this.task.ac_id ||
                      !this.can("discover.task-library.write"))
                );
              } else {
                this.setReadOnlyAccess(true);
              }
              this.accountNodeTypes({ task_type: this.task.task_type }).then(
                async () => {
                  node_template.genericNode();
                  condition_label_template.conditionLabelNode();
                  start_node_template.startNode();

                  if (
                    _.has(
                      this.nodeTypeOptionsAvailableForTask,
                      "TRIGGER_CAMPAIGN"
                    )
                  ) {
                    Promise.all([
                      this.getOnDemandCampaigns(),
                      this.getPhoneNumberToTaskMap({
                        fetch_all: 1,
                        for_campaign_cli: 1,
                        ac_id: this.selectedAccountId
                      }),
                      this.getLanguages(),
                      _.map(NODE_LOG_PROFILE, profile_type => {
                        this.getNodeLoggingProfiles({
                          fetch_all: 1,
                          profile_type,
                          fetch_minimum_details: 1
                        });
                      }),
                      this.fetchVariables(),
                      this.getPrompts({ fetch_all: 1 }),
                      this.fetchJavascriptFunctions(),
                      this.getNodes({
                        ac_id: this.selectedAccountId,
                        task_id: this.task_id
                      })
                    ])
                      .then(results => {
                        this.onDemandCampaigns = results[0];
                        this.phoneNumberToTaskMap = results[1].data;

                        if (cancelForm) {
                          this.handleCancel();
                        }
                        if (this.clickedNode && this.clickedNode.node_id) {
                          this.reloadTaskAndOverwrite({
                            canvas: canvasName,
                            nodeId: this.clickedNode.node_id
                          });
                        } else {
                          this.reloadTaskAndOverwrite({
                            canvas: canvasName,
                            nodeId: null
                          });
                        }
                      })
                      .catch(err => {
                        console.log(err);
                        this.handleFetchTaskDependenciesFailure(err);
                      });
                  }
                }
              );
            }
          })
          .catch(() => {
            this.$message.error(__("could not get updated task details"));
          })
          .finally(() => {
            this.changeLoadingState(false);
          });
      }
    );

    EventBus.$on("direct-to-node", nodeId => {
      this.navigateToNode(nodeId);
    });
  },

  beforeRouteEnter(to, from, next) {
    if (to.params.nav_to_node_id && !to.query.ac_id) {
      getTaskForNode({ node_id: to.params.nav_to_node_id }).then(
        async ({ data }) => {
          let { ac_id, sp_id } = data;

          const retVal = usersUtils.setServiceProviderIdAndAccountIdForUser(
            sp_id,
            store.state.app.selectedServiceProviderId,
            store.state.app.userAccountSwitcherOptions,
            ac_id,
            store.state.app.selectedAccountId
          );

          await store.dispatch(
            "app/changeUserSelectedServiceProviderId",
            retVal.serviceProviderId
          );
          await store.dispatch(
            "app/changeUserSelectedAccountId",
            retVal.accountId
          );
          next();
        }
      );
    } else {
      next();
    }
  },

  beforeDestroy() {
    EventBus.$off("canvas-changed");
    EventBus.$off("reload-task-and-overwrite");
    EventBus.$off("open-check-in-use-modal");
    EventBus.$off("open-delete-confirmation-dialog-box");
    EventBus.$off("open-pasted-reminder-dialog");
    EventBus.$off("direct-to-node");
    this.removeCanvases();
    this.removeNodes();
    this.setPaletteMounted(false);
    this.chart && this.chart.destroy();
  },

  watch: {
    open_node_id: {
      handler(val) {
        if (this.chart && val && val !== "new") {
          this.navigateToNode(val);
          this.openNode(this.chart, val);
        }
      }
    },
    task_id: {
      immediate: true,
      handler: function(val, oldVal) {
        if (+val !== +oldVal) {
          this.initializeCallFlowEditor();
        }
      }
    },

    // tasks: {
    //   deep: true,
    //   handler() {
    //     this.task = this.getTask(this.task_id);
    //   }
    // },
    isNodeCancelCompleted: {
      handler(val) {
        // val changed to true from false
        if (val) {
          this.handleCancel();
        }
      }
    },
    eb: {
      deep: true,
      handler() {
        this.buildRequestData();
      }
    },

    showModal: {
      handler(val) {
        if (!val) {
          this.searchStartedAt = {};
          this.retainFocus();
        }
      }
    },

    clickedNode(val) {
      /**
       *  the modal popup to configure a node depends on
       *  proper setting of selectedNodeType. So we watch
       *  for the clicked node changes and set the selected
       *  node type accordingly
       */
      this.selectedNodeType = !_.isEmpty(val)
        ? // if user double clicked on a node, edit mode is on
          this.isEdit
          ? // if in edit mode, selected node type would be node type of the clicked node itself
            val.node_type.data.node_type
          : // if a node type is dropped from palette, then its in created mode
          this.paletteSelection
          ? // if palette selection is selected, selected node type would be palette selection itself
            this.paletteSelection
          : // or if anything else, we default to variable node type
            NODE_TYPES.VARIABLE.NODE_TYPE
        : // if the clicked node is empty, set selected node type to null
          null;
    },

    showExpressionBuilder: {
      // when expression builder modal closes retain the focus on main cfe container
      handler: function(val) {
        if (!val) {
          this.retainFocus();
        }
      }
    },

    showNotification: {
      handler: function() {
        if (this.showNotification) {
          this.$notify(this.notificationContent);
          this.setShowNotificationStatus(false);
        }
      }
    },

    showVariableNotification: {
      handler: function() {
        if (this.showVariableNotification) {
          this.$notify(this.notificationVariableContent);
          this.setShowVariableNotificationStatus(false);
        }
      }
    },

    showDeleteConfirmBox: {
      handler: function() {
        if (this.showDeleteConfirmBox) {
          let isMultiNodes =
            (this.branchSelected &&
              this.deleteConfiguration.deleteType === "delete_branch") ||
            this.renderedNode(this.clickedRenderedNodeId).tags.includes(
              NODE_CATEGORIES.MULTIPLE_CHILD
            );
          let deleteTypeMsg =
            this.deleteConfiguration.deleteType === "delete_branch"
              ? "branch"
              : "node";
          if (isMultiNodes && this.deleteConfiguration.deleteType) {
            if (this.deleteConfiguration.deleteType === "delete_branch") {
              deleteTypeMsg += "es";
            } else {
              deleteTypeMsg += "s";
            }
          }
          deleteTypeMsg = __(deleteTypeMsg);
          deleteTypeMsg += ".";
          // eslint-disable-next-line
          let message = __("This will permanently delete the :deleteTypeMsg Continue?", {
              deleteTypeMsg: deleteTypeMsg
            }
          );
          // pass the current canvas id to the backend as well
          this.deleteConfiguration.canvasId = this.currentCanvas.canvas_id;
          this.$confirm(message, __("Warning"), {
            confirmButtonText: __("OK"),
            cancelButtonText: __("Cancel"),
            type: "warning"
          })
            .then(() => {
              this.setShowDeleteConfirmationBox(false);
              this.deleteNodeAction(this.deleteConfiguration);
            })
            .catch(() => {
              this.$notify({
                title: __("Delete cancelled"),
                message: __("User aborted"),
                type: "info"
              });
              this.setShowDeleteConfirmationBox(false);
            });
        }
      }
    },

    nodes: {
      deep: true,
      handler: function(val) {
        if (!_.isEmpty(val)) {
          this.makeNodePaletteItemsDraggable();
          this.returnValidNodes();
        }
      }
    },

    paletteMounted: {
      handler: function(val) {
        if (val) {
          this.makeNodePaletteItemsDraggable();
        }
      }
    },

    nodeClickFlag: "retainFocus",

    searchItem: {
      handler() {
        if (this.debouncedValidNodeSearchFn) {
          this.debouncedValidNodeSearchFn.cancel();
        }
        this.debouncedValidNodeSearchFn = _.debounce(
          this.returnValidNodes,
          500
        );
        this.debouncedValidNodeSearchFn();
      }
    },

    showDeleteConfirmationDialog(val) {
      if (!val) {
        this.setCanvasUsages([]);
        this.setGoToNodeUsages([]);
        this.searchStartedAt = {};
        this.retainFocus();
      }
    },

    showPastedReminderDialog(val) {
      if (!val) {
        this.setPastedNodesWithReference([]);
        this.searchStartedAt = {};
        this.retainFocus();
      }
    },

    validSearchNodes: {
      handler: function(val) {
        this.setSearchResults({ query: this.searchItem, nodes: val });
        this.removeHighlightOnAllSelectedNodesAction({
          classSelector: "found"
        });

        if (this.searchItem) {
          _.map(val, node => {
            this.addHighlightOnSelectedNodeAction({
              nodeId: node.node_id,
              classSelector: "found"
            });
          });
        } else {
          this.addHighlightOnSelectedNodeAction({
            nodeId: this.clickedRenderedNodeId
          });
        }
      }
    },

    userRole: {
      immediate: true,
      handler(val) {
        if (val === "system") {
          if (
            !_.find(this.selectedDsCollectionStructure, {
              name: "pop_region_id"
            })
          ) {
            this.selectedDsCollectionStructure.push({
              name: "pop_region_id",
              label: __("POP REGION ID"),
              type: defaultFieldTypes.NUMBER,
              choices: null
            });
          }
        } else {
          this.selectedDsCollectionStructure = _.filter(
            this.selectedDsCollectionStructure,
            column => column.name !== "pop_region_id"
          );
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$content-theme-disabled-color: var(--theme-color-light-10p) !default;
@import "~@/styles/node-palette.scss";
@import "~@/styles/callflow-editor.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/button.scss";

.canvas-node-link {
  color: var(--theme-color);
  cursor: pointer;
  text-decoration: underline;
  padding: 5px 0;
  margin: 15px 0;
}

.backBtn {
  border: none;
  font-size: 14px;
  font-weight: bold;
  background-color: $call-flow-canvas-background;
  padding: 0 20px;

  ::v-deep i {
    font-size: 14px;
    font-weight: bolder;
  }

  &:hover {
    background-color: $call-flow-canvas-background;
  }
}

.modalContainer {
  .canvas {
    background-color: $call-flow-canvas-background;
  }

  ::v-deep #orgchart > svg {
    background-color: $call-flow-canvas-background !important;
  }
}

.modalContainer.dark {
  .canvas {
    background-color: $dark-call-flow-canvas-background;
  }

  ::v-deep #orgchart {
    > svg {
      background-color: $dark-call-flow-canvas-background !important;
    }

    > div:not(.el-loading-mask):not(.bg-toolbar-container):nth-last-of-type(1) {
      box-shadow: rgb(60, 60, 60) 0 0 4px 2px !important;
      background: #2c3e50 !important;

      div {
        background: #2c3e50 !important;
        color: lightgrey !important;

        &:hover {
          background: #6e7681 !important;
        }
      }
    }
  }

  ::v-deep .nodeRect {
    fill: #29313c;
  }

  ::v-deep .nodeName {
    fill: lightgrey;
  }

  .callFlowTitleBar {
    color: lightgrey;
    background-color: $dark-call-flow-canvas-background;
  }

  ::v-deep .sidebar-settings {
    ::v-deep .el-menu {
      background: $dark-node-palette-background;

      .user-info {
        color: lightgrey;
      }
    }
  }

  ::v-deep .node-modal {
    background: $dark-node-palette-background;
  }

  ::v-deep input {
    border-color: $dark-input-border-color;
    color: $dark-text-color;
    background: $dark-node-palette-background;
  }

  ::v-deep textarea {
    border-color: $dark-input-border-color;
    color: $dark-text-color;
    background: $dark-node-palette-background;
  }

  ::v-deep .el-table {
    color: $dark-text-color;
    background: $dark-node-palette-background;

    tr {
      color: $dark-text-color;
      background: $dark-node-palette-background;
    }

    th {
      color: $dark-text-color;
      background: $dark-node-palette-background;
    }
  }

  ::v-deep .el-tabs__item {
    color: $dark-text-color;
  }

  ::v-deep label {
    color: $dark-text-color;
  }
}

/*#orgchart ::v-deep [node-id]:not(.condition_label) rect:hover {*/
/*  filter: url(#dropshadowdark);*/
/*}*/

#orgchart ::v-deep .node.protected {
  .ellipses {
    visibility: hidden;
  }
}

#orgchart ::v-deep .node.cut {
  .nodeRect {
    opacity: 0.3;
  }

  .nodeName {
    opacity: 0.3;
  }

  .nodeType {
    opacity: 0.3;
  }

  .nodeImage {
    opacity: 0.3;
  }

  .menuButton {
    opacity: 0.3;
  }
}

#orgchart ::v-deep .node {
  &:hover {
    .copyImage {
      display: block;
    }
  }
}

#orgchart ::v-deep .node:not(.start_node) text:first-of-type {
  fill: $--color-node-default;
}

#orgchart ::v-deep .node.focused:not(.start_node):not(.dummy) {
  .nodeRect {
    stroke: $--color-node-default;
    stroke-width: 2px;
  }
}

#orgchart ::v-deep .node.copy:not(.dummy) {
  .nodeRect {
    fill: #e5efff;
  }
}

#orgchart ::v-deep .node.found {
  .nodeRect {
    fill: #ffe193;
  }
}

#orgchart ::v-deep .node:not(.start_node) > [control-node-menu-id] circle {
  fill: $--color-node-default;
}

.modalContainer ::v-deep [class*="node-modal-"] {
  padding: 0;
}

.modalContainer {
  ::v-deep .submitBtn {
    color: $--color-white !important;
    background: $content-theme-color;
    border-color: $content-theme-color;

    &:hover {
      color: $--color-white;
      background-color: $content-theme-hover-color !important;
      border-color: $content-theme-hover-color !important;
    }

    &.submitBtn.is-disabled {
      color: $--color-white;
      background-color: $content-theme-disabled-color !important;
      border-color: $content-theme-disabled-color !important;
    }
  }
}

.modalContainer ::v-deep .node-submit-integration {
  background-color: var(--theme-color);
  border-color: var(--theme-color) !important;

  &:hover {
    color: $--color-white;
    background-color: var(--theme-hover-color) !important;
    border-color: var(--theme-hover-color) !important;
  }

  &.submitBtn.is-disabled {
    color: $--color-white;
    background-color: var(--theme-color-light-10p) !important;
    border-color: var(--theme-color-light-10p) !important;
  }
}

.modalContainer ::v-deep .node-submit-variable {
  background-color: $content-theme-color;
  border-color: $content-theme-color;

  &:hover {
    background-color: $content-theme-hover-color;
    border-color: $content-theme-hover-color;
  }

  &.submitBtn.is-disabled {
    color: $--color-white;
    background-color: $content-theme-disabled-color !important;
    border-color: $content-theme-disabled-color !important;
  }
}

.modalContainer ::v-deep .node-submit-web_service {
  background-color: $content-theme-color;
  border-color: $content-theme-color;

  &:hover {
    background-color: $content-theme-hover-color;
    border-color: $content-theme-hover-color;
  }

  &.submitBtn.is-disabled {
    color: $--color-white;
    background-color: $content-theme-disabled-color !important;
    border-color: $content-theme-disabled-color !important;
  }
}

.modalContainer ::v-deep .node-submit-messaging_send {
  background-color: $content-theme-color;
  border-color: $content-theme-color;

  &:hover {
    background-color: $content-theme-hover-color;
    border-color: $content-theme-hover-color;
  }

  &.submitBtn.is-disabled {
    color: $--color-white;
    background-color: $content-theme-disabled-color !important;
    border-color: $content-theme-disabled-color !important;
  }
}

.modalContainer ::v-deep .node-submit-messaging_reply {
  background-color: $content-theme-color;
  border-color: $content-theme-color;

  &:hover {
    background-color: $content-theme-hover-color;
    border-color: $content-theme-hover-color;
  }

  &.submitBtn.is-disabled {
    color: $--color-white;
    background-color: $content-theme-disabled-color !important;
    border-color: $content-theme-disabled-color !important;
  }
}

.modalContainer ::v-deep .node-submit-messaging_conversation {
  background-color: $content-theme-color;
  border-color: $content-theme-color;

  &:hover {
    background-color: $content-theme-hover-color;
    border-color: $content-theme-hover-color;
  }

  &.submitBtn.is-disabled {
    color: $--color-white;
    background-color: $content-theme-disabled-color !important;
    border-color: $content-theme-disabled-color !important;
  }
}

.modalContainer ::v-deep .node-submit-set_voice {
  background-color: $content-theme-color;
  border-color: $content-theme-color;

  &:hover {
    background-color: $content-theme-hover-color;
    border-color: $content-theme-hover-color;
  }

  &.submitBtn.is-disabled {
    color: $--color-white;
    background-color: $content-theme-disabled-color !important;
    border-color: $content-theme-disabled-color !important;
  }
}

.modalContainer ::v-deep .node-submit-keyword_finder {
  background-color: $content-theme-color;
  border-color: $content-theme-color;

  &:hover {
    background-color: $content-theme-hover-color;
    border-color: $content-theme-hover-color;
  }

  &.submitBtn.is-disabled {
    color: $--color-white;
    background-color: $content-theme-disabled-color !important;
    border-color: $content-theme-disabled-color !important;
  }
}

.modalContainer ::v-deep .node-submit-decision {
  background-color: $content-theme-color;
  border-color: $content-theme-color;

  &:hover {
    background-color: $content-theme-hover-color;
    border-color: $content-theme-hover-color;
  }

  &.submitBtn.is-disabled {
    color: $--color-white;
    background-color: $content-theme-disabled-color !important;
    border-color: $content-theme-disabled-color !important;
  }
}

.modalContainer {
  display: grid;
  grid-template-columns: 280px 1fr;

  &.protected {
    grid-template-columns: 1fr;
  }

  height: 100vh;
  width: 100%;
  max-height: 100vh;

  #canvas {
    width: 100%;
    height: 100vh;
    cursor: grab;
    display: grid;
    grid-template-rows: auto 1fr;

    .callFlowTitleBar {
      padding-top: 5px;
      background-color: $call-flow-canvas-background;
      z-index: 2;
      width: 100%;
      display: grid;

      grid-template-columns: minmax(75px, 1fr) minmax(350px, auto) minmax(
          450px,
          1fr
        );
      gap: 10px;

      .taskNameTitle {
        font-weight: bold;
        margin-left: 15px;
        display: flex;
        align-items: center;

        span {
          margin-left: 5px;
          font-size: 0.9em;
          color: dimgrey;
        }
      }

      .canvasNameTitle {
        font-weight: bold;
        justify-self: center;
        align-self: center;
        display: flex;
        overflow-wrap: anywhere;
      }

      .cfe-controls {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .canvasSearchInput {
          min-width: 200px;
          max-width: 300px;
        }

        .cfe-minor-controls-wrapper {
          display: flex;
          align-items: center;
          margin-right: 6px;
          //flex: 0 1 100%;

          .cfe-minor-controls {
            display: flex;
            outline: none;
            justify-content: space-evenly;
            padding-inline: 6px;
            align-self: stretch;
            align-items: center;

            * {
              cursor: pointer;
              outline: none;
            }
          }
        }

        .canvasSearchInput {
          display: flex;
          align-content: center;
          align-items: center;
          padding-right: 6px;
          flex: 1;

          ::v-deep .el-select {
            width: 100%;
          }

          .node-search {
            ::v-deep .el-input__prefix {
              display: flex;

              .node-search-icon {
                mask-size: cover;
                height: 24px;
                width: 24px;
                background-color: #454545;
                mask: url("~@/assets/icons/icon-search.svg") no-repeat center;
              }
            }
          }

          .search-input {
            /*left: 30px;*/
            /*width: 0px;*/
            /*padding: 0 20px;*/
            transition: all 0.8s ease;

            /*.active {*/
            /*  width: 100%;*/
            /*}*/
          }

          .search-btn {
            cursor: pointer;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            background-color: #eee;
            border-radius: 999999px;
          }
        }
      }

      @media only screen and (max-width: 1400px) {
        & {
          grid-template-columns: minmax(75px, 1fr) minmax(200px, auto) minmax(
              200px,
              1fr
            );

          padding-top: 20px;

          .cfe-controls {
            grid-column-end: 4;
            grid-column-start: 2;
            grid-row-start: 2;
            grid-row-end: 3;
            align-self: end;
          }

          .canvasNameTitle {
            grid-column-start: 1;
            grid-column-end: 4;
            grid-row-start: 1;
            grid-row-end: 2;
          }
          .taskNameTitle {
            grid-column-start: 1;
            grid-row-start: 2;
            grid-row-end: 3;
          }
        }
      }

      @media only screen and (max-width: 880px) {
        & {
          .taskNameTitle {
            .assignNumbersAction {
              .assignedNumbers {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.modalContainer ::v-deep .el-dialog__body {
  padding: 20px 0 10px 0 !important;
}

.modalContainer ::v-deep .el-dialog__footer {
  padding: 20px 0 10px 0 !important;
}

.node-configure-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dialog-contents {
  width: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;

  .submit-btns {
    margin-left: 20px;
  }
}

.node-type-options {
  margin-left: 10px;
  flex-grow: 0;

  .el-form-item {
    margin-bottom: 0;
  }
}

.ghostNode {
  max-height: 40px;
  max-width: 221px;
  height: 40px;
  width: 221px;
  box-shadow: 0 0 18px -4px rgba(0, 0, 0, 0.47);
  border-radius: 6px;
  margin: 5px;
  display: flex;
  justify-content: space-around;
  flex-shrink: 0;
  align-items: center;
  background-color: white;

  .node-name {
    font-size: 0.85rem;
    font-weight: bold;
    letter-spacing: 0.03rem;
  }
}

#ghostNode {
  height: 52px;
  width: 233px;
  padding: 7px;
  position: fixed;
  z-index: -99999;
}

#orgchart
  ::v-deep
  > div:not(.el-loading-mask):not(.bg-toolbar-container):nth-last-of-type(1) {
  margin-left: 15%;
  margin-top: 2.5%;
  border-radius: 6px !important;
  font-size: 0.825rem;
  width: 215px;
  cursor: pointer;

  &:hover {
    border-radius: 6px !important;
  }

  div {
    border-radius: 6px;
    border-bottom: none !important;

    &:hover {
      border-radius: 6px;
    }

    > span {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-weight: bold;
        font-size: 0.75rem;
      }
    }
  }
}

::v-deep .el-divider {
  margin: 10px 0 !important;
  background-color: lightgrey;
}

.canvas-item {
  &:hover {
    color: #00afaf;
  }
}

.canvas-dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.canvas-dropdown-row-top {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pin-canvas-icon {
  height: 13px;
  cursor: pointer;
  fill: #4545458a;

  &:hover {
    fill: $content-theme-hover-color;
  }

  &.pinned {
    fill: $content-theme-hover-color;
  }
}

#pinned-canvas-list {
  cursor: move;

  .canvas-tab {
    font-weight: normal;
  }
}

.startNodeSearchIcon {
  ::v-deep path {
    fill: #454545;
  }
}

.subHeading {
  padding: 10px 0;
  font-weight: bold;
}

.colorIcon {
  fill: var(--theme-color) !important;

  ::v-deep path {
    fill: var(--theme-color) !important;
  }
}
</style>

<style lang="scss">
.call-flow-editor-notification {
  .el-notification__content {
    text-align: start;
    word-break: break-word;
  }
}

.call-flow-editor-notification-left {
  margin-left: 280px;
}

.my-autocomplete {
  max-width: 250px;
  .el-scrollbar {
    width: inherit;
  }

  .el-select-dropdown__list {
    width: 100%;
  }

  li {
    line-height: normal;
    padding: 7px;

    .value {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.editContentItemModal {
  padding: 0;
  border-radius: 0;

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0 !important;
    /*overflow: hidden;*/
  }

  .el-dialog__footer {
    padding: 0;
  }
}

.cfe-control-tooltip {
  line-height: 1;
  padding: 4px 6px;
}

.assignNumbersAction {
  cursor: pointer;

  .assignedNumbers {
    cursor: pointer;

    &:hover {
      color: var(--theme-hover-color) !important;
    }
  }
}

.canvas-list {
  padding: 10px;
}

.logDialog {
  opacity: 1;

  .el-dialog__body {
    padding: 20px 20px;
    color: #181f29;
    font-size: 14px;
    word-break: break-all;
  }
}

.pasted-reminder-dialog {
  top: 10%;
  left: 25%;
  position: fixed;
  display: inline-flex;
  width: 80%;
  height: fit-content;

  .el-dialog {
    position: fixed;
    margin-top: 0 !important;
    margin-left: 20px !important;
    margin-bottom: 0 !important;
    width: 40% !important;
  }

  .el-dialog__body {
    position: relative;
    height: fit-content;
    margin-left: 15px;
    margin-right: 0;
    margin-bottom: 0;
  }
}

.dialog-box-body {
  margin-top: 10px;

  .items {
    outline: none;
    margin: 10px 0 0 10px;
  }
}

.fadeToPeek {
  opacity: 0.1;
}

.chatbot-preview {
  position: fixed;
  bottom: 80px;
  right: 36px;
  width: 48px;
  height: 48px;
  justify-content: center;
}
</style>
