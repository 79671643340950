<template>
  <div style="height: 75vh; max-height: 75vh">
    <vxe-toolbar custom>
      <template v-slot:buttons>
        <el-row style="align-items: center" type="flex">
          <el-col v-if="searchVisible" :sm="8" :xs="8" :md="8" :lg="8" :xl="8">
            <vxe-input
              v-model="filterName"
              type="search"
              :placeholder="__('Search Datastore')"
            ></vxe-input>
          </el-col>

          <el-col
            :xl="15"
            class="pagination-toolbar-preview-table"
            style="display: flex;justify-content: flex-end; flex: 1; align-self: flex-end; align-items: center"
          >
            <slot name="paginationToolbar" v-if="showPagination"> </slot>

            <el-row
              v-if="editedItems.length"
              class="info"
              style="display: inline-flex; "
              align="bottom"
            >
              <el-col
                :span="24"
                style="display: inline-flex; align-items: center;"
              >
                <div class="warning-exclamation"></div>
                <div
                  style="margin-bottom: 0; width: 240px; display: flex; font-size: 15px;"
                >
                  <div>
                    {{ editWarningMessage }}
                  </div>
                </div>
              </el-col>
            </el-row>

            <el-tooltip
              class="item"
              effect="dark"
              :content="editActionMessage"
              placement="bottom"
            >
              <el-button
                v-if="allowEdit"
                type="success"
                v-show="updatedData"
                class="advancedBtn"
                style="border-width: 1px 2px; border-color: #fcb500;"
                @click="saveChangesEvent()"
              >
                <img
                  class="bulkImg"
                  :src="getSaveChangesIcon"
                  onload="SVGInject(this)"
                />
              </el-button>
            </el-tooltip>

            <el-tooltip
              class="item"
              effect="dark"
              :content="deleteActionMessage"
              placement="bottom"
            >
              <el-button
                v-if="allowEdit"
                type="danger"
                v-show="checkBoxSelected"
                class="advancedBtn"
                @click="deleteDataEvent()"
              >
                <img
                  class="bulkImg"
                  :src="getDeleteIcon"
                  onload="SVGInject(this)"
                />
              </el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              :content="__('Insert Data')"
              placement="bottom"
            >
              <el-button
                v-if="allowEdit"
                class="advancedBtn"
                @click="insertEvent()"
              >
                <img
                  class="bulkImg"
                  :src="getInsertIcon"
                  onload="SVGInject(this)"
                />
              </el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              :content="__('Export Data')"
              placement="top"
            >
              <el-button
                v-if="allowExport"
                class="advancedBtn"
                @click="exportCSV()"
                :loading="executingButton"
              >
                <img
                  class="bulkImg"
                  :src="getExportIcon"
                  onload="SVGInject(this)"
                  v-if="!executingButton"
                />
              </el-button>
            </el-tooltip>
            <div v-if="showAdvancedDataExport">
              <el-tooltip
                class="item"
                effect="dark"
                content="Configure Data Export"
                placement="top"
              >
                <el-button
                  v-if="allowConfigureExport"
                  class="advancedBtn"
                  @click="showConfigureExportData()"
                  :loading="executingButton"
                >
                  <img
                    class="bulkImg"
                    :src="getConfigureExportIcon"
                    onload="SVGInject(this)"
                    v-if="!executingButton"
                  />
                </el-button>
              </el-tooltip>
            </div>

            <el-tooltip
              class="item"
              effect="dark"
              :content="__('Refresh')"
              placement="top"
            >
              <el-button
                class="advancedBtn"
                v-if="showRefreshIcon"
                @click="refreshEvent()"
              >
                <img
                  class="bulkImg"
                  :src="getRefreshIcon"
                  onload="SVGInject(this)"
                />
              </el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              :content="__('Filter')"
              placement="top"
            >
              <el-button
                v-if="showQueryConditionBuilderIcon"
                class="advancedBtn"
                @click="showQueryCondition"
              >
                <img
                  class="bulkImg"
                  :src="getFilterIcon"
                  onload="SVGInject(this)"
                />
              </el-button>
            </el-tooltip>
          </el-col>
        </el-row>
      </template>
    </vxe-toolbar>
    <div style="height: 59vh; max-height: 60vh; padding-top: 30px">
      <vxe-table
        ref="xTable"
        border="inner"
        class="vxe_table_data"
        style="width: 100%;"
        :align="allAlign"
        :data="list"
        :size="tableSize"
        :edit-config="
          allowEdit
            ? {
                trigger: 'dblclick',
                mode: 'cell',
                activeMethod: activeCellMethod
              }
            : null
        "
        :mouse-config="{ selected: true }"
        @checkbox-all="selectCheckBoxEvent"
        @checkbox-change="selectCheckBoxEvent"
        @edit-closed="editClosedEvent"
        @sort-change="handleSortChangeEvent"
        :sort-config="{ sortMethod: emptySortMethod }"
        v-loading="loadingTable"
        highlight-current-row
        highlight-current-column
        highlight-hover-row
        row-key
        resizable
        auto-resize
        height="auto"
        :row-style="getRowStyle"
        :expand-config="{ visibleMethod: expandVisibleMethod }"
      >
        <vxe-table-column
          v-if="allowEdit"
          type="checkbox"
          width="60"
        ></vxe-table-column>
        <!-- # Seq columns hiding for now -->
        <!-- <vxe-table-column
          type="seq"
          width="60"
          v-if="!hideSeq"
        ></vxe-table-column> -->
        <vxe-table-column
          v-for="column in filteredTableColumns"
          :key="column.label"
          :min-width="150"
          :field="column.name"
          :title="column.label"
          :visible="column.visible"
          :edit-render="column.type !== 'audio' ? fieldType(column.type) : null"
          :resizable="true"
          :sortable="sortable"
          :type="isSystemLog && column.name === '_uuid' ? 'expand' : ''"
          :title-help="getTitleHelp(column)"
        >
          <template
            v-slot="scope"
            v-if="column.type === 'audio' && useAudioFileUploaderInList"
          >
            <div class="audio__row">
              <file-uploader-minimal
                :show-file-name="false"
                :folder="`uploads/datastore_data/`"
                :file-url="tableAudioFilePath(scope.row, column)"
                :file-name="tableAudioFileName(scope.row, column)"
                :allow-types="['mp3', 'wav']"
                @on-success="
                  handleTableAudioUploadSuccess($event)(scope.row, column)
                "
                @on-delete="handleTableAudioRemove($event)(scope.row, column)"
              />
            </div>
          </template>
          <template v-slot="scope" v-else-if="column.type === 'audio'">
            <span>
              <audio-player
                :file="scope.row[generatedUrlPrefix + column.name]"
                :simple-player="true"
                class="simple-player"
                style="display: inline-block;width: 24px;"
                showClose="true"
              ></audio-player
            ></span>
          </template>
          <template v-slot="scope" v-else-if="column.type === 'timestamp'">
            <span>{{ convertTSToDateTime(scope.row, column) }}</span>
          </template>
          <template v-slot="scope" v-else-if="column.type === 'boolean'">
            <span>{{ scope.row[column.name] }}</span>
          </template>
          <template v-slot="scope" v-else-if="column.type === 'date'">
            <span :class="getClass(scope.row, column)">{{
              formatDate(scope.row, column)
            }}</span>
          </template>
          <template v-slot="scope" v-else-if="column.type === 'datetime'">
            <span :class="getClass(scope.row, column)">{{
              formatDateTime(scope.row, column)
            }}</span>
          </template>
          <template
            v-slot="scope"
            v-else-if="isSystemLog && column.name === '_uuid'"
          >
            <span
              :class="getClass(scope.row, column)"
              style="text-decoration: underline; cursor: pointer"
              @click="
                $emit('filter', { colName: '_uuid', value: scope.row._uuid })
              "
              >{{ scope.row._uuid }}</span
            >
          </template>
          <template
            v-slot="scope"
            v-else-if="isSystemLog && column.name === 'event'"
          >
            <span
              :class="getClass(scope.row, column)"
              style="text-decoration: underline; cursor: pointer"
              @click="
                $emit('filter', { colName: 'event', value: scope.row.event })
              "
              >{{ scope.row.event }}</span
            >
          </template>
          <template
            v-slot="scope"
            v-else-if="
              isSystemLog &&
                ['param1', 'param2', 'param3'].includes(column.name)
            "
          >
            <system-log-node-navigation
              :class="getClass(scope.row, column)"
              :parameter="formatValue(scope.row, column)"
              :node-id-to-navigate="
                getNodeIdToNavigate(column.name, scope.row.details)
              "
              :id="getNodeIdToNavigate(column.name, scope.row.details)"
              :task-id-to-navigate="getTaskIdToNavigate(scope.row.details)"
              :sub-task-id-to-navigate="
                getSubTaskIdToNavigate(scope.row.details)
              "
              :is-sub-task-log="
                !!(scope.row.details && scope.row.details.execution_type)
              "
              :show-all-interaction-logs="showAllInteractionLogs"
              @navigate-to-node="navigateToNode"
              @handle-node-link-hover="handleNodeLinkHover"
              @handle-ctrl-key-press-on-hover="handleKeyPressOnHover"
              @peek-node="peekNode"
              @end-peek-node="endPeekNode"
              @handle-ctrl-key-release="handleCtrlKeyRelease"
              @navigate-to-task="navigateToTask"
              :disable-navigation="disableNavigation"
            ></system-log-node-navigation>
          </template>
          <template v-slot="scope" v-else-if="column.type === 'double'">
            <span :class="getClass(scope.row, column)">{{
              formatValue(scope.row, column)
            }}</span>
          </template>
          <template v-slot="scope" v-else-if="column.type === 'text'">
            <span :class="getClass(scope.row, column)">{{
              formatValue(scope.row, column)
            }}</span>
          </template>
          <template v-slot="scope" v-else-if="column.type === 'number'">
            <span :class="getClass(scope.row, column)">{{
              formatValue(scope.row, column)
            }}</span>
          </template>
          <template v-slot:content="{ row }">
            <template>
              <ul>
                <li
                  v-for="(detail_value, detail_key) in row.details"
                  v-bind:key="`${detail_value}_${detail_key}`"
                >
                  <span v-if="detail_key.includes('no_match_audio')">
                    <span>{{ detail_key }}: </span>
                    <audio-player
                      class="audio-player"
                      :simplePlayer="true"
                      :show-seek-bar="true"
                      icon-play="/icons/play1.svg"
                      icon-download="/icons/download.svg"
                      icon-pause="/icons/pause.svg"
                      :file="detail_value"
                    />
                  </span>
                  <span v-else>{{ detail_key }}：{{ detail_value }}</span>
                </li>
              </ul>
            </template>
          </template>
        </vxe-table-column>
        <template v-slot:empty>
          <p>{{ __("No data") }}</p>
        </template>
      </vxe-table>
    </div>
    <vxe-modal
      v-model="showInsertForm"
      :title="__('Insert Data')"
      width="800"
      min-width="600"
      min-height="600"
      :loading="submitLoading"
      resize
      destroy-on-close
    >
      <vxe-form
        :data="formData"
        :rules="formRules"
        title-align="right"
        title-width="100"
        @submit="submitEvent"
      >
        <vxe-form-item
          :ref="`datastore-col-${item.field}`"
          v-for="(item, index) in formItems"
          v-bind:key="index"
          :title="item.title"
          :field="item.field"
          :span="item.span"
          :item-render="
            item.type !== 'audio' && item.type !== 'boolean'
              ? item.itemRender
              : null
          "
        >
          <template v-if="item.type === 'audio'">
            <file-uploader-minimal
              :folder="`uploads/datastore_data/`"
              :file-url="formAudioFilePath(item)"
              :file-name="formAudioFileName(item)"
              :allow-types="['mp3', 'wav']"
              @on-success="handleFormAudioUploadSuccess($event)(item)"
              @on-delete="handleFormAudioRemove($event)(item)"
            />
          </template>
          <template v-else-if="item.type === 'boolean'">
            <vxe-select
              v-model="formData[item.field]"
              :type="item.type"
              :options="boolOptions"
              :placeholder="item.itemRender.props.placeholder"
            ></vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item
          style="margin-top: 25px"
          align="right"
          span="24"
          :item-render="{}"
        >
          <template v-slot>
            <vxe-button type="submit" status="primary">{{
              __("Submit")
            }}</vxe-button>
            <vxe-button type="reset">{{ __("Reset") }}</vxe-button>
          </template>
        </vxe-form-item>
      </vxe-form>
    </vxe-modal>
  </div>
</template>

<script>
import _ from "lodash";
import FileUploaderMinimal from "@/components/uploaders/types/FileUploaderMinimal";
import AudioPlayer from "@/components/AudioPlayer";
import path from "path";
import SystemLogNodeNavigation from "@/components/SystemLog/SystemLogNodeNavigation";
import { mapGetters, mapState } from "vuex";
import { computeShade } from "@/utils/shades";
import { getDataStoresCollectionToCSV } from "@/api/datastorecollection";
import FileDownloader from "@/mixins/FileDownloader";
import moment from "moment-timezone";

const dynamicGeneratedUrl = "dynamic_g_url_";

export default {
  name: "PreviewTable",
  mixins: [FileDownloader],
  props: {
    tableData: {
      required: true,
      type: Array
    },
    exportData: {
      type: Array
    },
    tableColumns: {
      required: true,
      type: Array
    },
    defaultColumns: {
      required: false,
      type: Array,
      default: () => ["_id", "_uuid", "_timestamp"]
    },
    tableSize: {
      required: false,
      type: String,
      default: "small"
    },
    isSystemLog: {
      required: false,
      type: Boolean,
      default: false
    },
    showAllInteractionLogs: {
      required: false,
      type: Boolean,
      default: false
    },
    allowEdit: {
      required: false,
      type: Boolean,
      default: false
    },
    searchVisible: {
      required: false,
      type: Boolean,
      default: false
    },
    loadingTable: {
      required: false,
      type: Boolean,
      default: false
    },
    hideSeq: {
      required: false,
      type: Boolean,
      default: false
    },
    allowExport: {
      required: false,
      type: Boolean,
      default: false
    },
    allowConfigureExport: {
      required: false,
      type: Boolean,
      default: false
    },
    showPagination: {
      required: false,
      type: Boolean,
      default: false
    },
    useAudioFileUploaderInList: {
      required: false,
      type: Boolean,
      default: true
    },
    showQueryConditionBuilderIcon: {
      required: false,
      type: Boolean,
      default: true
    },
    showRefreshIcon: {
      required: false,
      type: Boolean,
      default: true
    },
    disableNavigation: {
      type: Boolean,
      required: false,
      default: false
    },
    sortable: {
      type: Boolean,
      default: true
    }
  },
  components: {
    FileUploaderMinimal,
    AudioPlayer,
    SystemLogNodeNavigation
  },
  data() {
    return {
      allAlign: null,
      listLoading: false,
      selectedRecords: [],
      originalData: [],
      editedItems: [],
      filterName: "",
      selectRow: null,
      showInsertForm: false,
      submitLoading: false,
      executingButton: false,
      showAdvancedDataExport: false,
      formData: {},
      boolOptions: [
        {
          label: "true",
          value: true
        },
        {
          label: "false",
          value: false
        }
      ],
      formRules: {},
      formItems: [],
      colNames: [],
      editColNames: [],
      generatedUrlPrefix: dynamicGeneratedUrl,
      generatedUrlPrefixRegex: new RegExp(`^${dynamicGeneratedUrl}`)
    };
  },
  computed: {
    ...mapGetters("app", {
      displayDateFormatMoment: "displayDateFormatMoment",
      displayDateTimeSecondsFormatMoment: "displayDateTimeSecondsFormatMoment",
      formattedDateTime: "formattedDateTime"
    }),
    ...mapState("datastorecollection", {
      requestData: state => state.requestData
    }),
    isProduction() {
      return (
        process.env.VUE_APP_BACKEND_BASE_API.includes("us7") ||
        process.env.VUE_APP_BACKEND_BASE_API.includes("five9.net")
      );
    },
    filteredTableColumns() {
      if (this.isSystemLog) {
        return _.filter(this.tableColumns, tableColumn => {
          return !_.includes(tableColumn["name"], "details");
        });
      }
      return this.tableColumns;
    },
    getFilterIcon() {
      return require("@/assets/icons/preview_table/query_builder_ds.svg");
    },
    getExportIcon() {
      return require("@/assets/icons/preview_table/export_ds_data.svg");
    },
    getRefreshIcon() {
      return require("@/assets/icons/preview_table/refresh_ds_data.svg");
    },
    getInsertIcon() {
      return require("@/assets/icons/preview_table/insert_ds_data.svg");
    },
    getDeleteIcon() {
      return require("@/assets/icons/preview_table/delete_ds_data.svg");
    },
    getSaveChangesIcon() {
      return require("@/assets/icons/preview_table/save_ds_changes.svg");
    },
    //TODO: Update Icon
    getConfigureExportIcon() {
      return require("@/assets/icons/preview_table/export_ds_data.svg");
    },
    editActionMessage() {
      if (this.updatedData) {
        let updateCount = this.editedItems.length;
        let message = `Update ${this.editedItems.length}`;
        return (
          message +
          (updateCount > 1 || updateCount === 0 ? " records" : " record")
        );
      }
      return "";
    },
    editWarningMessage() {
      if (this.updatedData) {
        let updateCount = this.editedItems.length;
        return __(":count :record edited but not uploaded", {
          count: this.editedItems.length,
          record:
            updateCount > 1 || updateCount === 0 ? __("records") : __("record")
        });
      }
      return "";
    },
    deleteActionMessage() {
      if (this.checkBoxSelected) {
        let deleteCount = this.selectedRecords.length;
        return __("Delete :count :record", {
          count: this.selectedRecords.length,
          record:
            deleteCount > 1 || deleteCount === 0 ? __("records") : __("record")
        });
      }
      return "";
    },
    list() {
      if (this.loadingTable) {
        return [];
      }
      const filterName = _.toString(this.filterName)
        .trim()
        .toLowerCase();

      let columns = this.tableColumns.map(o => o.name);

      if (filterName) {
        const filterRE = new RegExp(filterName, "gi");
        const searchProps = columns;
        const rest = this.tableData.filter(item =>
          searchProps.some(
            key =>
              _.toString(item[key])
                .toLowerCase()
                .indexOf(filterName) > -1
          )
        );
        return rest.map(row => {
          const item = Object.assign({}, row);
          searchProps.forEach(key => {
            item[key] = _.toString(item[key]).replace(
              filterRE,
              match => `<span  class="highlight">${match}</span>`
            );
          });
          return item;
        });
      }

      // cast the boolean value to a string so sort works
      return this.tableData.map(item => {
        columns.forEach(key => {
          if (typeof item[key] === "boolean") {
            item[key] = _.toString(item[key]);
          }
        });
        return item;
      });
    },
    nonDefaultFormItems() {
      return _.filter(
        this.tableColumns,
        formItem => !this.defaultColumns.includes(formItem.name)
      );
    },
    updatedData() {
      return !!this.editedItems.length;
    },
    formAudioFileName() {
      return row => {
        let path = _.get(this.formData, row.field, "");
        return this.getTailName(path);
      };
    },
    formAudioFilePath() {
      return row => {
        return this.formData[this.generatedUrlPrefix + row.field];
      };
    },
    tableAudioFileName() {
      return (row, column) => {
        let result = this.tableData.filter(function(data) {
          return data === row;
        });
        let path = _.get(result, `0.${column.name}`, "");
        return this.getTailName(path);
      };
    },
    columnTypeOf() {
      return name => {
        let column = _.find(this.tableColumns, { name });
        return !column ? "string" : column.type;
      };
    },
    convertTSToDateTime() {
      return (row, column) => {
        if (column.secure || !row[column.name]) {
          return row[column.name];
        }
        return this.formattedDateTime(
          row[column.name],
          `${this.displayDateTimeSecondsFormatMoment}.SSS Z`
        );
      };
    },

    formatDateTime() {
      return (row, column) => {
        if (column.secure) {
          return row[column.name];
        }
        if (row[column.name] === null) {
          return "NULL";
        }

        if (!row[column.name]) {
          return row[column.name];
        }

        return moment(row[column.name]).format(
          this.displayDateTimeSecondsFormatMoment
        );
      };
    },

    getClass() {
      return (row, column) => {
        if (column.secure) {
          return "";
        }
        if (row[column.name] === null) {
          return "null";
        }
        return "";
      };
    },

    formatValue() {
      return (row, column) => {
        if (column.secure) {
          return row[column.name];
        }
        if (row[column.name] === null) {
          return "NULL";
        }
        return row[column.name];
      };
    },

    formatDate() {
      return (row, column) => {
        if (column.secure) {
          return row[column.name];
        }

        if (row[column.name] === null) {
          return "NULL";
        }

        if (!row[column.name]) {
          return row[column.name];
        }

        return moment(row[column.name]).format(this.displayDateFormatMoment);
      };
    },

    tableAudioFilePath() {
      return (row, column) => {
        return row[this.generatedUrlPrefix + column.name];
      };
    },
    checkBoxSelected() {
      return !!this.selectedRecords.length;
    },
    audioColumns() {
      return this.nonDefaultFormItems.reduce((filtered, item) => {
        if (item.type === "audio") {
          filtered.push(item.name);
        }
        return filtered;
      }, []);
    }
  },
  mounted() {
    let vm = this;
    _.forEach(this.nonDefaultFormItems, function(column) {
      let key = column.name;
      if (
        !(
          vm.generatedUrlPrefixRegex.test(key) ||
          vm.isReservedTranscription(key)
        )
      ) {
        vm.buildFormItems(column);
        vm.colNames.push(key);
      }
      vm.editColNames.push(key);
    });
  },
  methods: {
    async toggleAdvancedDataExportVisibility() {
      this.showAdvancedDataExport = await this.showFeature(
        this.$getConst("ADVANCED_DATA_EXPORT")
      );
    },
    getTitleHelp(column) {
      if (column.secure) {
        return {
          icon: "el-icon-lock"
        };
      }
      // return {};
    },

    emptySortMethod() {
      // as we are sorting in backend, we shouldn't use
      // vxe-table sorting anymore
      return false;
    },
    isReservedTranscription(columnName) {
      if (!columnName.endsWith("_transcription")) {
        return false;
      }
      return this.audioColumns.some(audioColumn => {
        return audioColumn + "_transcription" === columnName;
      });
    },

    getNodeIdToNavigate(columnName, details) {
      if (details) {
        if (columnName === "param1") {
          if (details.current_node_id && details.current_node_id !== "none")
            return Number(details.current_node_id);
          else if (details.node_id && details.node_id !== "none")
            return Number(details.node_id);
        } else if (
          columnName === "param2" &&
          details.next_node_id &&
          details.next_node_id !== "none"
        ) {
          return Number(details.next_node_id);
        }
      }
      return -1;
    },
    getTaskIdToNavigate(details) {
      return details && details.task_id ? details.task_id : -1;
    },
    getSubTaskIdToNavigate(details) {
      return details && details.sub_task_id ? details.sub_task_id : -1;
    },
    handleKeyPressOnHover(event) {
      this.$emit("handle-ctrl-key-press-on-hover", event);
    },
    handleCtrlKeyRelease(event) {
      this.$emit("handle-ctrl-key-release", event);
    },
    handleNodeLinkHover(node_id, component_id) {
      this.$emit("handle-node-link-hover", node_id, component_id);
    },
    navigateToNode(node_id, task_id = null) {
      // navigate to to node under the sub_task if set
      if (task_id && task_id !== -1) {
        this.$emit("close-log-modal");
        this.$router.push({
          name: "callflow",
          params: { task_id: task_id, nav_to_node_id: node_id }
        });
      } else {
        this.$emit("navigate-to-node", node_id);
      }
    },
    peekNode(node_id) {
      if (node_id && node_id !== -1) {
        this.$emit("peek-node", node_id);
      }
    },
    endPeekNode() {
      this.$emit("end-peek-node");
    },
    navigateToTask(task_id) {
      this.$emit("close-log-modal");
      this.$router.push({ name: "callflow", params: { task_id: task_id } });
    },
    expandVisibleMethod({ row }) {
      return _.has(row, "details") && !_.isEmpty(row.details);
    },
    handleCurrentChange({ row }) {
      this.$emit("row-click", row);
    },
    activeCellMethod({ column }) {
      return !this.defaultColumns.includes(column.property);
    },
    selectCheckBoxEvent({ records }) {
      this.selectedRecords = this.$refs.xTable.getCheckboxRecords();
      this.$emit("checkbox-select-event", _.cloneDeep(records));
    },
    saveChangesEvent() {
      let updateRecords = {};
      updateRecords["data"] = this.editedItems;
      this.$emit("save-data-event", updateRecords);
      this.editedItems = [];
      this.selectedRecords = [];
    },
    deleteDataEvent() {
      this.$emit("delete-data-event", _.cloneDeep(this.selectedRecords));
      this.selectedRecords = [];
    },
    refreshEvent() {
      this.editedItems = [];
      this.selectedRecords = [];
      this.$emit("refresh-data-event");
    },
    handleSortChangeEvent({ property, order }) {
      this.$emit("update-sort-event", {
        column: property,
        sortBy: order === "asc" ? "asc" : "desc"
      });
    },
    handleTableAudioUploadSuccess({ path, url }) {
      return (row, column) => {
        let item = this.editedItems.find(item => item._id === row._id);
        if (item) {
          item[column.name] = path;
          item[this.generatedUrlPrefix + column.name] = url;
          row[column.name] = path;
          row[this.generatedUrlPrefix + column.name] = url;
        } else {
          row[column.name] = path;
          row[this.generatedUrlPrefix + column.name] = url;
          this.editedItems.push(row);
        }
      };
    },
    handleTableAudioRemove() {
      return (row, column) => {
        let item = this.editedItems.find(item => item._id === row._id);
        if (item) {
          item[column.name] = "";
          item[this.generatedUrlPrefix + column.name] = "";
          row[column.name] = "";
          row[this.generatedUrlPrefix + column.name] = "";
        } else {
          row[column.name] = "";
          row[this.generatedUrlPrefix + column.name] = "";
          this.editedItems.push(row);
        }
      };
    },
    handleFormAudioUploadSuccess({ path, url }) {
      return row => {
        this.formData[row.field] = path;
        this.formData[this.generatedUrlPrefix + row.field] = url;
      };
    },
    handleFormAudioRemove() {
      return row => {
        this.formData[row.field] = "";
        this.formData[this.generatedUrlPrefix + row.field] = "";
      };
    },
    fieldType(dataType) {
      if (dataType === "string") {
        return { name: "input", attrs: { type: "text" } };
      } else if (dataType === "number") {
        return { name: "$input", props: { type: "number" } };
      } else if (dataType === "double") {
        return { name: "$input", props: { type: "double", digits: 4 } };
      } else if (dataType === "boolean") {
        return {
          name: "$select",
          props: {
            type: "boolean"
          },
          options: [
            {
              label: "true",
              value: true
            },
            {
              label: "false",
              value: false
            }
          ]
        };
      } else if (dataType === "date") {
        return {
          name: "$input",
          props: {
            type: "date",
            "label-format": "yyyy-MM-dd",
            "value-format": "yyyy-MM-dd",
            "parse-format": "yyyy-MM-dd"
          }
        };
      } else if (dataType === "datetime") {
        return {
          name: "$input",
          props: {
            type: "datetime",
            transfer: true,
            "label-format": "yyyy-MM-dd HH:mm:ss",
            "value-format": "yyyy-MM-dd HH:mm:ss",
            "parse-format": "yyyy-MM-dd HH:mm:ss"
          }
        };
      }
      return { name: "input", attrs: { type: "text" } };
    },
    parseFieldData(dataType, value = null, secure = false) {
      if (secure) {
        return value;
      }
      if (dataType === "number") {
        return value ? parseInt(value) : null;
      } else if (dataType === "double") {
        return value ? parseFloat(value) : null;
      } else if (dataType === "boolean") {
        return value ? Boolean(value) : false;
      } else if (dataType === "date") {
        return value ? value : null;
      } else if (dataType === "datetime") {
        return value ? value : null;
      }
      return value ? value : "";
    },
    insertEvent() {
      let sampleData = {};
      let vm = this;
      _.forEach(this.tableColumns, function(column) {
        sampleData[column.name] = vm.parseFieldData(column.type);
      });
      this.formData = sampleData;
      this.selectRow = null;
      this.showInsertForm = true;
    },
    submitEvent() {
      this.submitLoading = true;
      this.showInsertForm = false;
      let vm = this;
      let requestData = {};
      _.forEach(this.tableColumns, function(column) {
        requestData[column.name] = vm.parseFieldData(
          column.type,
          vm.formData[column.name]
        );
      });
      this.$emit("insert-data-event", _.cloneDeep(requestData));
      this.submitLoading = false;
    },
    buildFormItems(column) {
      let columnLabel = _.startCase(_.toLower(column.label));
      this.formItems.push({
        field: column.name,
        title: columnLabel,
        span: 12,
        type: column.type === "double" ? "float" : column.type,
        itemRender: {
          name: "$input",
          props: {
            ...this.fieldType(column.type).props,
            placeholder: `Enter ${columnLabel}`
          }
        }
      });
    },
    editClosedEvent() {
      let updatedData = _.map(
        this.tableData,
        _.partialRight(_.pick, [...this.defaultColumns, ...this.editColNames])
      );
      this.editedItems = _.differenceWith(
        updatedData,
        this.originalData,
        _.isEqual
      );
    },
    exportCSV() {
      this.executingButton = true;
      // let exportDataLimit = 10000;
      // let finalSelectedRecords;
      let visible = _.get(
        this.$refs.xTable.getTableColumn(),
        "visibleColumn",
        []
      );
      visible = _.filter(
        _.map(visible, column => {
          return _.get(column, "property");
        }),
        item => !_.isUndefined(item)
      );

      // export always exports min(studio.data_export_preview_row_limit, total_records_in_datastore_for_filters_applied) records

      // if (this.selectedRecords.length) {
      //   if (this.selectedRecords.length < exportDataLimit) {
      //     finalSelectedRecords = this.selectedRecords;
      //   } else {
      //     this.$message({
      //       // eslint-disable-next-line
      //       message: __("Export Limit reached! You can export upto :export_data_limit rows", {export_data_limit: exportDataLimit}),
      //       type: "info"
      //     });
      //     finalSelectedRecords = this.selectedRecords.slice(0, exportDataLimit);
      //   }
      // }
      getDataStoresCollectionToCSV(
        this.requestData,
        undefined,
        { fetch_all: 1 },
        // finalSelectedRecords,
        visible
      )
        .then(response => {
          if (response.data.url) {
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.name = "silent-download-frame";
            iframe.src = response.data.url;
            document.body.appendChild(iframe);
            setTimeout(() => {
              document.body.removeChild(iframe);
            }, 10000);
            this.executingButton = false;
          } else {
            this.$message({
              message: __("Failed to export data"),
              type: "error"
            });
            this.executingButton = false;
          }
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.message
          });
        });
    },

    downloadCSV() {
      this.executingButton = true;
      let exportOptions = {
        filename: "csv_data",
        type: "csv",
        columnFilterMethod({ column }) {
          return !(typeof column.property === "undefined");
        }
      };
      exportOptions["data"] = _.map(this.exportData, record => {
        let finalRecord = {};
        _.map(record, (value, key) => {
          if (
            !this.generatedUrlPrefixRegex.test(key) &&
            this.columnTypeOf(key) !== "audio"
          ) {
            finalRecord[key] = value;
          } else if (this.columnTypeOf(key) === "audio") {
            finalRecord[key] = record[this.generatedUrlPrefix + key];
          }
        });
        return finalRecord;
      });
      // exportOptions["data"] = this.exportData;
      this.$refs.xTable.exportData(exportOptions);
      this.executingButton = false;
    },

    showConfigureExportData() {
      this.$emit("show-configure-export-data", true);
    },

    showQueryCondition() {
      this.$emit("show-query-condition", true);
      this.$emit("reset-configure-export-condition", true);
    },
    getName(p) {
      return path.basename(p);
    },
    getTailName(p) {
      let nameParts = _.split(this.getName(p), "_");
      if (nameParts.length > 1) {
        return _.tail(nameParts).join("_");
      } else {
        return nameParts.join("_");
      }
    },
    generateRowShade(depth) {
      if (isNaN(depth)) return {};
      const color = getComputedStyle(document.documentElement).getPropertyValue(
        "--theme-color"
      );
      const colorSettingsForDepth = {
        1: { shade_index: 0.45, text_color: "black" },
        2: { shade_index: 0.3, text_color: "black" },
        3: { shade_index: 0.15, text_color: "black" },
        4: { shade_index: 0.05, text_color: "black" }
      };
      if (depth > 4) {
        depth = 4;
      }
      let colorSettings = colorSettingsForDepth[depth];
      let bgShadeColor = computeShade(colorSettings.shade_index, color.trim());
      return {
        backgroundColor: bgShadeColor,
        color: colorSettings.text_color
      };
    },
    getRowStyle({ row }) {
      if (row.details && !_.isEmpty(row.details)) {
        if (
          row.details.execution_type &&
          !_.isEmpty(row.details.execution_type)
        ) {
          return row.details.execution_type === "sub_task"
            ? this.generateRowShade(row.details.execution_depth)
            : {};
        }
        return {};
      }
    }
  },
  watch: {
    exportData: function() {
      this.downloadCSV();
    },
    selectedRecords: {
      deep: true,
      handler() {}
    },
    selectedAccountId: {
      immediate: true,
      async handler(newVal) {
        if (newVal === "all") {
          this.showAdvancedDataExport = false;
        } else {
          await this.toggleAdvancedDataExportVisibility();
        }
      }
    },
    loadingTable: {
      immediate: true,
      handler(loading) {
        this.$nextTick(() => {
          if (!loading) {
            this.originalData = _.cloneDeep(
              _.map(
                this.tableData,
                _.partialRight(_.pick, [
                  ...this.defaultColumns,
                  ...this.editColNames
                ])
              )
            );
            this.editedItems = [];
            this.selectedRecords = [];
          }
        });
      }
    }
  }
};
</script>
<style lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;

@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";

.null {
  font-style: italic;
  text-transform: uppercase;
  color: #a0a8b5;
}

::v-deep .highlight {
  color: #000;
  background-color: #ffff00;
}
.exportBtn {
  background-color: $content-theme-color;
  border-color: transparent;
  &:hover {
    background-color: $content-theme-hover-color;
    border-color: transparent;
  }
}

.audio__row {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .minimal-file-uploader {
    ::v-deep .el-popover__reference {
      margin-right: 10px;
      margin-left: 0 !important;
    }
  }
}

.simple-player {
  border-radius: 12px;
  display: inline-block;
  background: white !important;

  ::v-deep img {
    filter: invert(58%) sepia(52%) saturate(1516%) hue-rotate(166deg)
      brightness(96%) contrast(96%);
  }
}

.vxe-input--panel {
  z-index: 9999 !important;
}

.vxe-table--tooltip-wrapper {
  user-select: all;
  z-index: 99999999 !important;
}

.vxe-table--tooltip-wrapper .vxe-table--tooltip-content {
  white-space: pre-wrap !important;
}

.vxe-button.type--button.is--circle {
  padding: 0 0.5em;
  border-radius: 8% !important;
  height: 44px;
  width: 42px;
  border: 1px solid #dcdfe6;
  color: #000000;
  background-color: transparent;
  &:hover {
    background-color: transparent;
    border-color: $content-theme-color;
    color: var(--theme-color);
    ::v-deep svg {
      fill: $content-theme-hover-color;
      path {
        fill: $content-theme-hover-color;
      }
    }
  }
  &.is-disabled:hover,
  &.is-disabled {
    color: #a0a8b5;
    border-color: #f5f5f8 !important;
    ::v-deep svg {
      fill: #a0a8b5;
      path {
        fill: #a0a8b5;
      }
    }
  }
}

.vxe-button.type--button:not(.is--disabled):focus {
  border-color: #f5f5f8 !important;
  -webkit-box-shadow: 0 0 0.25em 0 #f5f5f8 !important;
  box-shadow: 0 0 0.25em 0 #f5f5f8 !important;
}

.is--checked.vxe-custom--option,
.is--checked.vxe-export--panel-column-option,
.is--checked.vxe-table--filter-option,
.is--indeterminate.vxe-custom--option,
.is--indeterminate.vxe-export--panel-column-option,
.is--indeterminate.vxe-table--filter-option,
.vxe-table .is--checked.vxe-cell--checkbox,
.vxe-table .is--indeterminate.vxe-cell--checkbox {
  color: var(--theme-color) !important;
}

.vxe-custom--option:not(.is--disabled):hover .vxe-checkbox--icon:before,
.vxe-export--panel-column-option:not(.is--disabled):hover.vxe-checkbox--icon:before,
.vxe-table--filter-option:not(.is--disabled):hover .vxe-checkbox--icon:before,
.vxe-table.vxe-cell--checkbox:not(.is--disabled):hover.vxe-checkbox--icon:before {
  border-color: var(--theme-color) !important;
}

.is--checked.vxe-custom--option .vxe-checkbox--icon:before,
.is--checked.vxe-export--panel-column-option .vxe-checkbox--icon:before,
.is--checked.vxe-table--filter-option .vxe-checkbox--icon:before,
.is--indeterminate.vxe-custom--option .vxe-checkbox--icon:before,
.is--indeterminate.vxe-export--panel-column-option .vxe-checkbox--icon:before,
.is--indeterminate.vxe-table--filter-option .vxe-checkbox--icon:before,
.vxe-table .is--checked.vxe-cell--checkbox .vxe-checkbox--icon:before,
.vxe-table .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon:before {
  border-color: var(--theme-color) !important;
  background-color: var(--theme-color) !important;
}
.vxe-toolbar {
  margin: 0;
}

.vxe-toolbar .vxe-custom--option-wrapper .vxe-custom--footer button:hover {
  color: var(--theme-color);
}

.vxe-select--panel {
  z-index: 9997 !important;
}

.vxe-toolbar .vxe-button--wrapper {
  .page-toolbar {
    float: left;
    padding-left: 10px !important;
  }

  .form-container {
    height: 44px;

    .el-form-item {
      margin: 0;
    }
  }
}
.vxe-body--row {
  height: 44px;
  &:hover {
    filter: brightness(90%);
  }
}
.table-actions {
  font-weight: 600;
  font-size: larger;
  margin-left: -7px;
}
</style>
<style lang="scss" scoped>
.advancedBtn {
  margin-left: 10px;
  height: 44px !important;
  width: 25px !important;
  border-color: #f5f5f8;
  color: #a0a8b5;
  background-color: transparent;
  &:hover {
    background-color: transparent;
    border-color: var(--theme-color);
    ::v-deep svg {
      fill: var(--theme-hover-color);
      path {
        fill: var(--theme-hover-color);
      }
    }
    .bulkImg {
      width: 24px;
      margin-left: -10px;
    }
  }
  &.is-disabled:hover,
  &.is-disabled {
    color: #a0a8b5;
    border-color: #f5f5f8;
    ::v-deep svg {
      fill: #a0a8b5;
      path {
        fill: #a0a8b5;
      }
    }
  }
}
.bulkImg {
  width: 24px;
  margin-left: -10px;
}

.audio-player {
  border-radius: 6px;
  display: inline-block;
  flex-direction: column;
  background-color: white;
  border-color: #0b8e6b;
  color: #454545;
  width: 200px;

  ::v-deep .audio-bar-contents {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.7rem;
    padding-left: 5px;
    padding-right: 5px;

    .control-group {
      display: flex;
      flex-direction: row;
      min-width: 10px;
      align-items: center;
    }

    .audio-bar-content {
      padding-left: 0;
      padding-right: 3px;
    }

    .audio-control {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .audio-progress {
      padding-left: 5px;
      padding-right: 10px;
      flex: 1;

      &.disabled {
        ::v-deep .el-slider__button {
          background-color: #a0a8b5 !important;
          border-color: #a0a8b5 !important;
        }

        ::v-deep .el-slider__runway {
          height: 1px;
          background-color: #454545;

          ::v-deep .el-slider__bar {
            height: 1px;
            background-color: #4db3f6;
          }

          ::v-deep .el-slider__button {
            width: 3px;
            height: 3px;
            border-radius: 2px;
          }
        }
      }

      ::v-deep .el-slider__runway {
        height: 3px;
        background-color: #454545;
        ::v-deep .el-slider__bar {
          height: 3px;
          background-color: #4db3f6;
        }

        ::v-deep.el-slider__button-wrapper {
          height: 34px;
          width: 12px;

          ::v-deep .el-tooltip {
            vertical-align: middle;
          }
        }
        ::v-deep .el-slider__button {
          width: 10px;
          height: 10px;
          border-radius: 8px;
          border-width: 1px;
          border-color: #454545;
        }
      }
    }
  }
}
</style>
