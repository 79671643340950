<template>
  <el-dialog
    :visible.sync="dialogVisible"
    v-if="dialogVisible"
    @close="handleDialogClose"
    :close-on-click-modal="isInListMode"
    :close-on-press-escape="isInListMode"
    show-close
    custom-class="globalContentItemModal"
    top="4vh"
    width="90%"
    append-to-body
  >
    <el-row>
      <el-col :span="6"><folders-sidebar></folders-sidebar></el-col>
      <el-col :span="18">
        <el-alert
          :title="fileWarningTitle"
          type="warning"
          :closable="false"
          show-icon
          style="text-align: left; width: 50%; margin: 20px; border-radius: 5px"
          id="allowedFileTypes"
          v-if="displayAllowedFileExtensions"
        >
          <div>
            <ul style="margin-top: 0">
              <li>
                {{ showOnlyFiles }}
              </li>
            </ul>
          </div>
        </el-alert>
        <files-index
          @add-attachment="handleAddAttachment"
          can-attach-as-file
          :can-multi-select="canMultiSelect"
          :show-only="showOnly"
        ></files-index>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import { EventBus } from "@/EventBus";
import FoldersSidebar from "@/views/build/content/folders/FoldersSidebar";
import FilesIndex from "@/views/build/content/files/index";
import { mapActions } from "vuex";
import _ from "lodash";
import {
  MAP_FILE_TYPE_TO_UPLOAD_TYPE,
  VISUAL_FORM_FIELD_TYPES
} from "@/constants/visualFormFieldTypes.js";
import { AUDIO, DOCUMENT, IMAGE, VIDEO } from "@/constants/fileTypes";

export default {
  name: "FileExplorer",
  components: {
    FoldersSidebar,
    FilesIndex
  },
  props: {
    canMultiSelect: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      mode: "list",
      itemToEdit: {},
      showOnly: "all"
    };
  },
  created() {
    EventBus.$on("open-node-attachment-modal", (source, type) => {
      this.showOnly = type;
      this.setGlobalContentModalIsOpen(true);
      this.dialogVisible = true;
    });
    EventBus.$on("close-node-attachment-modal", () => {
      this.setGlobalContentModalIsOpen(false);
      this.dialogVisible = false;
    });
  },
  computed: {
    isInListMode() {
      return this.mode === "list";
    },
    displayAllowedFileExtensions() {
      return [DOCUMENT, IMAGE, AUDIO, VIDEO].includes(this.showOnly);
    },
    showOnlyFiles() {
      let showOnlyFiles = [];

      if (!this.displayAllowedFileExtensions) {
        return showOnlyFiles;
      }

      showOnlyFiles = this.getFileExtensionUploadWhitelist(
        MAP_FILE_TYPE_TO_UPLOAD_TYPE[this.showOnly]
      );

      return showOnlyFiles.map(item => item.toUpperCase()).join(", ");
    },
    fileWarningTitle() {
      return `Can only select following file types for Attachment type ${_.startCase(
        this.showOnly
      )}`;
    }
  },
  methods: {
    ...mapActions("app", {
      setGlobalContentModalIsOpen: "setGlobalContentModalIsOpen"
    }),

    getFileExtensionUploadWhitelist(fieldType) {
      const type = VISUAL_FORM_FIELD_TYPES.filter(
        type => type.value === fieldType
      ).pop();

      return _.get(type, "allowedExtensions", []);
    },

    handleAddAttachment(file_url, file_id, file_name) {
      this.$emit("add-attachment", file_url, file_id, file_name);
      this.setGlobalContentModalIsOpen(false);
      this.dialogVisible = false;
    },

    changeMode() {
      this.itemToEdit = {};
      this.mode = "edit";
    },

    handleEdit(item) {
      this.itemToEdit = item;
      this.mode = "edit";
    },

    handleDialogClose() {
      this.setGlobalContentModalIsOpen(false);
    }
  },
  mounted() {
    this.contentType = "File";
    this.primaryKey = "file_id";
  }
};
</script>

<style lang="scss" scoped>
.btnAdd {
  margin-top: 30px;
}

.dialog-container {
  padding: 30px;
}
</style>

<style lang="scss">
.globalContentItemModal {
  padding: 0;
  border-radius: 0;
  .contentCardsContainer {
    max-height: calc(100vh - 400px) !important;
  }
  .el-dialog__header {
    padding: 0;
    .el-dialog__headerbtn {
      z-index: 100;
    }
  }

  .el-dialog__body {
    padding: 0 !important;
    //height: 90vh;
    /*overflow: hidden;*/
  }

  .el-dialog__footer {
    padding: 0;
  }
}
</style>
