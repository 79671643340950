var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.visibleFields.includes('auth_profile'))?_c('el-form-item',{attrs:{"label":_vm.__('Authentication'),"prop":_vm.customizePropPath('auth_profile')}},[_c('el-select',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.authProfilesLoading),expression:"authProfilesLoading"}],staticClass:"w-full",attrs:{"value":_vm.nodeToBind.web_service_node.data.additional_settings['auth_profile'],"placeholder":_vm.__('Select Authentication'),"default-first-option":""},on:{"input":function($event){return _vm.$set(
          _vm.nodeToBind.web_service_node.data.additional_settings,
          'auth_profile',
          $event
        )},"change":function($event){return _vm.handleFieldUpdate($event, 'auth_profile')}}},_vm._l((_vm.ccAuthProfiles),function(item){return _c('el-option',{key:item.auth_profile_id,attrs:{"label":item.auth_profile_name,"value":item.auth_profile_id}})}),1)],1):_vm._e(),(_vm.visibleFields.includes('webhook_guid'))?_c('el-form-item',{attrs:{"label":_vm.__('Webhook'),"prop":_vm.customizePropPath('webhook_guid')}},[_c('el-select',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.webhookLoading),expression:"webhookLoading"}],staticClass:"w-full",attrs:{"value":_vm.nodeToBind.web_service_node.data.additional_settings['webhook_guid'],"placeholder":_vm.__('Select Webhook'),"default-first-option":""},on:{"input":function($event){return _vm.$set(
          _vm.nodeToBind.web_service_node.data.additional_settings,
          'webhook_guid',
          $event
        )},"change":function($event){return _vm.handleFieldUpdate($event, 'webhook_guid')}}},_vm._l((_vm.webHooks),function(item){return _c('el-option',{key:item.guid,attrs:{"label":item.name,"value":item.guid}})}),1)],1):_vm._e(),(_vm.visibleFields.includes('options'))?_c('el-form-item',{attrs:{"label":"Additional Options"}},[_c('el-checkbox',{staticStyle:{"margin-left":"2px"},model:{value:(_vm.toWait),callback:function ($$v) {_vm.toWait=$$v},expression:"toWait"}},[_vm._v(" "+_vm._s(_vm.__("Wait For Action Output"))+" ")]),_c('el-checkbox',{staticStyle:{"margin-left":"2px"},attrs:{"id":"toWrap"},model:{value:(_vm.toWrap),callback:function ($$v) {_vm.toWrap=$$v},expression:"toWrap"}},[_vm._v(" "+_vm._s(_vm.__("Wrap Output In Value Object"))+" ")])],1):_vm._e(),(_vm.visibleFields.includes('body'))?[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":_vm.__('Reaction Id'),"prop":_vm.customizePropPath('body.id')}},[_c('el-input',{attrs:{"placeholder":"enter your reaction id here"},model:{value:(_vm.reactionId),callback:function ($$v) {_vm.reactionId=$$v},expression:"reactionId"}})],1)],1)],1),_c('el-form-item',{attrs:{"label":_vm.__('Data fields')}},[_c('query-params',{attrs:{"default-value-property":"test_value","default-key-property":"test_key","use-secure-variables":false},model:{value:(_vm.fieldData),callback:function ($$v) {_vm.fieldData=$$v},expression:"fieldData"}})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }