<script>
import {
  exportTask,
  getExportedTaskFile,
  getSnapShot,
  getTaskCollectionCount
} from "@/api/tasks";
import _ from "lodash";
import TaskImportExportRequest from "@/views/build/tasks/create-task/components/TaskExport/TaskImportExportRequest";
import FileDownloader from "@/mixins/FileDownloader";
import { mapActions } from "vuex";

export default {
  mixins: [TaskImportExportRequest, FileDownloader],
  data() {
    const defaultExportConfig = {
      exportDataStoreWithData: {},
      variables: {},
      authProfiles: {}
    };
    return {
      showTaskExportConfigurationModal: false,
      taskToExportStats: {},
      taskToExport: {},
      loadingSnapshot: false,
      exportingTask: false,
      exportConfiguration: { ...defaultExportConfig },
      defaultExportConfig: { ...defaultExportConfig },
      errored: false,
      ctfFileDownloaded: false,
      taskActionType: "",
      taskHasCollection: false,
      taskCollectionCount: 0,
      taskToDuplicate: {},
      exportCollection: false
    };
  },

  methods: {
    ...mapActions("tasks", {
      duplicateTask: "duplicateTask",
      duplicateTaskCollection: "duplicateTaskCollection"
    }),
    initiateExport() {
      if (!(this.loadingSnapshot || this.exportingTask || this.errored)) {
        this.exportingTask = true;
        this.ctfFileDownloaded = false;

        exportTask(
          this.taskToExport.task_id,
          this.exportConfiguration,
          true,
          this.exportCollection
        )
          .then(({ data }) => {
            this.listenForTaskImportExportUpdates(
              data,
              this.taskToExport.task_id,
              this.onTaskExportCompletionEvent,
              this.onTaskExportErroredEvent,
              this.isTaskExportStatusCheckIntervalClearable
            );
          })
          .catch(err => {
            this.$message({
              type: "error",
              message: err.message || __("Task export failed")
            });
            this.exportingTask = false;
          });
      } else {
        this.$message({
          type: "error",
          message: __("Unable to export task")
        });
      }
    },

    onTaskExportCompletionEvent(
      taskId,
      { request_id: requestId, request_info: requestInfo }
    ) {
      this.stopListeningToTaskImportExportChannel(taskId, requestId);
      if (
        requestInfo &&
        requestInfo.export_type === "ctf" &&
        !this.ctfFileDownloaded
      ) {
        this.downloadExportedTaskFile(this.taskToExport.task_id, requestId);
      }
    },

    onTaskExportErroredEvent(
      taskId,
      { request_id: requestId, request_info: requestInfo }
    ) {
      this.$message({
        type: "error",
        message: _.get(requestInfo, "error") || __("Task export failed")
      });
      this.stopListeningToTaskImportExportChannel(taskId, requestId);
      this.exportingTask = false;
    },

    isTaskExportStatusCheckIntervalClearable(event) {
      let { status } = event;
      return (
        this.ctfFileDownloaded ||
        this.socketConnectionEstablished ||
        status === "completed" ||
        status === "error"
      );
    },

    downloadExportedTaskFile(taskId, requestId) {
      getExportedTaskFile({
        task_id: taskId,
        request_id: requestId
      })
        .then(({ data }) => {
          this.ctfFileDownloaded = true;
          this.downloadFile(
            data,
            `task_export_${_.snakeCase(this.taskToExport.task_name)}.ctf`
          );
          this.stopTaskExportingProcess();
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: __("Task export failed")
          });
          this.exportingTask = false;
        })
        .finally(() => {
          this.stopListeningToTaskImportExportChannel(taskId, requestId);
        });
    },

    stopTaskExportingProcess() {
      this.showTaskExportConfigurationModal = false;
      this.exportingTask = false;
    },

    handleInitiateExport(task, actionType) {
      this.showTaskExportConfigurationModal = true;
      this.taskActionType = actionType;
      this.getTaskSnapshot(task);
    },

    getTaskSnapshot(task) {
      this.taskToExport = task;
      this.taskToExportStats = {};
      this.loadingSnapshot = true;
      getSnapShot({
        task_id: task.task_id,
        new_snapshot: this.useNewTaskExportLayout
      })
        .then(({ data }) => {
          this.listenForTaskImportExportUpdates(
            data,
            task.task_id,
            this.onSnapshotCompletionEvent,
            this.onSnapshotErroredEvent
          );
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.message
          });
          this.errored = true;
          this.loadingSnapshot = false;
        });
    },

    onSnapshotCompletionEvent(
      taskId,
      { request_id: requestId, request_info: requestInfo }
    ) {
      this.stopListeningToTaskImportExportChannel(taskId, requestId);
      this.taskCollectionCount = _.get(requestInfo, "snap.collection_count", 0);
      if (this.taskCollectionCount > 1) {
        this.taskHasCollection = true;
      }
      this.taskToExportStats = _.get(requestInfo, "snap", {});
      this.loadingSnapshot = false;
      this.errored = false;
    },

    onSnapshotErroredEvent(
      taskId,
      { request_id: requestId, request_info: requestInfo }
    ) {
      this.stopListeningToTaskImportExportChannel(taskId, requestId);
      this.$message({
        type: "error",
        message: _.get(requestInfo, "error") || __("Failed to get snapshot")
      });
      this.errored = true;
      this.loadingSnapshot = false;
    },

    async handleInitialDuplicate(task, actionType) {
      this.taskToDuplicate = task;
      await this.getTaskCollection(task.task_id);
      if (!this.taskHasCollection || this.taskCollectionCount === 1) {
        await this.duplicate(task);
      } else {
        this.showTaskExportConfigurationModal = true;
        this.taskActionType = actionType;
      }
    },

    async duplicate(task) {
      this.loadingText = "";
      this.changeTaskDuplicationDoneFlag(false);
      await this.duplicateTask(task)
        .then(data => {
          this.listenForTaskImportExportUpdates(
            data,
            task.task_id,
            this.onTaskDuplicationCompletionEvent,
            this.onTaskDuplicationErrorEvent
          );
        })
        .catch(err => {
          this.changeTaskDuplicationDoneFlag(true);
          this.changeTaskDuplicatingFlag(false);
          this.$message({
            message: err.message || __("operation failed"),
            type: "error"
          });
        });
    },

    duplicateCollection(task, groupName) {
      this.loadingText = "";
      this.changeTaskDuplicationDoneFlag(false);
      this.duplicateTaskCollection({ task, groupName })
        .then(data => {
          this.listenForTaskImportExportUpdates(
            data,
            task.task_id,
            this.onTaskDuplicationCompletionEvent,
            this.onTaskDuplicationErrorEvent
          );
        })
        .catch(err => {
          this.changeTaskDuplicationDoneFlag(true);
          this.changeTaskDuplicatingFlag(false);
          this.$message({
            message: err.message || __("operation failed"),
            type: "error"
          });
        });
    },

    async getTaskCollection(task_id) {
      await getTaskCollectionCount(task_id)
        .then(data => {
          this.taskHasCollection = data.data.has_collection;
          if (this.taskHasCollection) {
            this.taskCollectionCount = data.data.collection_count;
          }
        })
        .catch(() => {
          this.$message.error(__("Failed to get task collection count"));
        });
    },

    initialDuplicate(duplicateForm) {
      if (duplicateForm.process_single_task) {
        this.duplicate(this.taskToDuplicate);
      } else {
        this.duplicateCollection(
          this.taskToDuplicate,
          duplicateForm.group_name
        );
      }
    },
    newExport(exportCollection) {
      this.exportCollection = exportCollection;
      this.initiateExport();
    }
  },
  watch: {
    //resolve conflicts
    showTaskExportConfigurationModal: {
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          if (!val) {
            this.taskToExportStats = {};
            this.taskToExport = {};
          } else {
            this.exportConfiguration = { ...this.defaultExportConfig };
          }
        });
      }
    }
  }
};
</script>
