<script>
import MessagingNode from "./BaseMessagingNode";
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";
import {
  MESSAGE_FORMAT_PLAIN_TEXT,
  MESSAGE_FORMAT_CARD,
  MESSAGE_FORMAT_RICH_TEXT
} from "@/constants/messagingNodeFormat";
import { setupJsonSchemaValidator } from "@/utils/jsonSchemaValidation";

export default {
  mixins: [MessagingNode],
  created() {
    setupJsonSchemaValidator();

    this.$set(
      this.nodeToBind,
      "node_type",
      NODE_TYPES.PROACTIVE_MESSAGE.NODE_TYPE
    );

    this.$set(
      this.nodeToBind.messaging_node.data,
      "sms_node_type",
      "proactive"
    );

    this.$set(
      this.nodeToBind.messaging_node.data,
      "available_message_formats",
      [MESSAGE_FORMAT_PLAIN_TEXT, MESSAGE_FORMAT_RICH_TEXT, MESSAGE_FORMAT_CARD]
    );

    // Checks whether the node type has been changed from a non-messaging type node
    let nodeTypeChangedToMessaging =
      this.node.node_type.data.node_type !==
        NODE_TYPES.PROACTIVE_MESSAGE.NODE_TYPE &&
      !this.node.node_type.data.node_type.includes("messaging_");

    // If message format is empty, and the node isn't being "edited", or node type has changed,
    // the message format should be set as default.
    if (
      _.isEmpty(this.nodeToBind.messaging_node.data.message_format) &&
      (!this.isEditing || nodeTypeChangedToMessaging)
    ) {
      this.$set(
        this.nodeToBind.messaging_node.data,
        "message_format",
        MESSAGE_FORMAT_RICH_TEXT
      );
    }

    if (_.isEmpty(this.nodeToBind.messaging_node.data.phone_number)) {
      this.$set(this.nodeToBind.messaging_node.data, "phone_number", "");
    }

    if (_.isEmpty(this.nodeToBind.messaging_node.data.keyword_conditions)) {
      this.$set(this.nodeToBind.messaging_node.data, "keyword_conditions", {});
    }

    if (_.isEmpty(this.nodeToBind.messaging_node.data.variable_rules)) {
      this.$set(this.nodeToBind.messaging_node.data, "variable_rules", {});
    }

    if (_.isEmpty(this.nodeToBind.messaging_node.data.messaging_node_button)) {
      this.$set(
        this.nodeToBind.messaging_node.data,
        "messaging_node_button",
        {}
      );
    }
  }
};
</script>
