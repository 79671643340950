export const IMAGE = "image";
export const AUDIO = "audio";

export const VIDEO = "video";
export const DOCUMENT = "document";

export const STICKER = "sticker";

export const FILE_TYPES = [IMAGE, AUDIO, VIDEO, DOCUMENT, STICKER];

export const DEFAULT_MEDIA_OPTIONS = [
  {
    value: IMAGE,
    get label() {
      return __("Image");
    }
  },
  {
    value: VIDEO,
    get label() {
      return __("Video");
    },
    disabled: true
  },
  {
    value: AUDIO,
    get label() {
      return __("Audio");
    }
  },
  {
    value: DOCUMENT,
    get label() {
      return __("Document");
    }
  },
  {
    value: STICKER,
    get label() {
      return __("Sticker");
    }
  }
];
