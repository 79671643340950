<template>
  <div>
    <el-form-item
      :label="__('Authentication')"
      :prop="customizePropPath('auth_profile')"
      v-if="visibleFields.includes('auth_profile')"
    >
      <el-select
        class="w-full"
        :value="
          nodeToBind.web_service_node.data.additional_settings['auth_profile']
        "
        @input="
          $set(
            nodeToBind.web_service_node.data.additional_settings,
            'auth_profile',
            $event
          )
        "
        :placeholder="__('Select Authentication')"
        v-loading="authProfilesLoading"
        default-first-option
        @change="handleFieldUpdate($event, 'auth_profile')"
      >
        <el-option
          v-for="item in ccAuthProfiles"
          :key="item.auth_profile_id"
          :label="item.auth_profile_name"
          :value="item.auth_profile_id"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item
      :label="__('Webhook')"
      :prop="customizePropPath('webhook_guid')"
      v-if="visibleFields.includes('webhook_guid')"
    >
      <el-select
        class="w-full"
        :value="
          nodeToBind.web_service_node.data.additional_settings['webhook_guid']
        "
        @input="
          $set(
            nodeToBind.web_service_node.data.additional_settings,
            'webhook_guid',
            $event
          )
        "
        :placeholder="__('Select Webhook')"
        v-loading="webhookLoading"
        @change="handleFieldUpdate($event, 'webhook_guid')"
        default-first-option
      >
        <el-option
          v-for="item in webHooks"
          :key="item.guid"
          :label="item.name"
          :value="item.guid"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <!--    <el-form-item-->
    <!--      label="Capability"-->
    <!--      :prop="customizePropPath('capability_name')"-->
    <!--      v-if="visibleFields.includes('capability_name')"-->
    <!--    >-->
    <!--      <el-select-->
    <!--        class="w-full"-->
    <!--        v-model="-->
    <!--          nodeToBind.web_service_node.data.additional_settings[-->
    <!--            'capability_name'-->
    <!--          ]-->
    <!--        "-->
    <!--        v-loading="capabilityLoading"-->
    <!--        placeholder="Select Capability"-->
    <!--        @change="handleFieldUpdate($event, 'capability_name')"-->
    <!--      >-->
    <!--        <el-option-group-->
    <!--          v-for="group in schemaCapabilities"-->
    <!--          :key="group.label"-->
    <!--          :label="group.label"-->
    <!--        >-->
    <!--          <el-option-->
    <!--            v-for="item in group.options"-->
    <!--            :key="item.value"-->
    <!--            :label="item.label"-->
    <!--            :value="item.value"-->
    <!--          >-->
    <!--          </el-option>-->
    <!--        </el-option-group>-->
    <!--      </el-select>-->
    <!--    </el-form-item>-->
    <el-form-item
      label="Additional Options"
      v-if="visibleFields.includes('options')"
    >
      <el-checkbox v-model="toWait" style="margin-left: 2px;">
        {{ __("Wait For Action Output") }}
      </el-checkbox>
      <el-checkbox id="toWrap" v-model="toWrap" style="margin-left: 2px;">
        {{ __("Wrap Output In Value Object") }}
      </el-checkbox>
    </el-form-item>
    <template v-if="visibleFields.includes('body')">
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item
            :label="__('Reaction Id')"
            :prop="customizePropPath('body.id')"
          >
            <el-input
              v-model="reactionId"
              placeholder="enter your reaction id here"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item :label="__('Data fields')">
        <query-params
          v-model="fieldData"
          default-value-property="test_value"
          default-key-property="test_key"
          :use-secure-variables="false"
        />
      </el-form-item>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import BaseUploader from "@/components/uploaders/BaseUploader";

import { requestResponseFromTestURL } from "@/api/services";
import {
  filterRowsIfSomeKeyValueIsAbsent,
  generateKeyValueFrom,
  stringifyJsonArray
} from "@/utils/collection";
import _ from "lodash";
import QueryParams from "../components/QueryParams";

/*
 * Global static identifier for each WhenDu Built-In device/connection
 *  across accounts in WhenDu portal
 **/
const WHENDU_BUILT_IN_SCHEMA_GUID = "8d90d994-0cee-4caa-b40a-891f49f4cf83";

export default {
  mixins: [BaseUploader],
  components: {
    QueryParams
  },
  props: {
    selectedProfile: {
      type: Object,
      required: true
    },
    nodeToBind: {
      type: Object,
      required: true
    },
    appendToPostBody: {
      type: Function,
      required: true
    },
    taskId: {
      required: true,
      type: [Number, String]
    },
    isEditing: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      additionalSettings: {},
      webProfiles: [],
      profileSettings: [],
      webHooks: [],
      selectedDeviceId: "",
      selectedDeviceSchema: {},
      schemaCapabilities: [],
      capabilityString: "",
      baseCatchUrl: "",
      visibleFields: ["auth_profile"],
      webhookLoading: true,
      profileBody: "",
      capabilityLoading: true,
      capabilityTypes: [
        {
          key: "actions",
          identifier: "action"
        },
        {
          key: "events",
          identifier: "event"
        },
        {
          key: "states",
          identifier: "state"
        }
      ],
      requestResponseFromWebService: requestResponseFromTestURL
    };
  },
  async mounted() {
    this.setAuthProfileType("OAuth2");
    this.getAuthProfiles({ notShowLoader: false, fetch_all: 1 });
    this.setProfileSettings();
    if (this.isEditing) {
      await this.fetchFieldData();
      this.setAdditionalSettingsBody(this.reactionId, this.fieldData);
    }
    this.profileBody = this.nodeToBind.web_service_node.data.additional_settings.body;
  },
  computed: {
    ...mapGetters("authprofiles", {
      ccAuthProfiles: "clientCredentialsAuthProfiles"
    }),
    ...mapState("authprofiles", {
      authProfilesLoading: state => state.loading
    }),
    editorOptions() {
      return {
        mainMenuBar: false,
        mode: "code"
      };
    },
    customizePropPath() {
      return path => {
        return `web_service_node.data.additional_settings.${path}`;
      };
    },
    toWait: {
      get() {
        return this.nodeToBind.web_service_node.data.additional_settings
          .to_wait;
      },
      set(val) {
        this.$set(
          this.nodeToBind.web_service_node.data.additional_settings,
          "to_wait",
          val
        );
        this.updateCatchUrl();
      }
    },
    toWrap: {
      get() {
        return this.nodeToBind.web_service_node.data.additional_settings
          .to_wrap;
      },
      set(val) {
        this.$set(
          this.nodeToBind.web_service_node.data.additional_settings,
          "to_wrap",
          val
        );
        this.updateCatchUrl();
        this.updateVariableRules();
      }
    },
    fieldData: {
      get() {
        if (
          _.isEmpty(
            this.nodeToBind.web_service_node.data.additional_settings.body
          )
        ) {
          return [];
        }
        let bodyData = this.nodeToBind.web_service_node.data.additional_settings
          .body;
        if (_.isObject(bodyData)) {
          return [];
        }
        try {
          bodyData = JSON.parse(bodyData);
        } catch (error) {
          return [];
        }
        let bodyDataArray = _.get(bodyData, "test_data", []);
        if (_.isEmpty(bodyDataArray)) {
          Object.keys(bodyData.data).map(key => {
            bodyDataArray.push({
              key: key,
              value: bodyData.data[key],
              test_key: "",
              test_value: ""
            });
          });
        }

        return bodyDataArray;
      },
      set(val) {
        this.setAdditionalSettingsBody(this.reactionId, val);
      }
    },
    reactionId: {
      get() {
        let bodyData = this.nodeToBind.web_service_node.data.additional_settings
          .body;
        let bodyDataParse = { id: "" };
        if (_.isObject(bodyData)) {
          return _.get(bodyData, "id", "");
        }
        try {
          bodyDataParse = JSON.parse(bodyData);
        } catch (error) {
          return "";
        }
        return _.get(bodyDataParse, "id", "");
      },
      set(val) {
        this.setAdditionalSettingsBody(val, this.fieldData);
      }
    }
  },

  watch: {
    toWrap: {
      immediate: true,
      deep: true,
      handler: function(val) {
        if (val) {
          this.updateCatchUrl();
        }
        this.updateVariableRules();
      }
    }
  },
  methods: {
    ...mapActions("authprofiles", {
      getAuthProfiles: "getAuthProfiles",
      setAuthProfileType: "setAuthProfileType"
    }),
    async fetchFieldData() {
      this.visibleFields = [
        "auth_profile",
        "webhook_guid",
        "capability_name",
        "body",
        "options"
      ];
      await this.fetchWebhookData();
    },
    verifyWebhookAvailability() {
      let webhookId = this.nodeToBind.web_service_node.data.additional_settings[
        "webhook_guid"
      ];
      let selectedWebhook = _.find(this.webHooks, webHook => {
        return webHook.guid === webhookId;
      });
      if (_.isEmpty(selectedWebhook)) {
        this.$message({
          type: "warning",
          message: __("Webhook configured not available.")
        });
        this.$set(
          this.nodeToBind.web_service_node.data.additional_settings,
          "webhook_guid",
          ""
        );
      }
    },
    getDefaultDeviceId() {
      // code that returns a promise whose fulfilled value is a default device id
      return new Promise(resolve => {
        let requestData = this.getRequestDataFor("schema_guid");
        let defaultDevice = "";
        this.requestResponseFromWebService(requestData)
          .then(res => {
            let devices = res.data.ws_response_data;
            defaultDevice = _.find(
              devices,
              device =>
                _.get(device, "deviceSchemaGuid", "") ===
                WHENDU_BUILT_IN_SCHEMA_GUID
            );
            resolve(_.get(defaultDevice, "guid", ""));
          })
          .catch(() => {
            resolve();
          });
      });
    },
    async filterWebhooksForDefaultDevice(webHooks) {
      let defaultDeviceId = await this.getDefaultDeviceId();
      this.webHooks = [];
      if (defaultDeviceId) {
        // filter webhook list to show webhooks belonging to default device
        this.webHooks = _.filter(
          webHooks,
          webhook => _.get(webhook, "deviceGuid") === defaultDeviceId
        );
      }
      this.webhookLoading = false;
      if (this.isEditing) this.verifyWebhookAvailability();
      this.setBaseCatchUrl();
    },
    fetchWebhookData() {
      return new Promise(resolve => {
        let requestData = this.getRequestDataFor("webhook_guid");
        this.visibleFields = _.union(this.visibleFields, ["webhook_guid"]);
        this.webhookLoading = true;
        this.requestResponseFromWebService(requestData)
          .then(res => {
            let webHooks = res.data.ws_response_data;
            this.filterWebhooksForDefaultDevice(webHooks);
            resolve();
          })
          .catch(() => {
            this.webhookLoading = false;
          });
      });
    },
    fetchSchemaData() {
      return new Promise(resolve => {
        let testData = this.getRequestDataFor("schema_guid");
        this.visibleFields = _.union(this.visibleFields, [
          "capability_name",
          "body",
          "options"
        ]);
        this.capabilityLoading = true;
        this.requestResponseFromWebService(testData).then(res => {
          this.selectedDeviceSchema = res.data.ws_response_data;
          let testData = this.getRequestDataFor("capability_name");
          this.requestResponseFromWebService(testData).then(res => {
            let responseData = res.data.ws_response_data;
            this.schemaCapabilities = this.formatSchemaCapabilities(
              responseData
            );
            this.capabilityLoading = false;
            resolve(responseData);
          });
        });
      });
    },
    getRequestDataFor(field) {
      const testObjectRule = {
        key: "test_key",
        value: "test_value"
      };

      return {
        fetch_timeout: 30,
        profile_id: this.nodeToBind.web_service_node.data
          .web_service_profile_id,
        node_type: this.nodeToBind.node_type,
        task_id: +this.taskId,
        url: "test",
        http_method: "GET",
        ac_id: this.selectedAccountId,
        headers: JSON.stringify({}),
        parameters: stringifyJsonArray(
          generateKeyValueFrom(
            filterRowsIfSomeKeyValueIsAbsent(
              this.getParamsForField(field),
              "test_key"
            ),
            testObjectRule
          )
        ),
        post_raw_data: this.formatPostParameters(
          this.nodeToBind.web_service_node.data.post_body_placeholder
        ),
        authentications: this.attachAuthentication(),
        additional_settings: JSON.stringify(
          this.nodeToBind.web_service_node.data.additional_settings
        )
      };
    },
    setProfileSettings() {
      this.profileSettings = JSON.parse(this.selectedProfile.profile_setting);
    },
    setCatchURLCapabilities() {
      // can be useful in later version to support any additional capability
      // let capabilityType = "";
      // let capabilityName = this.nodeToBind.web_service_node.data
      //   .additional_settings["capability_name"];
      // _.map(this.schemaCapabilities, capabilities => {
      //   let capability = _.find(capabilities.options, {
      //     value: capabilityName
      //   });
      //   if (capability) {
      //     capabilityType = capabilities.identifier;
      //   }
      // });
      this.capabilityString = "/action/wait";
      this.visibleFields = _.union(this.visibleFields, [
        "capability_name",
        "body",
        "options"
      ]);
      this.updateCatchUrl();
    },
    setBaseCatchUrl() {
      let webhookId = this.nodeToBind.web_service_node.data.additional_settings[
        "webhook_guid"
      ];
      let selectedWebhook = _.find(this.webHooks, {
        guid: webhookId
      });
      this.baseCatchUrl = _.get(selectedWebhook, "catchUrl");
      this.selectedDeviceId = _.get(selectedWebhook, "deviceGuid");
      this.setCatchURLCapabilities();
    },
    updateCatchUrl() {
      let fullUrl = this.baseCatchUrl + this.capabilityString;
      fullUrl += `?wait=${this.toWait}&wrap=${this.toWrap}`;
      this.$set(
        this.nodeToBind.web_service_node.data.additional_settings,
        "catch_url",
        fullUrl
      );
      this.$emit("updated-catch-url", fullUrl);
    },
    updateVariableRules() {
      let variableRules = _.cloneDeep(
        this.nodeToBind.web_service_node.data.variable_rules.data
      );

      if (_.isEmpty(variableRules)) {
        return;
      }
      _.forEach(variableRules, (variableRule, key) => {
        if (
          variableRule.variable_type === "array" &&
          variableRule.rule_value === "root"
        ) {
          let parseJson = JSON.parse(variableRule.default_value);

          if (this.toWrap) {
            if (
              parseJson.ws_response_data &&
              parseJson.ws_response_data.value === undefined
            ) {
              parseJson.ws_response_data = {
                value: parseJson.ws_response_data
              };
              variableRule.default_value = JSON.stringify(parseJson);
              variableRules[key] = variableRule;
            }
          } else {
            if (
              parseJson.ws_response_data &&
              parseJson.ws_response_data.value
            ) {
              parseJson.ws_response_data = parseJson.ws_response_data.value;
              variableRule.default_value = JSON.stringify(parseJson);
              variableRules[key] = variableRule;
            }
          }
        }

        if (this.toWrap) {
          if (
            variableRule.variable_type === "single_value" &&
            variableRule.rule_value.startsWith("root['ws_response_data']") &&
            variableRule.rule_value.startsWith(
              "root['ws_response_data']['value']"
            ) === false
          ) {
            variableRule.rule_value = variableRule.rule_value.replace(
              "root['ws_response_data']",
              "root['ws_response_data']['value']"
            );
            variableRules[key] = variableRule;
          }
        } else {
          if (
            variableRule.variable_type === "single_value" &&
            variableRule.rule_value.startsWith("root['ws_response_data']") &&
            variableRule.rule_value.startsWith(
              "root['ws_response_data']['value']"
            )
          ) {
            variableRule.rule_value = variableRule.rule_value.replace(
              "root['ws_response_data']['value']",
              "root['ws_response_data']"
            );
            variableRules[key] = variableRule;
          }
        }
      });

      this.$emit("updated-variable-rules", variableRules);
    },
    refreshFields() {
      this.visibleFields = ["auth_profile"];
      this.$set(
        this.nodeToBind.web_service_node.data.additional_settings,
        "webhook_guid",
        ""
      );
      this.baseCatchUrl = "";
      this.capabilityString = "";
      this.webHooks = [];
      this.schemaCapabilities = [];
    },
    async handleFieldUpdate(value, field) {
      if (value) {
        switch (field) {
          case "auth_profile":
            this.refreshFields();
            await this.fetchWebhookData();
            break;
          case "webhook_guid":
            this.setBaseCatchUrl();
            // await this.fetchSchemaData();
            break;
          case "capability_name":
            break;
        }
      }
    },
    getParamsForField(field) {
      let endPoint = this.getFieldEndpoint(field);
      return [
        {
          key: "url_endpoint",
          value: endPoint,
          test_key: "url_endpoint",
          test_value: endPoint
        }
      ];
    },
    getFieldEndpoint(field) {
      let endpoint = "";
      let schemaGuid = "";
      switch (field) {
        case "webhook_guid":
          endpoint = "/webhooks";
          break;
        case "schema_guid":
          endpoint = `/devices?roots=true`;
          break;
        case "capability_name":
          schemaGuid = _.get(this.selectedDeviceSchema, "deviceSchemaGuid", "");
          endpoint = `/device_schemas/${schemaGuid}`;
          break;
      }
      return endpoint;
    },
    formatSchemaCapabilities(responseData) {
      let data = [];
      this.capabilityTypes.forEach(function(capabilityType) {
        let capabilityOptions = _.get(responseData, capabilityType.key, []);
        if (!_.isEmpty(capabilityOptions)) {
          capabilityOptions = capabilityOptions.map(option => {
            return {
              value: option.name,
              label: option.description
            };
          });
          data.push({
            label: capabilityType.key.toUpperCase(),
            identifier: capabilityType.identifier,
            options: capabilityOptions
          });
        }
      });
      return data;
    },
    formatPostParameters(parameters) {
      return JSON.stringify({
        ...parameters
      });
    },
    attachAuthentication() {
      let profile_id = this.nodeToBind.web_service_node.data
        .additional_settings["auth_profile"];
      return JSON.stringify({
        selected_AuthProfile: profile_id,
        auth_type: "OAuth2",
        useAuthProfile: true
      });
    },
    setAdditionalSettingsBody(reactionId, fieldData) {
      let bodyRaw = {
        id: reactionId,
        data: fieldData.reduce((obj, item) => {
          if (item.value && obj) {
            obj[item.key] = item.value;
          }
          return obj;
        }, {})
      };
      let bodyRawTest = {
        id: reactionId,
        data: fieldData.reduce((obj, item) => {
          if (item.test_value && obj) {
            obj[item.test_key] = item.test_value;
          }
          return obj;
        }, {})
      };
      if (!_.isEmpty(bodyRawTest.data)) {
        this.appendToPostBody(JSON.stringify(bodyRawTest));
      } else {
        this.appendToPostBody(JSON.stringify(bodyRaw));
      }
      bodyRaw.test_data = fieldData;
      this.$set(
        this.nodeToBind.web_service_node.data.additional_settings,
        "body",
        JSON.stringify(bodyRaw)
      );
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node_common.scss";
</style>
