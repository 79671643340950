<script>
import _ from "lodash";
import { EventBus } from "@/EventBus";
import { DEFAULT_MEDIA_OPTIONS } from "@/constants/fileTypes";
import { mapState } from "vuex";
import { DEFAULT_DRAG_CLASS, DEFAULT_SELECTOR } from "@/constants/dragRow";

export default {
  name: "BaseAttachment",
  data() {
    return {
      default_media_options: DEFAULT_MEDIA_OPTIONS
    };
  },
  methods: {
    generateUniqueRowClass({ rowIndex }) {
      return "row row-" + rowIndex;
    },

    /**
     * drag start
     * @param selector
     * @param dragClass
     */
    onDragStart(selector = DEFAULT_SELECTOR, dragClass = DEFAULT_DRAG_CLASS) {
      try {
        const rows = document.querySelectorAll(selector);

        if (rows.length > 0) {
          rows.forEach(row => row.classList.add(dragClass));
        }
      } catch (e) {
        console.log(e.toString());
      }
    },

    /**
     * drag end
     * @param selector
     * @param dragClass
     */
    onDragEnd(selector = DEFAULT_SELECTOR, dragClass = DEFAULT_DRAG_CLASS) {
      try {
        const rows = document.querySelectorAll(selector);

        if (rows.length > 0) {
          rows.forEach(function(row) {
            if (row.classList.contains(dragClass)) {
              row.classList.remove(dragClass);
            }
          });
        }
      } catch (e) {
        console.log(e.toString());
      }
    },

    addAttachment(row) {
      this.selected_row = row;
      EventBus.$emit(
        "open-node-attachment-modal",
        "node-attachment",
        row.attachment_type
      );
    },

    handleAddAttachment(file_url, file_id, file_name) {
      this.selected_row.file_id = file_id;
      this.selected_row.file_url = file_url;
      this.selected_row.file_name = file_name;
    },

    showPreview(row) {
      EventBus.$emit(
        "open-file-preview-modal",
        row.file_url,
        row.attachment_type
      );
    }
  },
  computed: {
    ...mapState("canvas", {
      isNodeSubmit: state => state.isNodeSubmit
    }),

    isAttachmentTypeEmpty() {
      return attachmentType => {
        return _.isEmpty(attachmentType);
      };
    },
    readonlyText() {
      return row => {
        return !!row.file_id;
      };
    },
    /**
     * get error class
     * @returns {(function(*, *): (string|undefined))|*}
     */
    invalidClasses() {
      return (row, key, index) => {
        if (index > 0 && !row.attachment_type && !row.file_name) {
          return "empty-row empty-val";
        }
        let hasError = !row[key] || this.hasInvalidVariable(row[key]);
        if (!hasError) {
          return "no-red-highlight";
        }
      };
    },
    /**
     * classes
     * @returns {(function(*, *): (string))|*}
     */
    classes() {
      return (row, key, index) => {
        if (index > 0 && !row.attachment_type && !row.file_name) {
          return "empty-row empty-val";
        }
        if (!row[key]) {
          return "empty-val";
        } else {
          return "non-empty-val";
        }
      };
    },
    /**
     * based on the filter media options passed, add media options to the select
     * @returns {[{label: string, value: string},{disabled: boolean, label: string, value: string},{label: string, value: string},{label: string, value: string},{label: string, value: string}]|({label: string, value: string}|{disabled: boolean, label: string, value: string}|{label: string, value: string}|{label: string, value: string}|{label: string, value: string})[]}
     */
    mediaOptions() {
      if (this.filterDefaultMediaOptions.length > 0) {
        return _.filter(this.default_media_options, mediaOption =>
          this.filterDefaultMediaOptions.includes(mediaOption.value)
        );
      }
      return this.default_media_options;
    },
    DEFAULT_SELECTOR() {
      return DEFAULT_SELECTOR;
    }
  }
};
</script>
<style>
.draggable .action-icon {
  cursor: grab;
}

.attachment-grabbing {
  cursor: grabbing !important;
}

.attachment-grabbing * {
  cursor: grabbing !important;
}
</style>
