<template>
  <div :id="bodyId" class="validation-body">
    <div
      class="validation-content"
      :id="contentId"
      v-for="item in this.validationContent"
      :key="item.node_id"
    >
      <el-alert
        :closable="false"
        type="text"
        :class="{ highlighted: highlightedNode === item.node_id }"
      >
        <div class="content-details">
          <div class="content-icon">
            <img
              class="content-cell-icon"
              :src="getIconForNode(item.node_type)"
              draggable="false"
              alt="Node Icon"
            />
          </div>
          <div class="content-body">
            <div class="content-type">
              {{ item.node_type }}
            </div>
            <div class="content-name">
              {{ item.node_name }}
            </div>
          </div>
        </div>
      </el-alert>
      <div class="error-message" v-html="getNodeMessages(item)"></div>
      <div class="clickable-node" @click="directToNode(item.node_id)">
        Locate Node
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { EventBus } from "@/EventBus";

export default {
  name: "NodeValidationContent",
  components: {},
  props: {
    validationContent: {
      required: false,
      default: () => [],
      type: Array
    },
    category: {
      required: true,
      type: String,
      default: "error"
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      highlightedNode: null
    };
  },
  computed: {
    bodyId() {
      return this.category + "-body";
    },

    contentId() {
      return this.category + "-content";
    }
  },

  methods: {
    directToNode(nodeId) {
      EventBus.$emit("direct-to-node", nodeId);
      this.highlightedNode = nodeId;
    },

    getIconForNode(type) {
      return "/icons/" + type + ".svg";
    },

    getNodeMessages(item) {
      let messages = null;

      switch (this.category) {
        case "error":
          messages = item.errors;
          break;
        case "warning":
          messages = item.warnings;
          break;
        default:
          return "";
      }

      return _.flatten(Object.values(messages)).join("<br>");
    }
  }
};
</script>

<style lang="scss"></style>
