<script>
import { Conditions, Core } from "vue-renderless-expression-builder";
import { fieldTypes, operators } from "@/utils/queryConditionBuilder";
import _ from "lodash";
import { mapActions, mapState } from "vuex";

const { defaultFieldTypes } = Conditions.Defaults;

const defaultOrderBy = { column: "_timestamp", sortBy: "desc" };
const DEFAULT_ROW_LIMIT = "no-limit"; // the max limit will be always controlled by backend
const DATA_STORE_NODE_ROW_LIMIT = 1;

const emptyCondition = {
  maxDepth: 0,
  connectionType: "and",
  children: []
};

export default {
  data() {
    return {
      eb: new Core.ExpressionBuilder(_.cloneDeep(emptyCondition), err => {
        console.log(err);
      }),
      selectedDsCollectionStructure: [
        {
          name: "",
          label: "",
          type: defaultFieldTypes.TEXT,
          visible: true,
          choices: null,
          secure: false
        }
      ],
      defaultColumns: [
        {
          name: "_id",
          label: __("_id (reserved)"),
          type: "objectId"
        },
        {
          name: "_uuid",
          label: __("_uuid (reserved)"),
          type: defaultFieldTypes.TEXT
        },
        {
          name: "_timestamp",
          label: __("_timestamp (reserved)"),
          type: "timestamp"
        },
        {
          name: "_task_id",
          label: __("_task_id (reserved)"),
          type: defaultFieldTypes.NUMBER
        }
      ],
      updateOrDeleteQueryCondition: {
        children: [
          {
            fieldName: "_id",
            operatorName: "=",
            fieldTypeName: "objectId",
            value: ""
          }
        ],
        connectionType: "and",
        maxDepth: 0
      },
      orderBy: _.cloneDeep(defaultOrderBy),
      rowLimit: DEFAULT_ROW_LIMIT,
      dialogVisible: false,
      fieldTypes,
      operators
    };
  },
  computed: {
    ...mapState("datastorecollection", {
      datastorescollection: state => state.datastorescollection,
      loadingCollectionData: state => state.loading,
      requestData: state => state.requestData
    }),

    ...mapState("dataStores", {
      dataStores: state => state.dataStores
    }),

    ...mapState("app", {
      userRole: state => state.userRole
    }),

    defaultColumnsForBreakdown() {
      return dataStoreId => {
        let columns = [...this.defaultColumns];
        const isSystemLog = this.isTaskLogDs(dataStoreId);
        if (!isSystemLog) {
          columns = _.filter(columns, column => column.name !== "_uuid");
        }
        return _.filter(columns, column => column.name !== "_id");
      };
    },

    dsData() {
      return this.parseData(this.datastorescollection, "ws_response_data");
    },

    isTaskLogDs() {
      return dataStoreId => {
        let dataStore = _.find(this.dataStores, {
          data_store_id: dataStoreId
        });
        return !!(dataStore && dataStore.is_log);
      };
    },

    columnsForQueryBuilder() {
      return _.filter(this.selectedDsCollectionStructure, item => !item.secure);
    },
    updateOrDeleteIdFilterValue: {
      get() {
        return this.updateOrDeleteQueryCondition.children[0].value;
      },
      set(val) {
        this.updateOrDeleteQueryCondition.children[0].value = val;
      }
    },
    defaultDataStoreColumnsToSampleValue() {
      return {
        _id: "",
        _uuid: "",
        _timestamp: ""
      };
    },
    defaultColumnNames() {
      return _.map(this.defaultColumns, "name");
    }
  },
  methods: {
    ...mapActions("datastorecollection", {
      setRequestData: "setRequestData",
      getDataStoresCollection: "getDataStoresCollection"
    }),
    getDefaultRowLimit(isDataStoreNode = false) {
      return isDataStoreNode === true
        ? DATA_STORE_NODE_ROW_LIMIT
        : DEFAULT_ROW_LIMIT;
    },
    getDefaultOrderBy() {
      return _.cloneDeep(defaultOrderBy);
    },
    handleFetchSuccess(event) {
      if (!_.isEmpty(event)) {
        this.$emit("replaceContent", event);
      }
      this.dialogVisible = false;
    },
    // clear existing conditions value
    resetConditionValue(resetOrderBy = false) {
      // clear existing conditions
      this.eb = new Core.ExpressionBuilder(_.cloneDeep(emptyCondition), err => {
        console.log(err);
      });

      // //reset order by
      if (resetOrderBy) {
        this.orderBy = this.getDefaultOrderBy();
      }
    },
    createColumnEqualsValueFilterCondition(
      columnName,
      columnType,
      columnValue
    ) {
      let fieldType = _.find(
        fieldTypes,
        fieldType => fieldType.name === columnType
      );
      if (!fieldType) {
        fieldType = _.find(fieldTypes, fieldType => fieldType.name === "text");
      }

      // if (columnType === "audio") {
      //   console.warn("cannot build query condition with audio column");
      //   return _.cloneDeep(emptyCondition);
      // }
      return {
        children: [
          {
            fieldName: columnName,
            operatorName: fieldType.availableOperators[0],
            fieldTypeName: columnType,
            value: columnValue
          }
        ],
        connectionType: "and",
        maxDepth: 0
      };
    },
    setNewQueryCondition(condition) {
      this.eb = new Core.ExpressionBuilder(
        _.merge({ maxDepth: 0 }, condition),
        err => {
          console.log(err);
        }
      );
    },
    parseFieldData(dataType, value, secure = false) {
      if (secure) {
        return value;
      }
      if (dataType === "number") {
        return value || value === 0 ? parseInt(value) : null;
      } else if (dataType === "double") {
        return value || value === 0 ? parseFloat(value) : null;
      } else if (dataType === "boolean") {
        // for boolean type, covert "false", "FALSE" and "0" to false
        if (
          (typeof value === "string" &&
            value.toLowerCase().trim() === "false") ||
          value === "0"
        ) {
          return false;
        }
        return value ? Boolean(value) : false;
      } else if (dataType === "date") {
        return value ? value : null;
      } else if (dataType === "datetime") {
        return value ? value : null;
      } else if (dataType === "text") {
        if (value === null) {
          return null;
        }
      }
      return value ? value : "";
    },
    parseData(tableData, path = "data") {
      let vm = this;
      let data = _.cloneDeep(_.get(tableData, path, []));
      _.forEach(data, record => {
        _.forEach(this.selectedDsCollectionStructure, function(column) {
          if (column.name !== "_id") {
            record[column.name] = vm.parseFieldData(
              column.type,
              record[column.name],
              !!column.secure
            );
            if (
              column.name === "pop_region_id" &&
              record["pop_region_id"] === null
            ) {
              record["pop_region_id"] = 0;
            }
          }
        });
      });
      return data || [];
    },
    fieldType(dataType) {
      if (dataType === "string") {
        return defaultFieldTypes.TEXT;
      } else if (dataType === "integer") {
        return defaultFieldTypes.NUMBER;
      } else if (dataType === "double") {
        return "double";
        // return defaultFieldTypes.NUMBER;
      } else if (dataType === "audio") {
        return "audio";
      } else if (dataType === "date") {
        return defaultFieldTypes.DATE;
      } else if (dataType === "datetime") {
        return "datetime";
      } else if (dataType === "boolean") {
        return defaultFieldTypes.BOOLEAN;
      }
      return defaultFieldTypes.TEXT;
    },
    reInitializeDataStoreColumns(showDefaults = false) {
      this.selectedDsCollectionStructure = _.map(
        this.defaultColumns,
        column => {
          return {
            name: column.name,
            label: column.label,
            type: column.type,
            choices: null,
            visible: showDefaults,
            secure: !!column.secure
          };
        }
      );
    },
    pushNewColumnToCollectionStructure(column) {
      this.selectedDsCollectionStructure.push(column);
    }
  },
  watch: {
    userRole: {
      immediate: true,
      handler(newVal) {
        if (newVal === "system") {
          if (!_.find(this.defaultColumns, { name: "pop_region_id" })) {
            this.defaultColumns.push({
              name: "pop_region_id",
              label: __("pop_region_id (reserved)"),
              type: defaultFieldTypes.NUMBER
            });
          }
        } else {
          this.defaultColumns = _.filter(
            this.defaultColumns,
            column => column.name !== "pop_region_id"
          );
        }
      }
    }
  }
};
</script>
