import _ from "lodash";
import { DOCUMENT, IMAGE, AUDIO, VIDEO } from "@/constants/fileTypes";

const TEXT_INPUT = "text_input";
export const IMAGE_UPLOAD = "img_upload";
export const DOCUMENT_UPLOAD = "doc_upload";
export const AUDIO_UPLOAD = "audio_upload";

export const VIDEO_UPLOAD = "video_upload";

export const MAP_FILE_TYPE_TO_UPLOAD_TYPE = {
  [DOCUMENT]: DOCUMENT_UPLOAD,
  [IMAGE]: IMAGE_UPLOAD,
  [AUDIO]: AUDIO_UPLOAD,
  [VIDEO]: VIDEO_UPLOAD
};

/**
 * @typedef {Object} VisualFormTypeObjectOptions
 * @property {string[]} allowedMime Allowed mime types for upload field types
 * @property {string[]} allowedExtensions Allowed file extensions for upload field types
 * @property {boolean} hasSecondaryVar Set to true if secondary variable is supported
 * @property {Function} secondaryVariableLabel Label for the secondary variable. Must be a function that calls the translate function
 */

/**
 * @typedef {Object} VisualFormFieldTypeDetail
 * @property {string} label Display label of the type
 * @property {string} value Internal value of the type
 * @property {string[]} allowedMime List of allowed MIME types
 * @property {string[]} allowedExtensions Allowed file extensions for upload field types
 * @property {boolean} hasSecondaryVar
 * @property {string} secondaryVariableLabel Label for the secondary variable
 */

/**
 * Build visual form field type dictionary
 * @param {Function} labelTranslatable A function that calls the translate function
 * @param {string} value
 * @param {VisualFormTypeObjectOptions} options Extra options to pass
 * @returns {VisualFormFieldTypeDetail}
 */
function typeObjectFactory(labelTranslatable, value, options = {}) {
  return {
    get label() {
      return labelTranslatable();
    },
    value,
    allowedMime: _.get(options, "allowedMime", []),
    allowedExtensions: _.get(options, "allowedExtensions", []),
    hasSecondaryVar: _.get(options, "hasSecondaryVar") === true,
    get secondaryVariableLabel() {
      const fn = _.get(options, "secondaryVariableLabel");

      if (typeof fn === "function") {
        return fn();
      }

      return __("Secondary Variable");
    }
  };
}

export const VISUAL_FORM_FIELD_TYPE_CONST = {
  TEXT_INPUT,
  IMAGE_UPLOAD,
  DOCUMENT_UPLOAD,
  AUDIO_UPLOAD,
  VIDEO_UPLOAD,
  DEFAULT: TEXT_INPUT
};

/**
 * @type {VisualFormFieldTypeDetail[]}
 */
export const VISUAL_FORM_FIELD_TYPES = [
  // MIME Sourced from: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types

  typeObjectFactory(() => __("Text"), TEXT_INPUT),
  typeObjectFactory(() => __("Image Upload"), IMAGE_UPLOAD, {
    hasSecondaryVar: true,
    secondaryVariableLabel: () => __("Save Image Type to Variable"),
    allowedMime: ["image/jpeg", "image/png"],
    allowedExtensions: ["jpg", "jpeg", "png"]
  }),
  typeObjectFactory(() => __("Document Upload"), DOCUMENT_UPLOAD, {
    hasSecondaryVar: true,
    secondaryVariableLabel: () => __("Save Document Type to Variable"),
    allowedMime: [
      "application/pdf",
      "text/csv",

      // MS word
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

      // MS powerpoint
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",

      // MS excel
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ],
    allowedExtensions: [
      "pdf",
      "csv",
      "doc",
      "docx",
      "ppt",
      "pptx",
      "xls",
      "xlsx"
    ]
  }),
  typeObjectFactory(() => __("Audio Upload"), AUDIO_UPLOAD, {
    hasSecondaryVar: true,
    secondaryVariableLabel: () => __("Save Audio Type to Variable"),
    allowedMime: ["audio/mpeg", "audio/wav"],
    allowedExtensions: ["mp3", "wav"]
  }),
  typeObjectFactory(() => __("Video Upload"), VIDEO_UPLOAD, {
    hasSecondaryVar: true,
    secondaryVariableLabel: () => __("Save Video Type to Variable"),
    allowedMime: ["video/mp4"],
    allowedExtensions: ["mp4"]
  })
];
