<script>
//import _ from "lodash";

import _ from "lodash";

export default {
  name: "NodeUsesVariableDropdown",
  methods: {
    addVariableRule(rule) {
      if (rule.interaction_variable_id) {
        delete rule.variable_id;
        this.nodeToBind[
          this.nodeName
        ].data.interaction_variable_rules.data.push(rule);
        return;
      }
      this.nodeToBind[this.nodeName].data.variable_rules.data.push(rule);
    },
    handleVariableUpdate(studioVariables, cavVariables) {
      this.$set(
        this.nodeToBind[this.nodeName].data.variable_rules,
        "data",
        studioVariables
      );
      this.$set(
        this.nodeToBind[this.nodeName].data.interaction_variable_rules,
        "data",
        cavVariables
      );
    },
    removeJsonPath(rule) {
      if (rule.variable_id) {
        this.$set(
          this.nodeToBind[this.nodeName].data.variable_rules,
          "data",
          _.remove(
            this.nodeToBind[this.nodeName].data.variable_rules.data,
            variable =>
              variable.rule_value !== rule.rule_value ||
              variable.variable_id !== rule.variable_id
          )
        );
      }
      if (rule.interaction_variable_id) {
        this.$set(
          this.nodeToBind[this.nodeName].data.interaction_variable_rules,
          "data",
          _.remove(
            this.nodeToBind[this.nodeName].data.interaction_variable_rules.data,
            variable =>
              variable.rule_value !== rule.rule_value ||
              variable.interaction_variable_id !== rule.interaction_variable_id
          )
        );
      }
    }
  },
  computed: {
    getVariableRule() {
      return row => {
        return row.rule_value;
      };
    },
    newVariableDetected() {
      return row => {
        return row?.variable_id === -1;
      };
    },
    currentSelection() {
      return row => {
        const { variable_id, interaction_variable_id, variable_name } = row;
        const variableId = interaction_variable_id
          ? interaction_variable_id
          : variable_id;
        return variableId === -1 ? variable_name : variableId;
      };
    }
  }
};
</script>
